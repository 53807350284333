import React, { useState } from 'react';

const steps = [
    {
        title: 'Licentienemer',
        description: 'Details about the licentienemer.',
        fields: [
            { name: 'naam', label: 'Naam', type: 'text' },
            { name: 'email', label: 'Email', type: 'email' },
            { name: 'telefoonnummer', label: 'Telefoonnummer', type: 'text' },
            { name: 'straat', label: 'Straat', type: 'text' },
            { name: 'huisnummer', label: 'Huisnummer', type: 'text' },
            { name: 'postcode', label: 'Postcode', type: 'text' },
            { name: 'plaats', label: 'Plaats', type: 'text' },
        ],
    },
    {
        title: 'Werkbegeleiders',
        description: 'Information on werkbegeleiders.',
        fields: [
            { name: 'voornaam', label: 'Voornaam', type: 'text' },
            { name: 'achternaam', label: 'Achternaam', type: 'text' },
            { name: 'email', label: 'Email', type: 'email' },
            { name: 'telefoon_nummer', label: 'Telefoonnummer', type: 'text' },
        ],
    },
    {
        title: 'Casemanagers',
        description: 'Details about casemanagers.',
        fields: [
            { name: 'voornaam', label: 'Voornaam', type: 'text' },
            { name: 'achternaam', label: 'Achternaam', type: 'text' },
            { name: 'email', label: 'Email', type: 'email' },
        ],
    },
    {
        title: 'Werkbedrijven',
        description: 'Information on werkbedrijven.',
        fields: [
            { name: 'naam', label: 'Naam', type: 'text' },
            { name: 'logo', label: 'Logo URL', type: 'url' },
            { name: 'straat', label: 'Straat', type: 'text' },
            { name: 'huisnummer', label: 'Huisnummer', type: 'text' },
            { name: 'postcode', label: 'Postcode', type: 'text' },
            { name: 'plaats', label: 'Plaats', type: 'text' },
        ],
    },
    {
        title: 'Trajecten',
        description: 'Details about trajecten.',
        fields: [
            { name: 'naam', label: 'Naam', type: 'text' },
            { name: 'gemeente', label: 'Gemeente', type: 'text' },
            { name: 'duur', label: 'Duur', type: 'number' },
            { name: 'startdatum', label: 'Startdatum', type: 'date' },
        ],
    },
    {
        title: 'Talenten',
        description: 'Details about talenten.',
        fields: [
            { name: 'voornaam', label: 'Voornaam', type: 'text' },
            { name: 'achternaam', label: 'Achternaam', type: 'text' },
            { name: 'email', label: 'Email', type: 'email' },
            { name: 'gemeente', label: 'Gemeente', type: 'text' },
        ],
    },
];

const Onboarding: React.FC = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState<any>({});
    const [entries, setEntries] = useState<{ [key: number]: any[] }>({});

    const isLastStep = currentStep === steps.length - 1;

    const handleNext = () => {
        if (!isLastStep) {
            setCurrentStep((prev) => prev + 1);
        } else {
            console.log('Onboarding complete', entries);
        }
    };

    const handleBack = () => {
        if (currentStep > 0) {
            setCurrentStep((prev) => prev - 1);
        }
    };

    const handleChange = (index: number, fieldName: string, value: string) => {
        const stepEntries = entries[currentStep] || [];
        stepEntries[index] = { ...stepEntries[index], [fieldName]: value };
        setEntries({ ...entries, [currentStep]: stepEntries });
    };

    const addEntry = () => {
        const stepEntries = entries[currentStep] || [];
        setEntries({ ...entries, [currentStep]: [...stepEntries, {}] });
    };

    return (
        <div className="flex flex-col min-h-screen bg-slate-50 p-6">
            <div className="mt-auto flex justify-center pb-10">
                <img
                    src="/Bloeiindex_Purple@4.png" // Replace with the path to your logo
                    alt="Logo"
                    className="h-6 w-auto"
                />
            </div>
            {/* Progress Bar */}
            <div className="mb-8 ">
                <div className="flex justify-center">
                    {steps.map((step, index) => (
                        <div
                            key={index}
                            className={`relative flex-1  ${index < currentStep ? 'text-blue-700' : 'text-gray-400'
                                }`}
                        >
                            <div className="flex items-center">
                                <div
                                    className={`flex h-8 w-8 items-center justify-center rounded-full border-2 ${index <= currentStep
                                            ? 'border-indigo-600 bg-indigo-100'
                                            : 'border-gray-300 bg-white'
                                        }`}
                                >
                                    {index + 1}
                                </div>
                                {index < steps.length - 1 && (
                                    <div
                                        className={`flex-1 h-0.5 ${index < currentStep ? 'bg-indigo-600' : 'bg-gray-300'
                                            }`}
                                    ></div>
                                )}
                            </div>
                            <div className="mt-2 text-sm">{step.title}</div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Onboarding Content */}
            <div className="flex flex-grow flex-col items-center justify-center">
                <div className="max-w-4xl w-full bg-white rounded-lg shadow-xl p-8">
                    <div className="mb-4">
                        <div className="text-gray-500 text-sm font-medium">
                            Step {currentStep + 1} of {steps.length}
                        </div>
                        <h1 className="text-2xl font-bold text-gray-900 mt-2">
                            {steps[currentStep].title}
                        </h1>
                        <p className="text-gray-600 mt-1">{steps[currentStep].description}</p>
                    </div>

                    {/* Table for Multiple Entries */}
                    <div className="mt-6 space-y-4">
                        <table className="w-full table-auto border-collapse border border-gray-300">
                            <thead>
                                <tr className="bg-gray-100">
                                    {steps[currentStep].fields.map((field) => (
                                        <th
                                            key={field.name}
                                            className="border border-gray-300 px-4 py-2 text-left text-gray-700"
                                        >
                                            {field.label}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {(entries[currentStep] || []).map((entry, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {steps[currentStep].fields.map((field) => (
                                            <td
                                                key={field.name}
                                                className="border border-gray-300 px-4 py-2 text-gray-600"
                                            >
                                                <input
                                                    type={field.type}
                                                    value={entry[field.name] || ''}
                                                    onChange={(e) =>
                                                        handleChange(rowIndex, field.name, e.target.value)
                                                    }
                                                    className="w-full border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm sm:text-sm"
                                                />
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    {/* Add Entry Button */}
                    <div className="mt-4 flex justify-end">
                        <button
                            onClick={addEntry}
                            className="bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-500"
                        >
                            Add Entry
                        </button>
                    </div>

                    {/* Navigation Buttons */}
                    <div className="flex justify-between mt-8">
                        <button
                            onClick={handleBack}
                            className={`px-4 py-2 text-sm rounded-md ${currentStep === 0
                                    ? 'text-gray-400 cursor-not-allowed'
                                    : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                                }`}
                            disabled={currentStep === 0}
                        >
                            Back
                        </button>
                        <button
                            onClick={handleNext}
                            className="px-4 py-2 text-sm bg-indigo-600 text-white rounded-md hover:bg-indigo-500"
                        >
                            {isLastStep ? 'Finish' : 'Next'}
                        </button>
                    </div>
                </div>
            </div>
            {/* Logo at the Bottom */}
            <div className="mt-auto flex justify-center items-center">
                <span className="text-gray-500 text-sm mr-2">Powered by</span>
                <img
                    src="/gds.png" // Replace with the path to your logo
                    alt="Logo"
                    className="h-12 w-auto"
                />
            </div>
        </div>
    );
};

export default Onboarding;