import { DocumentReference } from 'firebase/firestore';
import _getTrajecten, { createTrajectDocument, getTrajectDocumentById, updateTrajectDocument } from '../datasource/traject-utils';
import { _getUserById, getUserById, UserModel } from './user-utils';
import { getCountAanmeldingenByTraject } from '../datasource/aanmelding-utils';
import { getCountTalentenByTraject } from '../datasource/talent-utils';
import { User } from '../datasource/interfaces/collection';

export type TrajectModel = {
    duur?: number,
    aantal_rapportages?: number,
    evaluatie_momenten?: number,
    jobcoach_interventies?: number,
    praktijkverklaring?: boolean,
    talentportfolio?: boolean,
    loonwaardemeting?: boolean,
    gemeente?: string,
    naam?: string,
    omschrijving?: string,
    reference?: DocumentReference,
    traject_consulent_reference?: DocumentReference,
    consulent?: {
      naam: string
    },
    aanmeldingenCount?: number,
    talentenCount?: number,
    casemanager_references?: DocumentReference[],
    casemanagers?: User[]
};

export type NewTrajectModel = {
    duur?: number,
    traject_consulent_id?: string,
    aantal_rapportages?: number,
    evaluatie_momenten?: number,
    jobcoach_interventies?: number,
    praktijkverklaring?: boolean,
    talentportfolio?: boolean,
    loonwaardemeting?: boolean,
    gemeente?: string,
    naam?: string,
    omschrijving?: string,
    consulent_reference?: DocumentReference,
    casemanager_references?: DocumentReference[],
    casemanagers?: User[],
    data_eigenaar_ref?: DocumentReference
}

export const getAllTrajecten = async (user: UserModel) => {
  const list: TrajectModel[] = []

  const trajecten = await _getTrajecten(user?.data_eigenaar_ref!)

  for (var traject of trajecten) {
    list.push({
      duur: traject.duur,
      traject_consulent_reference: traject.traject_consulent_reference,
      aantal_rapportages: traject.aantal_rapportages,
      evaluatie_momenten: traject.evaluatie_momenten,
      jobcoach_interventies: traject.jobcoach_interventies,
      praktijkverklaring: traject.praktijkverklaring,
      talentportfolio: traject.talentportfolio,
      loonwaardemeting: traject.loonwaardemeting,
      gemeente: traject.gemeente,
      naam: traject.naam,
      omschrijving: traject.omschrijving,
      reference: traject.reference,
      casemanager_references: traject.casemanager_references ?? [],
      casemanagers: traject.casemanagers ?? []
  })
  }

  return list

}

export const getTrajectById = async (id?: string, options?: {consulent?: boolean, includeCount?: boolean}) => {

  if (!id) return Promise.reject("Fout")

  const traject = await getTrajectDocumentById(id)

  if (!traject) return Promise.reject("Geen traject gevonden.")

  let trajectModel: TrajectModel = {
      duur: traject.duur,
      aantal_rapportages: traject.aantal_rapportages,
      evaluatie_momenten: traject.evaluatie_momenten,
      jobcoach_interventies: traject.jobcoach_interventies,
      praktijkverklaring: traject.praktijkverklaring,
      talentportfolio: traject.talentportfolio,
      loonwaardemeting: traject.loonwaardemeting,
      gemeente: traject.gemeente,
      naam: traject.naam,
      omschrijving: traject.omschrijving,
      reference: traject.reference,
      casemanager_references: traject.casemanager_references ?? [],
      casemanagers: traject.casemanagers ?? []
  }

  if (options?.consulent) {
    let consulent = await getUserById(traject.traject_consulent_reference)
    trajectModel.consulent = {
        naam: `${consulent?.voornaam} ${consulent?.achternaam}`
    } 
} else {
  trajectModel.traject_consulent_reference = traject.traject_consulent_reference
}

  return trajectModel

}

export const getTrajecten = async (user: UserModel, options?: {includeCount?: boolean}) => {
    const list: TrajectModel[] = []
   
  var trajecten = await _getTrajecten(user?.data_eigenaar_ref!)

  const consulenten: Map<string, UserModel> = new Map()

  for (let traject of trajecten) {
    let consulent
    if (traject.traject_consulent_reference)
    {
      if (consulenten.has(traject.traject_consulent_reference.id)) {
        consulent = consulenten.get(traject.traject_consulent_reference.id)
      } else {
        consulent = await getUserById(traject.traject_consulent_reference)
        consulenten.set(traject.traject_consulent_reference.id, consulent)
      }
    }

    let tempTraject = {
      duur: traject.duur,
      aantal_rapportages: traject.aantal_rapportages,
      evaluatie_momenten: traject.evaluatie_momenten,
      jobcoach_interventies: traject.jobcoach_interventies,
      praktijkverklaring: traject.praktijkverklaring,
      talentportfolio: traject.talentportfolio,
      loonwaardemeting: traject.loonwaardemeting,
      gemeente: traject.gemeente,
      naam: traject.naam,
      omschrijving: traject.omschrijving,
      reference: traject.reference,
      consulent: {
        naam: `${consulent.voornaam} ${consulent.achternaam}`
      }
    } as TrajectModel

    if (options?.includeCount) {
      let aanmeldingCount = await getCountAanmeldingenByTraject(traject.reference, user?.data_eigenaar_ref!)
      let talentCount = await getCountTalentenByTraject(traject.reference, user?.data_eigenaar_ref!)
      tempTraject.aanmeldingenCount = aanmeldingCount
      tempTraject.talentenCount = talentCount
    }

    list.push(tempTraject)
  }

  return list;
}

export const createTraject = async (authUser: UserModel, consulent_id: string, casemanager_ids: string[], data: NewTrajectModel) => {

  if (!consulent_id) return Promise.reject("Fout.")

  const user = await _getUserById(consulent_id)

  if (!user) return Promise.reject("Geen consulent gevonden.")

  data.consulent_reference = user.ref

  const casemanagers: DocumentReference[] = []
  const casemanagersExploded: User[] = []

  for (let id of casemanager_ids!) {
    let casemanager = await _getUserById(id)
    if (!casemanager){
      return Promise.reject("Casemanager niet gevonden.")
    }
    casemanagers.push(casemanager.ref)
    casemanagersExploded.push({
      achternaam: casemanager.achternaam,
      email: casemanager.email,
      profiel_foto: casemanager.profiel_foto ?? null,
      reference: casemanager.ref.id,
      voornaam: casemanager.voornaam
    })
  }

  data.casemanager_references = casemanagers
  data.casemanagers = casemanagersExploded
  data.data_eigenaar_ref = authUser.data_eigenaar_ref

  return createTrajectDocument(data)
}

export const updateTraject = async (id: string, consulent_id: string, casemanager_ids: string[], authUser: UserModel, data: NewTrajectModel) => {

  if (!consulent_id) return Promise.reject("Fout.")

  const user = await _getUserById(consulent_id)

  if (!user) return Promise.reject("Geen consulent gevonden.")

  data.consulent_reference = user.ref

  const casemanagers: DocumentReference[] = []
  const casemanagersExploded: User[] = []

  for (let id of casemanager_ids!) {
    let casemanager = await _getUserById(id)
    if (!casemanager){
      return Promise.reject("Casemanager niet gevonden.")
    }
    casemanagers.push(casemanager.ref)
    casemanagersExploded.push({
      achternaam: casemanager.achternaam,
      email: casemanager.email,
      profiel_foto: casemanager.profiel_foto ?? null,
      reference: casemanager.ref.id,
      voornaam: casemanager.voornaam
    })
  }

  data.casemanager_references = casemanagers
  data.casemanagers = casemanagersExploded

  return updateTrajectDocument(id, authUser.data_eigenaar_ref!, data)
}

export const findTrajectenByCasemanager = async (id: string, reference: DocumentReference, casemanagers?: User[]) => {
  try {
    const traject = await getTrajectById(id);

    if (!traject) {
      return Promise.reject("Geen traject gevonden.");
    }

    if (casemanagers && casemanagers.length > 0) {
      traject.casemanagers = traject.casemanagers?.filter(casemanager =>
        casemanagers.some(user => user.reference === casemanager.reference)
      );
    }


    if (reference && traject.casemanager_references) {
      const isReferenceValid = traject.casemanager_references.some(ref => ref.id === reference.id);
      if (!isReferenceValid) {
        return Promise.reject("Casemanager niet gekoppeld aan dit traject.");
      }
    }

    return traject;
  } catch (error) {
    return Promise.reject(`Fout bij ophalen van traject: ${error}`);
  }
};
