import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition, Listbox } from "@headlessui/react";
import { FunnelIcon, ChevronDownIcon } from "@heroicons/react/24/solid"; // Import icons
import { getTrajecten, TrajectModel } from "../../../domain/traject-utils";
import { Filter } from "../../../domain/talent-utils";
import gemeentes from '../../../../src/datalists/gemeentes.json';
import Select from 'react-select-virtualized';

export default function FilterComponent({ user, filters, onSubmitFilter }: { user: any, filters: Filter, onSubmitFilter: any }) {
    const [isOpen, setIsOpen] = useState(false);
    const [trajecten, setTrajecten] = useState<TrajectModel[]>([])
    const [trajectId, setTrajectId] = useState("")
    const [gemeente, setGemeente] = useState("")
    const [tempFilters, setTempFilters] = useState({
        exclude: filters.exclude,
        active: filters.active
    });

    useEffect(() => {
        getTrajecten(user).then((trajecten) => {
            setTrajecten(trajecten)
        })
    }, [])

    const openModal = () => setIsOpen(true);
    const closeModal = () => setIsOpen(false);

    return (
        <div className="relative">
            {/* Round Filter Icon */}
            <button
                onClick={openModal}
                className="fixed sm:ml-32 sm:mb-12 mb-24 bottom-6 left-1/2 transform -translate-x-1/2 flex items-center justify-center p-3 bg-blue-700 text-white rounded-full shadow-lg hover:bg-blue-700"
            >
                <span className="mr-2 ml-2">Filter Talenten</span> {/* Added text "Filter" before the icon */}
                <FunnelIcon className="h-6 w-6 mr-2" />
            </button>

            {/* Modal */}
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50" onClose={closeModal}>
                    <div className="fixed inset-0 bg-black bg-opacity-50" />
                    <div className="fixed inset-0 flex items-center justify-center p-4">
                        <Dialog.Panel className="bg-white rounded-lg shadow-xl w-full max-w-sm p-6">
                            <Dialog.Title className="text-lg font-semibold text-gray-900">
                                Talentenhub Filters
                            </Dialog.Title>
                            <div className="mt-4 space-y-4">

                                <Listbox value={trajectId} onChange={setTrajectId}>
                                    <div className="relative">
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                                            Traject
                                        </Listbox.Label>
                                        <Listbox.Button className="w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-left cursor-pointer flex justify-between items-center">
                                            {(trajectId) ? `${trajecten.find((traject) => traject.reference!.id == trajectId)?.naam} - ${trajecten.find((traject) => traject.reference!.id == trajectId)?.gemeente}` : "Select a traject"}
                                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md z-10">
                                            {trajecten.map((traject, index) => (
                                                <Listbox.Option key={traject.reference?.id} value={traject.reference?.id} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                                                    {`${traject.naam} ${traject.gemeente}`}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox>

                                <div className="relative">
                                    <p>Gemeente</p>
                                    <Select onChange={(e) => setGemeente(e?.label)} options={gemeentes.gemeentes.map((pair) => { return { 'value': pair.value, 'label': pair.name } })} {...(gemeente ? { defaultValue: { 'value': gemeente.toLowerCase(), 'label': gemeente } } : {})} />
                                </div>

                                {/* Year Dropdown */}
                                {/* <Listbox value={filters.year} onChange={(value) => setFilters({ ...filters, year: value })}>
                                    <div className="relative">
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                                            Jaar
                                        </Listbox.Label>
                                        <Listbox.Button className="w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-left cursor-pointer flex justify-between items-center">
                                            {filters.year || "Select a year"}
                                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md z-10">
                                            {years.map((year, index) => (
                                                <Listbox.Option key={index} value={year} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                                                    {year}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox> */}

                                {/* State Dropdown */}
                                {/* <Listbox value={filters.state} onChange={(value) => setFilters({ ...filters, state: value })}>
                                    <div className="relative">
                                        <Listbox.Label className="block text-sm font-medium text-gray-700">
                                            Status
                                        </Listbox.Label>
                                        <Listbox.Button className="w-full mt-1 bg-white border border-gray-300 rounded-md shadow-sm py-2 px-4 text-left cursor-pointer flex justify-between items-center">
                                            {filters.state || "Select a state"}
                                            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                                        </Listbox.Button>
                                        <Listbox.Options className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-md z-10">
                                            {states.map((state, index) => (
                                                <Listbox.Option key={index} value={state} className="cursor-pointer hover:bg-gray-100 px-4 py-2">
                                                    {state}
                                                </Listbox.Option>
                                            ))}
                                        </Listbox.Options>
                                    </div>
                                </Listbox> */}

                                {/* Active / Non-Active Toggle Switch */}
                                <div className="flex items-center justify-between mt-6">
                                    <span className="text-sm font-medium text-gray-700">Status</span>
                                    <label className="flex items-center space-x-3 cursor-pointer">
                                        <span className="text-sm font-medium text-gray-900">
                                            {tempFilters.exclude ? "Beëindigd tonen" : "Incl. Beëindigd"}
                                        </span>
                                        <input
                                            type="checkbox"
                                            checked={!tempFilters.exclude} // 'Lopend' should be the default active state
                                            onChange={() => setTempFilters({ ...tempFilters, exclude: !tempFilters.exclude })}
                                            className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                        />

                                    </label>
                                </div>

                                {/* Active / Non-Active Toggle Switch */}
                                <div className="flex items-center justify-between mt-6">
                                    <span className="text-sm font-medium text-gray-700">Actief</span>
                                    <label className="flex items-center space-x-3 cursor-pointer">
                                        <span className="text-sm font-medium text-gray-900">
                                            {tempFilters.active ? "Inactief tonen" : "Incl. Inactief"}
                                        </span>
                                        <input
                                            type="checkbox"
                                            checked={!tempFilters.active} // 'Lopend' should be the default active state
                                            onChange={() => setTempFilters({ ...tempFilters, active: !tempFilters.active })}
                                            className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                                        />

                                    </label>
                                </div>
                            </div>

                            {/* Actions */}
                            <div className="mt-6 flex justify-between">
                                <button
                                    onClick={closeModal}
                                    className="px-4 py-2 bg-gray-50 rounded-lg text-gray-700 hover:bg-gray-400"
                                >
                                    Annuleren
                                </button>
                                <button
                                    onClick={() => {
                                        onSubmitFilter({ exclude: tempFilters.exclude, trajectId: trajectId, gemeente: gemeente, active: tempFilters.active })
                                        closeModal();
                                    }}
                                    className="px-4 py-2 bg-blue-700 text-white rounded-lg hover:bg-blue-600"
                                >
                                    Toepassen
                                </button>
                            </div>
                        </Dialog.Panel>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
}