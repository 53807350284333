import { ArrowsUpDownIcon, ChatBubbleLeftEllipsisIcon } from '@heroicons/react/20/solid'
import { EvaluatieModel, getEvaluatiesByTalentId } from '../../domain/evaluatie-utils'
import { useEffect, useState } from 'react'
import { TalentModel } from '../../domain/talent-utils'
import { UserModel } from '../../domain/user-utils'
import DefaultImage from '../defaultimage/defaultimage'

export default function TrajectTab(evaluaties: EvaluatieModel[]) {

  return (
    <div className="mt-6 px-8">
      {/* Header */}
      <div className="px-4 sm:px-0 relative block lg:hidden">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Traject updates</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Alle updates van de begeleiders</p>
        <button
          type="button"
          className="absolute right-0 top-0 flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none"
          aria-label="Toggle Sort Order"
        >
          <ArrowsUpDownIcon className="h-4 w-4 text-gray-600" />
        </button>
      </div>

      <ul role="list" className="-mb-8 mt-6">
        {evaluaties.map((evaluatie, id) => (
          <li key={id}>
            <div className="relative pb-8 ">
              {id !== evaluaties.length - 1 ? (
                <span aria-hidden="true" className="absolute left-5 top-5 -ml-px h-full w-0.5 bg-gray-200" />
              ) : null}
              <div className="relative flex items-start space-x-3 ">
                <div className="relative">
                  <DefaultImage
                    profiel_foto={evaluatie.auteur?.profiel_foto || 'public/logo192.png'}
                    className={"flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white"}
                  />

                  <span className="absolute -bottom-0.5 -right-1 rounded-tl bg-white px-0.5 py-px">
                    <ChatBubbleLeftEllipsisIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                  </span>
                </div>
                <div className="min-w-0 flex-1 bg-slate-50 p-4 rounded-lg">
                  <div>
                    <div className="text-sm">
                      <a href="#" className="font-medium text-gray-900">
                        {evaluatie.auteur?.naam}
                      </a>
                    </div>
                    <p className="mt-0.5 text-sm text-gray-500">Geschreven op {evaluatie.aanmaak_datum.toLocaleDateString()}</p>
                  </div>
                  <div className="mt-2 text-sm text-gray-700">
                    <p>{evaluatie.content}</p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
