'use client';

import { Dialog } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { db, functions } from "../../../utils/firebase";
import { useAuth } from "../../../context/AuthContext";
import getCasemanagers from '../../../domain/casemanager-utils';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth'
import { httpsCallable } from 'firebase/functions';

interface AddCasemanagerModalProps {
  isOpen: boolean;
  onClose: () => void;
  casemanagerData: {
    id?: any;
    voornaam: string;
    achternaam: string;
    telefoon_nummer: string;
    email: string;
    profiel_foto?: string;
    gebruikers_rol?: string;
    data_eigenaar_ref?: string;
    geslacht?: "man" | "vrouw" | "geen" | "";
  };
  onSave: () => void;
}

export default function AddCasemanagerModal({ casemanagerData, isOpen, onClose, onSave }: AddCasemanagerModalProps) {
  const [formData, setFormData] = useState(casemanagerData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { user } = useAuth()


  useEffect(() => {
    setFormData({ ...casemanagerData, gebruikers_rol: "casemanager" }); 
  }, [casemanagerData]);

  useEffect(() => {
    setFormData({...casemanagerData, data_eigenaar_ref: "/licentienemer/ph6twMZC8ZFZos3ft4eE"})
  }, [casemanagerData]);

  const handleChange = (field: keyof typeof casemanagerData, value: string) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({
          ...prev,
          profiel_foto: reader.result as string,
        }));
      };
      reader.readAsDataURL(file);
    }
  };


  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const handleAdd = async () => {
    if (!formData.voornaam || !formData.achternaam) {
      setError("Voornaam en Achternaam zijn verplicht.");
      return;
    }
    if (!formData.telefoon_nummer) {
      setError("Telefoon is verplicht.");
      return;
    }
    if (!formData.email || !validateEmail(formData.email)) {
      setError("Voer een geldig e-mailadres in.");
      return;
    }
  
    setLoading(true);
    setError(null);


    try {
    await createNewUser({
      email: formData.email,
      voornaam: formData.voornaam,
      achternaam: formData.achternaam,
      geslacht: formData.geslacht || "",
      rol: "casemanager",
      profiel_foto: formData.profiel_foto,
      data_eigenaar: casemanagerData.data_eigenaar_ref, 
    });

    if (user) {
      getCasemanagers(user);
    }    

    console.log("Casemanager successfully created!");
    onSave();
    onClose();
  } catch (error) {
    console.error("Error creating casemanager:", error);
    setError("Er is een fout opgetreden bij het aanmaken van de gebruiker.");
  } finally {
    setLoading(false);
  }

    // try {
    //   const auth = getAuth();
  
    //   const userCredential = await createUserWithEmailAndPassword(
    //     auth,
    //     formData.email,
    //     "123456"
    //   );
  
    //   const newUser = userCredential.user;
  
    //   const userRef = doc(db, "user", newUser.uid);
    //   const casemanagerDataToSave = {
    //     ...formData,
    //     gebruikers_rol: "casemanager",
    //   };
  
    //   await setDoc(userRef, casemanagerDataToSave, { merge: true }); 
  
    //   console.log("Casemanager successfully added as a user!");
    //   onSave();
    //   onClose();
    // } catch (error) {
    //   console.error("Error adding casemanager:", error);
    //   setError("Er is een fout opgetreden bij het toevoegen van de gebruiker.");
    // } finally {
    //   setLoading(false);
    // }
  };

  // const createNewUser = (data) => {
  //   httpsCallable<{ email: string, voornaam: string, achternaam: string, geslacht: string, rol: string, profiel_foto: string, data_eigenaar: string }, { status: string }>(functions, 'generate-account')({ email: data.email, voornaam: data.voornaam, achternaam: data.achternaam, geslacht: data.geslacht, rol: data.rol, profiel_foto: "", data_eigenaar: user!.data_eigenaar_ref!.id }).then(() => {
  //     // setIsModalOpen(false)
  //     // reset()
  //   }).catch(() => {
  //     alert("Er is iets fout gegaan.")
  //   })
  // }

  const createNewUser = (data) => {
    httpsCallable<{ email: string, voornaam: string, achternaam: string, geslacht: string, rol: string, profiel_foto: string, data_eigenaar: string }, { status: string }>(functions, 'generate-account')({
        email: data.email,
        voornaam: data.voornaam,
        achternaam: data.achternaam,
        geslacht: data.geslacht,
        rol: data.rol,
        profiel_foto: data.profiel_foto,  
        data_eigenaar: user!.data_eigenaar_ref!.id,
    })
    .then(() => {
      window.location.reload();
    })
    .catch(() => {
        alert("Er is iets fout gegaan.");
    });
};
  
  

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black bg-opacity-50" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="max-w-lg w-full bg-white rounded-lg shadow-lg">
          <div className="flex justify-between items-center border-b border-gray-200 px-6 py-4">
            <Dialog.Title className="text-lg font-medium text-gray-900">Add Casemanager</Dialog.Title>
            <button onClick={onClose} className="text-gray-500 hover:text-gray-700 focus:outline-none">
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
          <div className="px-6 py-4 overflow-y-auto max-h-[600px]">
            {error && <p className="text-red-500 text-sm">{error}</p>}
            <div className="space-y-4">
              <div>
                <label htmlFor="voornaam" className="block text-sm font-medium text-gray-700">Voornaam</label>
                <input
                  id="voornaam"
                  type="text"
                  value={formData.voornaam}
                  onChange={(e) => handleChange('voornaam', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="achternaam" className="block text-sm font-medium text-gray-700">Achternaam</label>
                <input
                  id="achternaam"
                  type="text"
                  value={formData.achternaam}
                  onChange={(e) => handleChange('achternaam', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="telefoon" className="block text-sm font-medium text-gray-700">Telefoon</label>
                <input
                  id="telefoon"
                  type="tel"
                  value={formData.telefoon_nummer}
                  onChange={(e) => handleChange('telefoon_nummer', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  id="email"
                  type="text"
                  value={formData.email}
                  onChange={(e) => handleChange('email', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="gebruikers_rol" className="block text-sm font-medium text-gray-700">Rol</label>
                <input
                  id="gebruikers_rol"
                  type="text"
                  value="casemanager"
                  disabled
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 cursor-not-allowed sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor=" data_eigenaar_ref" className="block text-sm font-medium text-gray-700">Licentienemer</label>
                <input
                  id=" data_eigenaar_ref"
                  type="text"
                  value="licentienemer/ph6twMZC8ZFZos3ft4eE"
                  disabled
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 cursor-not-allowed sm:text-sm"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Geslacht</label>
                <select
                  value={formData.geslacht || ""}
                  onChange={(e) => handleChange('geslacht', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm sm:text-sm"
                >
                  <option value="">Selecteer</option>
                  <option value="man">Man</option>
                  <option value="vrouw">Vrouw</option>
                  <option value="geen">Geen</option>
                </select>
              </div>
              <div>
                <label htmlFor="profiel_foto" className="block text-sm font-medium text-gray-700">Profiel foto</label>
                <div className="mt-1 flex items-center">
                  {formData.profiel_foto ? (
                    <img src={formData.profiel_foto} alt="Profile" className="w-16 h-16 rounded-full object-cover mr-4" />
                  ) : (
                    <div className="w-16 h-16 rounded-full bg-gray-200 mr-4" />
                  )}
                  <input
                    id="profiel_foto"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="block text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-3 border-t border-gray-200 px-6 py-4">
            <button onClick={onClose} className="px-4 py-2 text-sm text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200">
              Cancel
            </button>
            <button
              onClick={handleAdd}
              disabled={loading}
              className={`px-4 py-2 text-sm text-white bg-indigo-600 rounded-lg hover:bg-indigo-500 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
              {loading ? "Saving..." : "Add"}
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
