// dit is de layout file

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Home from './pages/Home';
import TalentPage from "./pages/talentenhub/page";
import { Dialog, DialogPanel } from '@headlessui/react';
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon, Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/20/solid';
// Caseload
import Caseload from './pages/talentenhub/caseload/App';
import Details from './pages/talentenhub/caseload/details/App';
import States from './pages/talentenhub/caseload/details/App';

// Talentenhub
import Rapportage from './pages/talentenhub/rapportagedocument/App';
import Rapportages from './pages/talentenhub/rapportages/App';
import JobProfile from './pages/talentenhub/intakeformulier/App';
import Aanmeldingen from './pages/talentenhub/aanmeldingen/App';
import Intakes from './pages/talentenhub/intakes/app';
import Detail from './pages/talentenhub/rapportages/detail/App'
import Aanmeldingformulier from './pages/talentenhub/aanmeldingformulier/Aanmeldingformulier'
import Talentdetails from './pages/talentenhub/talentdetail/App'
import Praktijkverklaringen from './pages/talentenhub/praktijkverklaringen/App'
import Pvdetail from './pages/talentenhub/praktijkverklaringen/detail/App'


// Bijenkorf
import Bijenkorf from './pages/bijenkorf/App';
import Collegas from './pages/bijenkorf/collegas/App';
import Bedrijven from './pages/bijenkorf/bedrijven/App';
import Vacatures from './pages/bijenkorf/vacatures/App';

// Beheer
import Algemeen from './pages/beheer/App';
import Beveiliging from './pages/beheer/beveiliging/App';
import Talenten from './pages/beheer/talenten/App';
import Intakedocument from './pages/talentenhub/intakedocument/App'
import Trajectensettings from './pages/beheer/trajecten/App'
import Aanmeldingdocument from './pages/talentenhub/aanmeldingdocument/Aanmeldingdocument'
import TrajectDetails from './pages/trajecten/detail/App'
import Organisatie from './pages/organisatie/App'
import Casemanagers from './pages/beheer/casemanagers/Casemanagers';

// Admin
import Admin from './pages/admin/App'
import Prompts from './pages/admin/prompts/App'

// Public
import Public from './pages/public/App';
import Publicform from './pages/public//aanmeldingformulier/Aanmeldingformulier';

// Vandaag
import Vandaag from './pages/vandaag/App';
import Vdgdetail from './pages/vandaag/detail/App';
import Vdgdoelen from './pages/vandaag/doelen/App';
import Vdgrpdates from './pages/vandaag/updates/App';
import Vdgrooster from './pages/vandaag/rooster/App';

// Signalen
import Signalen from './pages/signalen/App';

// Auth
import Login from './pages/login/App';
import Auth from './pages/login/auth/App'
import { AuthProvider, useAuth } from './context/AuthContext';
import Register from './pages/register/App';

// Trajecten
import Trajecten from './pages/trajecten/App'
import Casemanagerstra from './pages/trajecten/casemanagers/App';
import Trajectenfinancieel from './pages/trajecten/financieel/App';

// Team
import Team from './pages/team/App';

// V2
import V2 from './pages/v2/App';

// Onboarding
import Onboarding from './pages/onboarding/App';

//TrajectFromulier
import TrajectFormulier from './pages/trajectformulier/App';

//LoonWaardeMeting 
import LoonWaardeMeting from './pages/loonwaardemeting/App';


import Rooster, { } from './pages/rooster/App';

import PrivateRoute from './components/PrivateRoute';
import Companies from './pages/admin/companies/App';
import Teams from './pages/beheer/team/Team';
import Importer from './pages/beheer/import/Importer';

import Hometalenten from './pages/hometalenten/App';
import Notities from './pages/notities/App';




const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* Home */}
          <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />

          {/* Talentenhub */}
          <Route path="/talentenhub" element={<PrivateRoute><TalentPage /></PrivateRoute>} />
          <Route path="/talentenhub/rapportage" element={<PrivateRoute><Rapportage /></PrivateRoute>} />
          <Route path="/talentenhub/rapportages" element={<PrivateRoute><Rapportages /></PrivateRoute>} />
          <Route path="/talentenhub/intakeformulier" element={<PrivateRoute><JobProfile /></PrivateRoute>} />
          <Route path="/talentenhub/intakeformulier/:aanmeldingId" element={<PrivateRoute><JobProfile /></PrivateRoute>} />
          <Route path="/talentenhub/rapportageformulier" element={<PrivateRoute><Aanmeldingdocument /></PrivateRoute>} />
          <Route path="/talentenhub/intakes" element={<PrivateRoute><Intakes /></PrivateRoute>} />
          <Route path="/talentenhub/intakedocument/:id" element={<PrivateRoute><Intakedocument /></PrivateRoute>} />
          <Route path="/talentenhub/caseload" element={<PrivateRoute><Caseload /></PrivateRoute>} />
          <Route path="/talentenhub/aanmeldingen" element={<PrivateRoute><Aanmeldingen /></PrivateRoute>} />
          <Route path="/talentenhub/caseload/details/:id" element={<PrivateRoute><Details /></PrivateRoute>} />
          <Route path="/talentenhub/aanmeldingformulier" element={<PrivateRoute><Aanmeldingformulier /></PrivateRoute>} />
          <Route path="/talentenhub/aanmeldingdocument/:aanmeldingId" element={<PrivateRoute><Aanmeldingdocument /></PrivateRoute>} />
          <Route path="/talentenhub/rapportages/detail/:id" element={<PrivateRoute><Detail /></PrivateRoute>} />
          <Route path="/talentenhub/:id" element={<PrivateRoute><TalentPage /></PrivateRoute>} />
          <Route path="/talentenhub/talentdetails/:id" element={<PrivateRoute><Talentdetails /></PrivateRoute>} />
          <Route path="/talentenhub/praktijkverklaringen" element={<PrivateRoute><Praktijkverklaringen /></PrivateRoute>} />
          <Route path="/talentenhub/praktijkverklaring/detail/:id" element={<PrivateRoute><Pvdetail /></PrivateRoute>} />



          {/* Rooster */}
          <Route path="/rooster" element={<PrivateRoute><Rooster /></PrivateRoute>} />

          {/* Trajecten */}
          <Route path="/trajecten/detail/:trajectId" element={<PrivateRoute><TrajectDetails /></PrivateRoute>} />
          <Route path="/trajecten" element={<PrivateRoute><Trajecten /></PrivateRoute>} />
          <Route path="/trajecten/casemanagers" element={<PrivateRoute><Casemanagerstra /></PrivateRoute>} />
          <Route path="/trajecten/financieel" element={<PrivateRoute><Trajectenfinancieel /></PrivateRoute>} />


          {/* Bijenkorf */}
          <Route path="/bijenkorf" element={<PrivateRoute><Bijenkorf /></PrivateRoute>} />
          <Route path="/bijenkorf/collegas" element={<PrivateRoute><Collegas /></PrivateRoute>} />
          <Route path="/bijenkorf/bedrijven" element={<PrivateRoute><Bedrijven /></PrivateRoute>} />
          <Route path="/bijenkorf/vacatures" element={<PrivateRoute><Vacatures /></PrivateRoute>} />

          {/* Admin */}
          <Route path="/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
          <Route path="/admin/prompts" element={<PrivateRoute><Prompts /></PrivateRoute>} />
          <Route path="/admin/companies" element={<PrivateRoute><Companies /></PrivateRoute>} />
          <Route path="/beheer/trajecten" element={<PrivateRoute><Trajectensettings /></PrivateRoute>} />
          <Route path="/organisatie" element={<PrivateRoute><Organisatie /></PrivateRoute>} />
          <Route path="/beheer" element={<PrivateRoute><Algemeen /></PrivateRoute>} />
          <Route path="/beheer/beveiliging" element={<PrivateRoute><Beveiliging /></PrivateRoute>} />
          <Route path="/beheer/talenten" element={<PrivateRoute><Talenten /></PrivateRoute>} />
          <Route path="/beheer/team" element={<PrivateRoute><Teams /></PrivateRoute>} />
          <Route path="/beheer/casemanagers" element={<PrivateRoute><Casemanagers /></PrivateRoute>} />

          {/* Auth */}
          <Route path="/login" element={<Login />} />
          <Route path="/login/auth" element={<Auth />} />
          <Route path="/register" element={<Register />} />


          {/* Public */}
          <Route path="/:bedrijfsnaam" element={<Public />} />
          <Route path="/:bedrijfsnaam/aanmeldformulier" element={<Publicform />} />

          {/* Vandaag */}
          <Route path="/vandaag" element={<PrivateRoute><Vandaag /></PrivateRoute>} />
          <Route path="/vandaag/detail/:talentId" element={<PrivateRoute><Vdgdetail /></PrivateRoute>} />
          <Route path="/vandaag/doelen/:talentId" element={<PrivateRoute><Vdgdoelen /></PrivateRoute>} />
          <Route path="/vandaag/updates/:talentId" element={<PrivateRoute><Vdgrpdates /></PrivateRoute>} />
          <Route path="/vandaag/rooster/:talentId" element={<PrivateRoute><Vdgrooster /></PrivateRoute>} />

          {/* Signalen */}
          <Route path="/signalen" element={<PrivateRoute><Signalen /></PrivateRoute>} />

          {/*Hometalenten */}
          <Route path="/hometalenten" element={<PrivateRoute><Hometalenten></Hometalenten></PrivateRoute>} />

          {/*Notities */}
          <Route path="/notities" element={<PrivateRoute><Notities></Notities></PrivateRoute>} />


          {/* Team */}
          <Route path="/team" element={<PrivateRoute><Team /></PrivateRoute>} />

          {/* Onboarding */}
          <Route path="/onboarding" element={<PrivateRoute><Onboarding /></PrivateRoute>} />

          {/* V2 */}
          <Route path="/v2" element={<PrivateRoute><V2 /></PrivateRoute>} />

          <Route path="/test" element={<PrivateRoute><Importer/></PrivateRoute>} />


          {/* TrajectFormulier */}
          <Route path="/trajectformulier" element={<TrajectFormulier></TrajectFormulier>}/>

       {/* LoonWaardeMeting */}
       <Route path="/loonwaardemeting" element={<LoonWaardeMeting></LoonWaardeMeting>} />

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  </React.StrictMode >

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();