'use client'
import Layout from '../../../layout'
import { useEffect, useRef, useState } from 'react'
import { Button, Switch } from '@headlessui/react'
import Beheer from '../../../components/subnav/beheer/Beheer'
import { createTraject, getAllTrajecten, TrajectModel, updateTraject } from '../../../domain/traject-utils'
import { getAllConsulenten, UserModel } from '../../../domain/user-utils'
import { ConsulentContext } from '../../../components/context/ConsulentContext'
import ConsulentSelection from '../../../components/dropdown/consulent/App'
import { useAuth } from '../../../context/AuthContext'
import RapportagePDFFile from '../../../components/pdf/RapportagePDF'
import OntwikkeldoelenPDFFile from '../../../components/pdf/OntwikkeldoelenPDF'
import AanmeldPDFFile from '../../../components/pdf/AanmeldPDF'
import AanvragenPDFFile from '../../../components/pdf/AanvragenPDF'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { MultipleCasemanagerSelection } from '../../../components/dropdown/casemanager/App'
import getCasemanagers, { CasemanagerModel } from '../../../domain/casemanager-utils'
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import TrajectForm from './components/Form'

export default function Settings() {

    const { user } = useAuth()

    const [trajecten, setTrajecten] = useState<TrajectModel[]>([])
    const [consulenten, setConsulenten] = useState<UserModel[]>([])
    const [casemanagers, setCasemanagers] = useState<CasemanagerModel[]>([])

    useEffect(() => {

        Promise.all([getAllTrajecten(user!), getAllConsulenten(user!), getCasemanagers(user!)]).then(([trajecten, consulenten, casemanagers]) => {
            setTrajecten(trajecten)
            setConsulenten(consulenten)
            setCasemanagers(casemanagers)
        })
    }, [])

    // const submitForm = (e, index) => {
    //     e.preventDefault()

    //     var trajectId = e.currentTarget.elements.trajectid.value
    //     var trajectNaam = e.currentTarget.elements.trajectnaam.value
    //     var trajectDuur = e.currentTarget.elements.trajectduur.value
    //     var trajectConsulent = consulentIdRefs.current[index]
    //     var aantalRapportages = e.currentTarget.elements.trajectaantalrapportages.value
    //     var evaluatiemomenten = e.currentTarget.elements.trajectevaluatiemomenten.value
    //     var jobcoachInterventies = e.currentTarget.elements.trajectjobcoachinterventies.value
    //     var praktijkVerklaring = e.currentTarget.elements.trajectpraktijkverklaring.ariaChecked
    //     var talentPortfolio = e.currentTarget.elements.trajecttalentportfolio.ariaChecked
    //     var loonwaardemeting = e.currentTarget.elements.trajectloonwaardemeting.ariaChecked

    //     if (!trajectNaam || !trajectDuur || !trajectConsulent || !aantalRapportages || !jobcoachInterventies || !evaluatiemomenten || !praktijkVerklaring || !talentPortfolio || !loonwaardemeting || !trajectConsulent)
    //         return

    //     if (trajectId) {
    //         updateTraject(trajectId, trajectConsulent.value, {
    //             naam: trajectNaam, aantal_rapportages: Number(aantalRapportages), duur: Number(trajectDuur), evaluatie_momenten: Number(evaluatiemomenten),
    //             jobcoach_interventies: Number(jobcoachInterventies), loonwaardemeting: (loonwaardemeting == "false") ? false : true, praktijkverklaring: (praktijkVerklaring == "false") ? false : true,
    //             talentportfolio: (talentPortfolio == "false") ? false : true
    //         })?.then((_) => {
    //             console.log("Saved")
    //         })
    //     } else {
    //         createTraject({}).then((response) => {
    //             console.log("Saved")
    //         })
    //     }
    // }

    const handleSubmit = (data: {
        trajectId?: string,
        naam: string,
        traject_duur: number,
        traject_consulent_id: string,
        casemanager_ids: string[],
        gemeente: string,
        aantal_rapportages: number,
        jobcoach_interventies: number,
        evaluatie_momenten: number,
        praktijkverklaring: boolean,
        talentportfolio: boolean,
        loonwaardemeting: boolean
    }) => {
        if (!data.trajectId) {
            createTraject(user!, data.traject_consulent_id, data.casemanager_ids, {
                naam: data.naam, aantal_rapportages: data.aantal_rapportages, duur: data.traject_duur, evaluatie_momenten: data.evaluatie_momenten,
                jobcoach_interventies: data.jobcoach_interventies, loonwaardemeting: data.loonwaardemeting, praktijkverklaring: data.praktijkverklaring,
                talentportfolio: data.talentportfolio, gemeente: data.gemeente}).then(() => {
                    alert("Traject opgeslagen")
                }).catch((e) => {
                    alert("Er is iets fout gegaan, [" + e.message + "]")
                })
        } else {
            updateTraject(data.trajectId, data.traject_consulent_id, data.casemanager_ids, user!, {
                naam: data.naam, aantal_rapportages: data.aantal_rapportages, duur: data.traject_duur, evaluatie_momenten: data.evaluatie_momenten,
                jobcoach_interventies: data.jobcoach_interventies, loonwaardemeting: data.loonwaardemeting, praktijkverklaring: data.praktijkverklaring,
                talentportfolio: data.talentportfolio, gemeente: data.gemeente,
            }).then((_) => {
                alert("Traject opgeslagen")
            }).catch((e) => {
                alert("Er is iets fout gegaan, [" + e.message + "]")
            })
        }
    }

    return (
        <Layout>
            <>
                <div className="mx-auto lg:flex lg:gap-x-16 lg:px-8 h-[calc(100vh-65px)] overflow-auto ">
                    <h1 className="sr-only">General Settings</h1>

                    <Beheer />

                    <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
                        <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
                            <div>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Trajecten</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-500">
                                    This information will be displayed publicly so be careful what you share.
                                </p>
                                {trajecten.map((traject, i) => 
                                    <TrajectForm traject={traject} casemanagers={casemanagers} consulenten={consulenten} submitHandler={handleSubmit}/>
                                )}
                                <div className="bg-white shadow sm:rounded-lg border border-sky-600 mt-12 mb-12">
                                    <div className="px-4 py-5 sm:p-6">
                                        <div className="sm:flex sm:items-start sm:justify-between">
                                            <div>
                                                <h3 className="text-base font-semibold text-gray-900">Nieuw traject</h3>
                                                <div className="mt-2 max-w-xl text-sm text-gray-500">
                                                    <p>
                                                        Klik hier om een nieuw traject toe te voegen.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
                                                <button
                                                    onClick={() => setTrajecten([...trajecten, {}])}
                                                    className="inline-flex items-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                                >
                                                    Toevoegen
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </main>
                </div>
            </>
        </Layout>
    )
}
