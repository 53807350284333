import { useMemo } from "react"
import { SubmitHandler, useForm } from "react-hook-form"

type Inputs = {
  voornaam: string,
  achternaam: string,
  geslacht: string,
  email: string,
  rol: string
}

export default function AddUser({ isOpen, onClose, onSuccess, values }) {

  const {
    register,
    handleSubmit,
    reset,
    setValue
  } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    onSuccess(data)
  }

  useMemo(() => {
    if (!isOpen) return
    reset()
    setValue("email", values.email)
    setValue("rol", values.rol)
  }, [isOpen])

  if (!isOpen) return null; // Hide modal when closed

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 max-w-md w-full">
        <h2 className="text-xl font-bold mb-4">Create Account</h2>

        <form onSubmit={handleSubmit(onSubmit)}>

          <input {...register("email", { required: true })} hidden />
          <input {...register("rol", { required: true })} hidden />

          {/* Firstname */}
          <label className="block text-sm font-medium text-gray-700">Firstname</label>
          <input type="text" {...register("voornaam", { required: true })} className="w-full p-2 border rounded-md mb-3" placeholder="Enter firstname" />

          {/* Lastname */}
          <label className="block text-sm font-medium text-gray-700">Lastname</label>
          <input type="text" {...register("achternaam", { required: true })} className="w-full p-2 border rounded-md mb-3" placeholder="Enter lastname" />

          {/* Gender Dropdown */}
          <label className="block text-sm font-medium text-gray-700">Gender</label>
          <select {...register("geslacht", { required: true })} className="w-full p-2 border rounded-md mb-4">
            <option disabled selected value={""}>Selecteer geslacht</option>
            <option value="man">Man</option>
            <option value="vrouw">Vrouw</option>
            <option value="anders">Anders</option>
          </select>

          {/* Modal Buttons */}
          <div className="flex justify-between mt-4">
            <button type="button" onClick={onClose} className="px-4 py-2 text-sm text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300">
              Cancel
            </button>
            <button type="submit" className="px-4 py-2 text-sm bg-indigo-600 text-white rounded-md hover:bg-indigo-500">
              Save
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}