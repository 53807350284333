import { PhotoIcon } from "@heroicons/react/20/solid";
import { StepperContext } from "../../../../context/StepperContext";
import { ChangeEvent, MouseEventHandler, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { POSTCODE_REGEX } from "../../../../utils/regex";
import { TrajectModel, getAllTrajecten } from "../../../../domain/traject-utils";
import { UserModel, getAllConsulenten } from "../../../../domain/user-utils";
import { TrajectSelectionV2 } from "../../../../components/dropdown/traject/App";
import { ConsulentSelectionV2 } from "../../../../components/dropdown/consulent/App";
import nationaliteiten from "../../../../datalists/nationaliteiten.json"
import afkomst from "../../../../datalists/afkomst.json"


import dayjs from "dayjs";
import { CasemanagerSelectionV2 } from "../../../../components/dropdown/casemanager/App";
import getWerkvloerbegeleiders, { WerkvloerbegeleiderModel } from "../../../../domain/werkvloerbegeleider-utils";
import { WerkvloerbegeleiderSelectionV2 } from "../../../../components/dropdown/werkvloerbegeleider/App";
import { BedrijfSelection, WerkbedrijfSelectionV2 } from "../../../../components/dropdown/bedrijf/App";
import { BedrijfModel, getAllBedrijven } from "../../../../domain/bedrijf-utils";

export default function TalentDetail({user, handleClick}) {

    const { data, setData } = useContext(StepperContext)

    const [trajecten, setTrajecten] = useState<TrajectModel[]>([])
    const [werkvloerbegeleiders, setWerkvloerbegeleiders] = useState<WerkvloerbegeleiderModel[]>([])
    const [bedrijven, setBedrijven] = useState<BedrijfModel[]>([])

    const [files, setFiles] = useState<File[]>([])

    useEffect(() => {
        Promise.all([getWerkvloerbegeleiders(user!), getAllTrajecten(user!), getAllBedrijven(user)]).then(([consulenten, trajecten, werkbedrijven]) => {
            setTrajecten(trajecten)
            setWerkvloerbegeleiders(consulenten)
            setBedrijven(werkbedrijven)
        })
    }, [])

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files) {
            setFiles((files) => files.concat(Array.from(e.target.files!)))
        }
    }

    const handleOnDeleteClick = (i) => {
        setFiles((files) => {files.splice(i, 1); return files})
    }

    type Inputs = {
        gemeente: string,
        trajectId: string,
        startDatum: string,
        werkvloerbegeleiderId: string,
        werkbedrijfId: string,
        eersteIndruk: string,
        files: File[],
        voornaam: string,
        achternaam: string,
        email: string,
        dob: string,
        gender: string,
        telefoon: string,
        nationaliteit: string,
        afkomst: string,
        straat: string,
        huisnummer: string,
        postcode: string,
        woonplaats: string,
        casemanagerId: string
    }

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        watch
    } = useForm<Inputs>({
        defaultValues: useMemo(() => {

            if (data?.documenten) {
                setFiles(data?.documenten)
            }

            console.log(data)

            return {
                gemeente: data?.gemeente,
                eersteIndruk: data?.eersteIndrukTalent,
                voornaam: data?.voornaam,
                achternaam: data?.achternaam,
                email: data?.email,
                dob: data?.geboortedatum?.toISOString().substring(0, 10),
                gender: data?.gender,
                telefoon: data?.telefoon,
                nationaliteit: data?.nationaliteit,
                afkomst: data?.afkomst,
                straat: data?.straat,
                huisnummer: data?.huisnummer,
                postcode: data?.postcode,
                woonplaats: data?.woonplaats,
                startDatum: data?.startDatum?.toISOString().substring(0, 10),
                trajectId: data?.trajectId,
                casemanagerId: data?.casemanagerId,
                werkvloerbegeleiderId: data?.werkvloerbegeleiderId,
                werkbedrijfId: data?.werkbedrijfId,
                files: data?.documenten
            }
        }, [])
    })

    const selectedTraject = watch("trajectId");

    const onSubmit: SubmitHandler<Inputs> = (_data) => {
        setData({
            ...data, gemeente: _data.gemeente,
            trajectId: _data.trajectId,
            startDatum: dayjs(_data.startDatum).toDate(),
            werkvloerbegeleiderId: _data.werkvloerbegeleiderId,
            casemanagerId: _data.casemanagerId,
            eersteIndrukTalent: _data.eersteIndruk,
            documenten: files,
            voornaam: _data.voornaam,
            achternaam: _data.achternaam,
            email: _data.email,
            geboortedatum: dayjs(_data.dob).toDate(),
            gender: _data.gender,
            telefoon: _data.telefoon,
            nationaliteit: _data.nationaliteit,
            afkomst: _data.afkomst,
            straat: _data.straat,
            huisnummer: _data.huisnummer,
            postcode: _data.postcode,
            woonplaats: _data.woonplaats,
            werkbedrijfId: _data.werkbedrijfId
        })
        handleClick("next")
    }
    
    return (
        <div className="space-y-10 divide-y divide-gray-900/10 pt-10 px-4 ">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Traject</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">
                            Basis traject informatie
                        </p>
                    </div>
                    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">

                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">

                                {/* <div className="sm:col-span-3">
                                    <label htmlFor="last-name" className="block text-sm/6 font-medium text-gray-900">
                                        Gemeente
                                    </label>
                                    <div className="mt-2">
                                        <Controller
                                            name='gemeente'
                                            control={control}
                                            rules={{ required: "Gemeente is verplicht" }}
                                            render={({ field, fieldState: { error } }) => <><select 
                                            {...field}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6"
                                            >
                                                <option>United States</option>
                                                <option>Canada</option>
                                                <option>Mexico</option>
                                            </select>
                                                <span>{error?.message}</span></>}
                                        />
                                    </div>
                                </div> */}

                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900">
                                        Soort traject
                                    </label>
                                    <Controller
                                        name='trajectId'
                                        control={control}
                                        rules={{ required: "Traject is verplicht" }}
                                        render={({ field, fieldState: { error } }) => <><TrajectSelectionV2 {...field} traject={trajecten.find((traject) => traject.reference!.id == data?.trajectId)} trajecten={trajecten} /><span>{error?.message}</span></>}
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Startdatum
                                    </label>
                                    <input {...register("startDatum", { required: "Startdatum is verplicht", valueAsDate: true })} aria-label="Date" type="date" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" />
                                    {errors["startDatum"] && <span>{errors["startDatum"]?.message}</span>}
                                </div>

                                <div className="sm:col-span-3">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Werkbedrijf
                                    </label>
                                    <Controller
                                        name='werkbedrijfId'
                                        control={control}
                                        rules={{ required: "Werkbedrijf is verplicht" }}
                                        render={({ field, fieldState: { error } }) => <><WerkbedrijfSelectionV2 {...field} werkbedrijven={bedrijven} /><span>{error?.message}</span></>}
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Werkvloerbegeleider
                                    </label>
                                    <Controller
                                        name='werkvloerbegeleiderId'
                                        control={control}
                                        rules={{ required: "werkvloerbegeleider is verplicht" }}
                                        render={({ field, fieldState: { error } }) => <><WerkvloerbegeleiderSelectionV2 {...field} werkvloerbegeleider={werkvloerbegeleiders.find((werkvloerbegeleider) => werkvloerbegeleider.reference.id == data?.werkvloerbegeleiderId)} werkvloerbegeleiders={werkvloerbegeleiders} /><span>{error?.message}</span></>}
                                    />
                                </div>

                                <div className="sm:col-span-3">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Casemanager
                                    </label>
                                    {selectedTraject &&
                                    <Controller
                                        name='casemanagerId'
                                        control={control}
                                        rules={{ required: "Casemanager is verplicht" }}
                                        render={({ field, fieldState: { error } }) => <><CasemanagerSelectionV2 {...field} trajectId={selectedTraject} casemanager={(trajecten.find((traject) => traject.reference!.id == selectedTraject)?.casemanagers ?? []).find((_casemanager) => _casemanager?.reference == data.casemanagerId)} casemanagers={trajecten.find((traject) => traject.reference!.id == selectedTraject)?.casemanagers ?? []} /><span>{error?.message}</span></>}
                                    />}
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                                        Eerst indruk van het Talent
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                            {...register("eersteIndruk", { required: { value: true, message: "Eerste indruk is verplicht" } })}
                                            rows={4}
                                            placeholder="Hoe is de lichaamstaal van het talent? Hoe gaat het talent om met de introductie? Hoe reageert het talent op vragen over eerdere werkervaring?"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        />
                                        {errors["eersteIndruk"] && <span>{errors["eersteIndruk"]?.message}</span>}
                                    </div>
                                </div>


                                <div className="col-span-full">
                                    <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                                        CV en of andere documenten
                                    </label>
                                    <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                                        <div className="text-center">
                                            <PhotoIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                                            <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                                <label
                                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-blue-700 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-blue-500"
                                                >
                                                    <span>Upload een bestand</span>
                                                    <input {...register("files")} multiple onChange={handleFileChange} id="files" type="file" className="sr-only" />
                                                </label>
                                                <p className="pl-1">of sleep hem hier in</p>
                                            </div>
                                            <p className="text-xs leading-5 text-gray-600">PNG, JPG, GIF up to 10MB</p>
                                        </div>
                                    </div>
                                    {files.map((file, i) => <><div>{file.name}</div><img src={URL.createObjectURL(file)}/><button onClick={(e)=>handleOnDeleteClick(i)}>Delete</button></>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-x-8 gap-y-8 pt-10 md:grid-cols-3">
                    <div className="px-4 sm:px-0">
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Persoonlijke informatie</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
                    </div>
                    <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
                        <div className="px-4 py-6 sm:p-8">
                            <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Voornaam
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            {...register("voornaam", { required: { value: true, message: "Voornaam is verplicht" } })}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                        />
                                    </div>
                                    {errors["voornaam"] && <span>{errors["voornaam"]?.message}</span>}
                                </div>

                                <div className="sm:col-span-3">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Achternaam
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            {...register("achternaam", { required: { value: true, message: "Achternaam is verplicht" } })}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                        />
                                    </div>
                                    {errors["achternaam"] && <span>{errors["achternaam"]?.message}</span>}
                                </div>

                                <div className="sm:col-span-3">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Geboortedatum
                                    </label>
                                    <input 
                                    {...register("dob", { required: "Geboortedatum is verplicht" , valueAsDate: true })} aria-label="Date" type="date" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6" />
                                    {errors["dob"] && <span>{errors["dob"]?.message}</span>}
                                </div>

                                <div className="sm:col-span-3">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Gender
                                    </label>
                                    <div className="mt-2">
                                        <Controller
                                            name='gender'
                                            control={control}
                                            rules={{ required: { value: true, message: "Gender is verplicht" } }}
                                            render={({ field, fieldState: { error } }) => <><select {...field}
                                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6"
                                            >
                                                <option disabled selected hidden>Selecteer geslacht</option>
                                                <option value="vrouw">Vrouw</option>
                                                <option value="man">Man</option>
                                                <option value="anders">Anders</option>
                                            </select>
                                                <span>{error?.message}</span></>}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            {...register("email", { required: { value: true, message: "Email is verplicht" } })}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                        />
                                        {errors["email"] && <span>{errors["email"]?.message}</span>}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                        Telefoon
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            {...register("telefoon", { required: { value: true, message: "Telefoonnummer is verplicht" } })}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                        />
                                        {errors["telefoon"] && <span>{errors["telefoon"]?.message}</span>}
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900">
                                        Nationaliteit
                                    </label>
                                    <div className="mt-2">
                                    <Controller
                                        name='nationaliteit'
                                        control={control}
                                        rules={{ required: { value: true, message: "Nationaliteit is verplicht" } }}
                                        render={({ field, fieldState: { error } }) => <><select {...field}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6"
                                        >
                                            <option disabled selected hidden>Selecteer een nationaliteit</option>
                                            {nationaliteiten.nationaliteit.map((val) => 
                                               <option value={val.value}>{val.name}</option> 
                                            )}
                                        </select>
                                            <span className='text-red-400 text-xs'>{error?.message}</span></>}
                                    />
                                </div>

                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900">
                                        Afkomst
                                    </label>
                                    <div className="mt-2">
                                    <Controller
                                        name='afkomst'
                                        control={control}
                                        rules={{ required: { value: true, message: "Afkomst is verplicht" } }}
                                        render={({ field, fieldState: { error } }) => <><select {...field}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6"
                                        >
                                            <option disabled selected hidden>Selecteer een afkomst</option>
                                            {afkomst.afkomst.map((val) => 
                                               <option value={val.value}>{val.name}</option> 
                                            )}
                                        </select>
                                            <span className='text-red-400 text-xs'>{error?.message}</span></>}
                                    />
                                </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="street-address" className="block text-sm/6 font-medium text-gray-900">
                                        Straat
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            {...register("straat", { required: "Straat is verplicht" } )}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                        />
                                        {errors["straat"] && <span>{errors["straat"]?.message}</span>}
                                    </div>
                                </div>

                                <div className="sm:col-span-2 sm:col-start-1">
                                    <label htmlFor="city" className="block text-sm/6 font-medium text-gray-900">
                                        Huisnummer
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            {...register("huisnummer", { required: { value: true, message: "Huisnummer is verplicht" } })}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                        />
                                        {errors["huisnummer"] && <span>{errors["huisnummer"]?.message}</span>}
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label className="block text-sm/6 font-medium text-gray-900">
                                        Postcode
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            {...register("postcode", { required: { value: true, message: "Postcode is verplicht" }, pattern: { value: RegExp(POSTCODE_REGEX), message: "De postcode is niet juist (voorbeeld: 2288EL)" } })}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                        />
                                        {errors["postcode"] && <span>{errors["postcode"]?.message}</span>}
                                    </div>
                                </div>

                                <div className="sm:col-span-2">
                                    <label htmlFor="postal-code" className="block text-sm/6 font-medium text-gray-900">
                                        Woonplaats
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            {...register("woonplaats", { required: { value: true, message: "Woonplaats is verplicht" } })}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                        />
                                        {errors["woonplaats"] && <span>{errors["woonplaats"]?.message}</span>}
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="street-address" className="block text-sm/6 font-medium text-gray-900">
                                        Gemeente
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            {...register("gemeente", { required: "Gemeente is verplicht" } )}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                        />
                                        {errors["gemeente"] && <span>{errors["gemeente"]?.message}</span>}
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                    

                </div>
                <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
                        <button
                            type="submit"
                            className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                            Volgende
                        </button>
                    </div>
            </form>
        </div>

    )
}