import { MagnifyingGlassIcon, PlusCircleIcon, PlusIcon, UserCircleIcon } from "@heroicons/react/20/solid";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import React, { useState, useEffect } from "react";
import BottomSheet from "../../drawer/bottomsheet/BottomSheet";
import { UserModel } from '../../../domain/user-utils';
import { useParams } from "react-router-dom";
import UpdateCard from "../UpdatesCard";
import Updateschrijven from "../../updateschrijven/Updateschrijven";
import { EvaluatieModel, getAllEvaluatiesByUserId } from "../../../domain/evaluatie-utils";

const UpdatesPage: React.FC<{ user: UserModel; talentId?: string }> = ({ user }) => {
  const [isOpen2, setIsOpen2] = useState(false);
  const { id } = useParams();
  const [updates, setUpdates] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedUpdate, setSelectedUpdate] = useState<any | null>(null);
  const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([]);



  useEffect(() => {
    // Simulate API call
    getAllEvaluatiesByUserId(user!).then((evaluaties) => {
      setEvaluaties(evaluaties)
      setLoading(false);
    })

  }, []);



  const toggleBottomSheet2 = (update?: any) => {
    setSelectedUpdate(update || null);
    setIsOpen2(!isOpen2);
  };

  return (
    <div className="relative min-h-[calc(100vh-150px)] w-full flex flex-col items-center justify-center bg-gradient-to-b from-purple-100 to-white text-white px-6">

      {/* Conditionally Render Empty State or Updates List */}
      {loading ? (
        <div className="flex flex-col items-center text-center">
          {/* Icon */}
          <div className="bg-gradient-to-r from-purple-400 to-pink-400 p-4 rounded-full">
            <span className="text-4xl font-semibold">🌱</span>
          </div>

          {/* Text */}
          <h2 className="text-xl font-semibold text-blue-700 mt-4">Start met de Bloeiindex</h2>
          <p className="text-gray-400 mt-2 px-4">
            Laten we aan de slag gaan bloeiien, registreer een nieuwe gebeurtenis.
          </p>
        </div>
      ) : (
        <div className="w-screen h-screen space-y-4 overflow-y-auto max-h-[85vh] p-6 rounded-xl">
          {evaluaties.map((evaluatie,index) => (
            <div key={index} onClick={() => toggleBottomSheet2(evaluatie)} className="cursor-pointer">
              <UpdateCard evaluatie={evaluatie} />
            </div>
          ))}
        </div>
      )}

      {/* Floating Action Button */}
      <button
        onClick={toggleBottomSheet2}
        type="button"
        className="absolute bottom-16 bg-blue-700 hover:bg-indigo-600 text-white rounded-full w-16 h-16 flex items-center justify-center shadow-lg transition">
        <PlusIcon className="w-8 h-8 text-white" />
      </button>

      <BottomSheet isOpen={isOpen2} toggle={toggleBottomSheet2} title="Update Schrijven">
        <Updateschrijven user={user!} />
      </BottomSheet>
    </div>
  );
};

export default UpdatesPage;