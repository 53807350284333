import { and, collection, DocumentReference, getDocs, getDoc, doc, limit, query, QueryConstraint, QueryDocumentSnapshot, SnapshotOptions, where } from "firebase/firestore";
import { db } from "../utils/firebase";

export type RemoteCasemanager = {
    reference: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,
    werkbedrijf_reference: DocumentReference
};

const casemanagerConverter = {
    toFirestore: (casemanager: RemoteCasemanager) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions): RemoteCasemanager {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            achternaam: data.achternaam,
            email: data.email,
            gebruikers_rol: data.gebruikers_rol,
            geslacht: data.geslacht,
            profiel_foto: data.profiel_foto,
            telefoon_nummer: data.telefoon_nummer,
            voornaam: data.voornaam,
            werkbedrijf_reference: data.werkbedrijf_reference
        }
    }
}

const getCasemanagerDocuments = async (max: number, dataOwner: DocumentReference, queryConstraints?: QueryConstraint[]) => {

    let constraints: QueryConstraint[] = [where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "casemanager")]

    if (queryConstraints){
        for (let constraint of queryConstraints) {
            constraints.push(constraint)
        }
    }

    const q = query(collection(db, "user"), ...constraints, limit(max)).withConverter(casemanagerConverter);
    
    const querySnapshot = await getDocs(q);
    const list: RemoteCasemanager[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list;
}

export default getCasemanagerDocuments



export const getCasemanagerById = async (id: DocumentReference | string ) => {

    if(!id ){
        return null;
    }
    const docSnap =  (typeof id === "string" ) ?await getDoc( doc(db, "user", id.toString()).withConverter(casemanagerConverter)):await getDoc((id as DocumentReference).withConverter(casemanagerConverter));


    
    if (docSnap.exists()) {
       return docSnap.data()
      } else {
        return null; 
      }
}