import React, { useEffect, useState } from "react";
import Layout from "../../layout";
import { getRapportageSignalen, SignaalModel } from "../../domain/signalen-utils";
import { useAuth } from "../../context/AuthContext";
import PageHeader from "../../components/header/PageHeader";

export default function OverviewTable() {
    const { user } = useAuth();
    const [signalen, setSignalen] = useState<SignaalModel[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        getRapportageSignalen(user!, 999).then((signalen) => {
            setSignalen(signalen);
            setIsLoading(false);
        });
    }, []);

    if (isLoading) {
        return <div className="text-center py-10 text-gray-500">Loading...</div>;
    }

    return (
        <Layout>
            <div className="p-6 min-h-screen">
            <PageHeader title={"Signalen"} subtitle={"Welke taken liggen er op je bordje?"}/>                
                <div className="overflow-hidden bg-white">
                    <table className="min-w-full">
                        {/* Table Body */}
                        <tbody className="divide-y divide-gray-200">
                            {signalen.map((signaal, i) => (
                                <tr key={i} className="hover:bg-gray-50 transition duration-200">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex items-center space-x-3">
                                        <div className="h-10 w-10 bg-gray-200 rounded-full flex items-center justify-center text-gray-600 font-medium">
                                        </div>
                                        <span>{signaal.talent.voornaam} {signaal.talent.achternaam}</span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{signaal.traject}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{signaal.notificatieType}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">{signaal.datum?.toLocaleDateString()}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">Button</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </Layout>
    );
}