'use client'

import { useEffect, useState } from 'react'
import { Dialog, DialogPanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  ArrowPathIcon,
  ChevronRightIcon,
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
} from '@heroicons/react/20/solid'
import Layout from './layout'
import { Link, useParams } from 'react-router-dom'
import { getLicentienemerByName, LicentienemerModel } from '../../domain/licentienemer-utils'

const featuredTestimonial = {
  body: 'Integer id nunc sit semper purus. Bibendum at lacus ut arcu blandit montes vitae auctor libero. Hac condimentum dignissim nibh vulputate ut nunc. Amet nibh orci mi venenatis blandit vel et proin. Non hendrerit in vel ac diam.',
  author: {
    name: 'Brenna Goyette',
    handle: 'brennagoyette',
    imageUrl:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=1024&h=1024&q=80',
    logoUrl: 'https://tailwindui.com/plus/img/logos/savvycal-logo-gray-900.svg',
  },
}
const testimonials = [
  [
    [
      {
        body: 'Laborum quis quam. Dolorum et ut quod quia. Voluptas numquam delectus nihil. Aut enim doloremque et ipsam.',
        author: {
          name: 'Leslie Alexander',
          handle: 'lesliealexander',
          imageUrl:
            'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'Aut reprehenderit voluptatem eum asperiores beatae id. Iure molestiae ipsam ut officia rem nulla blanditiis.',
        author: {
          name: 'Lindsay Walton',
          handle: 'lindsaywalton',
          imageUrl:
            'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
  ],
  [
    [
      {
        body: 'Voluptas quos itaque ipsam in voluptatem est. Iste eos blanditiis repudiandae. Earum deserunt enim molestiae ipsum perferendis recusandae saepe corrupti.',
        author: {
          name: 'Tom Cook',
          handle: 'tomcook',
          imageUrl:
            'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
    [
      {
        body: 'Molestias ea earum quos nostrum doloremque sed. Quaerat quasi aut velit incidunt excepturi rerum voluptatem minus harum.',
        author: {
          name: 'Leonard Krasner',
          handle: 'leonardkrasner',
          imageUrl:
            'https://images.unsplash.com/photo-1519345182560-3f2917c472ef?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
        },
      },
      // More testimonials...
    ],
  ],
]
const footerNavigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Automation', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
    { name: 'Submit ticket', href: '#' },
    { name: 'Documentation', href: '#' },
    { name: 'Guides', href: '#' },
  ],
  company: [
    { name: 'About', href: '#' },
    { name: 'Blog', href: '#' },
    { name: 'Jobs', href: '#' },
    { name: 'Press', href: '#' },
  ],
  legal: [
    { name: 'Terms of service', href: '#' },
    { name: 'Privacy policy', href: '#' },
    { name: 'License', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'Instagram',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'X',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path d="M13.6823 10.6218L20.2391 3H18.6854L12.9921 9.61788L8.44486 3H3.2002L10.0765 13.0074L3.2002 21H4.75404L10.7663 14.0113L15.5685 21H20.8131L13.6819 10.6218H13.6823ZM11.5541 13.0956L10.8574 12.0991L5.31391 4.16971H7.70053L12.1742 10.5689L12.8709 11.5655L18.6861 19.8835H16.2995L11.5541 13.096V13.0956Z" />
        </svg>
      ),
    },
    {
      name: 'GitHub',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
    {
      name: 'YouTube',
      href: '#',
      icon: (props) => (
        <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M19.812 5.418c.861.23 1.538.907 1.768 1.768C21.998 8.746 22 12 22 12s0 3.255-.418 4.814a2.504 2.504 0 0 1-1.768 1.768c-1.56.419-7.814.419-7.814.419s-6.255 0-7.814-.419a2.505 2.505 0 0 1-1.768-1.768C2 15.255 2 12 2 12s0-3.255.417-4.814a2.507 2.507 0 0 1 1.768-1.768C5.744 5 11.998 5 11.998 5s6.255 0 7.814.418ZM15.194 12 10 15V9l5.194 3Z"
            clipRule="evenodd"
          />
        </svg>
      ),
    },
  ],
}
const jobOpenings = [
  {
    id: 1,
    role: 'Chaffeur',
    href: '#',
    description:
      'Kom als chauffeur werken bij Kringloop Winkel Rijswijk',
    salary: 'Retail / Logistiek',
    location: 'Rijswijk',
  },
  // {
  //   id: 2,
  //   role: 'Laravel developer',
  //   href: '#',
  //   description:
  //     'Et veniam et officia dolorum rerum. Et voluptas consequatur magni sapiente amet voluptates dolorum. Ut porro aut eveniet.',
  //   salary: '$125,000 USD',
  //   location: 'San Francisco, CA',
  // },
  // {
  //   id: 3,
  //   role: 'React Native developer',
  //   href: '#',
  //   description:
  //     'Veniam ipsam nisi quas architecto eos non voluptatem in nemo. Est occaecati nihil omnis delectus illum est.',
  //   salary: '$105,000 USD',
  //   location: 'San Francisco, CA',
  // },
  // {
  //   id: 4,
  //   role: 'React Native developer',
  //   href: '#',
  //   description:
  //     'Veniam ipsam nisi quas architecto eos non voluptatem in nemo. Est occaecati nihil omnis delectus illum est.',
  //   salary: '$105,000 USD',
  //   location: 'San Francisco, CA',
  // },
  // {
  //   id: 5,
  //   role: 'React Native developer',
  //   href: '#',
  //   description:
  //     'Veniam ipsam nisi quas architecto eos non voluptatem in nemo. Est occaecati nihil omnis delectus illum est.',
  //   salary: '$105,000 USD',
  //   location: 'San Francisco, CA',
  // },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {

  const {bedrijfsnaam} = useParams()

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const [licentienemer, setLicentienemer] = useState<LicentienemerModel>()

  useEffect(() => {
    getLicentienemerByName(bedrijfsnaam).then((model) => setLicentienemer(model))
  }, [])

  return (
    <Layout>
      <div className="bg-white">
        {/* Header */}


        <main>
          {/* Hero section */}
          <div className="relative isolate pt-14">
            <svg
              aria-hidden="true"
              className="absolute inset-0 -z-10 size-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            >
              <defs>
                <pattern
                  x="50%"
                  y={-1}
                  id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                  width={200}
                  height={200}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M100 200V.5M.5 .5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                <path
                  d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0} />
            </svg>
            <div className="mx-auto max-w-7xl px-6 py-24 sm:py-32 lg:flex lg:items-center lg:gap-x-10 lg:px-8 lg:py-40">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:flex-auto">
                <div className="flex">
                  <div className="relative flex items-center gap-x-4 rounded-full bg-white px-4 py-1 text-sm/6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                    <span className="font-semibold text-blue-700">Wij hebben vacatures</span>
                    <span aria-hidden="true" className="h-4 w-px bg-gray-900/10" />
                    <a href="#" className="flex items-center gap-x-1">
                      <span aria-hidden="true" className="absolute inset-0" />
                      Bekijk alle open posities
                      <ChevronRightIcon aria-hidden="true" className="-mr-2 size-5 text-gray-400" />
                    </a>
                  </div>
                </div>
                <h1 className="mt-10 text-pretty text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">
                  {licentienemer?.titel1}
                </h1>
                <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">
                {licentienemer?.beschrijving1}
                </p>
                <div className="mt-10 flex items-center gap-x-6">
                  <Link
                    to={{ pathname: 'aanmeldformulier' }}
                    className="rounded-md bg-sky-400 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Talent aanmelden
                  </Link>
                  <a href="#" className="text-sm/6 font-semibold text-gray-900">
                    Vraag stellen <span aria-hidden="true">→</span>
                  </a>
                </div>
              </div>
              <div className="mt-16 sm:mt-24 lg:mt-0 lg:shrink-0 lg:grow">
                <div role="img" className="mx-auto w-[22.875rem] max-w-full drop-shadow-xl">
                  <img alt="" src={licentienemer?.logo} />
                </div>
              </div>
            </div>
          </div>

          {/* Feature section */}
          <div className="bg-gray-900 py-24 sm:py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto max-w-2xl lg:text-center">
                <h2 className="text-base/7 font-semibold text-blue-700">{licentienemer?.naam}</h2>
                <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-white sm:text-5xl lg:text-balance">
                {licentienemer?.titel2}
                </p>
                <p className="mt-6 text-lg/8 text-gray-300">
                {licentienemer?.beschrijving2}
                </p>
              </div>
              <div className="mx-auto mt-16 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
                <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                    <div key={licentienemer?.feature1} className="flex flex-col">
                      <dt className="flex items-center gap-x-3 text-base/7 font-semibold text-white">
                        <CloudArrowUpIcon aria-hidden="true" className="size-5 flex-none text-blue-700" />
                        {licentienemer?.feature1}
                      </dt>
                      <dd className="mt-4 flex flex-auto flex-col text-base/7 text-gray-300">
                        <p className="flex-auto">{licentienemer?.feature_description1}</p>
                        <p className="mt-6">
                          {/* <a href={feature.href} className="text-sm/6 font-semibold text-blue-700">
                            Learn more <span aria-hidden="true">→</span>
                          </a> */}
                        </p>
                      </dd>
                    </div>
                    <div key={licentienemer?.feature2} className="flex flex-col">
                      <dt className="flex items-center gap-x-3 text-base/7 font-semibold text-white">
                        <LockClosedIcon aria-hidden="true" className="size-5 flex-none text-blue-700" />
                        {licentienemer?.feature2}
                      </dt>
                      <dd className="mt-4 flex flex-auto flex-col text-base/7 text-gray-300">
                        <p className="flex-auto">{licentienemer?.feature_description2}</p>
                        <p className="mt-6">
                          {/* <a href={feature.href} className="text-sm/6 font-semibold text-blue-700">
                            Learn more <span aria-hidden="true">→</span>
                          </a> */}
                        </p>
                      </dd>
                    </div>
                    <div key={licentienemer?.feature3} className="flex flex-col">
                      <dt className="flex items-center gap-x-3 text-base/7 font-semibold text-white">
                        <ArrowPathIcon aria-hidden="true" className="size-5 flex-none text-blue-700" />
                        {licentienemer?.feature3}
                      </dt>
                      <dd className="mt-4 flex flex-auto flex-col text-base/7 text-gray-300">
                        <p className="flex-auto">{licentienemer?.feature_description3}</p>
                        <p className="mt-6">
                          {/* <a href={feature.href} className="text-sm/6 font-semibold text-blue-700">
                            Learn more <span aria-hidden="true">→</span>
                          </a> */}
                        </p>
                      </dd>
                    </div>
                </dl>
              </div>
            </div>
          </div>

          {/* Feature section */}
          <div className="bg-white py-32">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto flex max-w-2xl flex-col items-end justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row">
                <div className="w-full lg:max-w-lg lg:flex-auto">
                  <h2 className="text-pretty text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
                    Wil jij een mooi plekje bij {licentienemer?.naam}?
                  </h2>
                  <p className="mt-6 text-xl/8 text-gray-600">
                    Dit is jouw kans om het Talent in je een kickstart te geven bij {licentienemer?.naam}
                  </p>
                  <img
                    alt=""
                    src="https://images.unsplash.com/photo-1606857521015-7f9fcf423740?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1344&h=1104&q=80"
                    className="mt-16 aspect-[6/5] w-full rounded-2xl bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]"
                  />
                </div>
                <div className="w-full lg:max-w-xl lg:flex-auto">
                  <h3 className="sr-only">Job openings</h3>
                  <ul className="-my-8 divide-y divide-gray-100">
                    {jobOpenings.map((opening) => (
                      <li key={opening.id} className="py-8">
                        <dl className="relative flex flex-wrap gap-x-3">
                          <dt className="sr-only">Role</dt>
                          <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900">
                            <a href={opening.href}>
                              {opening.role}
                              <span aria-hidden="true" className="absolute inset-0" />
                            </a>
                          </dd>
                          <dt className="sr-only">Description</dt>
                          <dd className="mt-2 w-full flex-none text-base/7 text-gray-600">{opening.description}</dd>
                          <dt className="sr-only">Salary</dt>
                          <dd className="mt-4 text-base/7 font-semibold text-gray-900">{opening.salary}</dd>
                          <dt className="sr-only">Location</dt>
                          <dd className="mt-4 flex items-center gap-x-3 text-base/7 text-gray-500">
                            <svg viewBox="0 0 2 2" aria-hidden="true" className="size-0.5 flex-none fill-gray-300">
                              <circle r={1} cx={1} cy={1} />
                            </svg>
                            {opening.location}
                          </dd>
                        </dl>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-8 flex border-t border-gray-100 pt-8">
                    <a href="#" className="text-sm/6 font-semibold text-blue-700 hover:text-blue-500">
                      Bekijk alle vacatures <span aria-hidden="true">&rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Newsletter section */}


          {/* Testimonials section */}
          {/* <div className="relative isolate mt-32 sm:mt-56 sm:pt-32">
            <svg
              aria-hidden="true"
              className="absolute inset-0 -z-10 hidden size-full stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)] sm:block"
            >
              <defs>
                <pattern
                  x="50%"
                  y={0}
                  id="55d3d46d-692e-45f2-becd-d8bdc9344f45"
                  width={200}
                  height={200}
                  patternUnits="userSpaceOnUse"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y={0} className="overflow-visible fill-gray-50">
                <path
                  d="M-200.5 0h201v201h-201Z M599.5 0h201v201h-201Z M399.5 400h201v201h-201Z M-400.5 600h201v201h-201Z"
                  strokeWidth={0}
                />
              </svg>
              <rect fill="url(#55d3d46d-692e-45f2-becd-d8bdc9344f45)" width="100%" height="100%" strokeWidth={0} />
            </svg>
            <div className="relative">
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-1/2 -z-10 -translate-y-1/2 transform-gpu overflow-hidden opacity-30 blur-3xl"
              >
                <div
                  style={{
                    clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                  className="ml-[max(50%,38rem)] aspect-[1313/771] w-[82.0625rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc]"
                />
              </div>
              <div
                aria-hidden="true"
                className="absolute inset-x-0 top-0 -z-10 flex transform-gpu overflow-hidden pt-8 opacity-25 blur-3xl xl:justify-end"
              >
                <div
                  style={{
                    clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                  className="ml-[-22rem] aspect-[1313/771] w-[82.0625rem] flex-none origin-top-right rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] xl:ml-0 xl:mr-[calc(50%-12rem)]"
                />
              </div>
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto max-w-2xl sm:text-center">
                  <h2 className="text-base/7 font-semibold text-blue-700">Testimonials</h2>
                  <p className="mt-2 text-pretty text-4xl font-semibold tracking-tight text-gray-900 sm:text-balance sm:text-5xl">
                    We have worked with thousands of amazing people
                  </p>
                </div>
                <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm/6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
                  <figure className="col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1">
                    <blockquote className="p-12 text-xl/8 font-semibold tracking-tight text-gray-900">
                      <p>{`“${featuredTestimonial.body}”`}</p>
                    </blockquote>
                    <figcaption className="flex items-center gap-x-4 border-t border-gray-900/10 px-6 py-4">
                      <img
                        alt=""
                        src={featuredTestimonial.author.imageUrl}
                        className="size-10 flex-none rounded-full bg-gray-50"
                      />
                      <div className="flex-auto">
                        <div className="font-semibold">{featuredTestimonial.author.name}</div>
                        <div className="text-gray-600">{`@${featuredTestimonial.author.handle}`}</div>
                      </div>
                      <img alt="" src={featuredTestimonial.author.logoUrl} className="h-10 w-auto flex-none" />
                    </figcaption>
                  </figure>
                  {testimonials.map((columnGroup, columnGroupIdx) => (
                    <div key={columnGroupIdx} className="space-y-8 xl:contents xl:space-y-0">
                      {columnGroup.map((column, columnIdx) => (
                        <div
                          key={columnIdx}
                          className={classNames(
                            (columnGroupIdx === 0 && columnIdx === 0) ||
                              (columnGroupIdx === testimonials.length - 1 && columnIdx === columnGroup.length - 1)
                              ? 'xl:row-span-2'
                              : 'xl:row-start-1',
                            'space-y-8',
                          )}
                        >
                          {column.map((testimonial) => (
                            <figure
                              key={testimonial.author.handle}
                              className="rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5"
                            >
                              <blockquote className="text-gray-900">
                                <p>{`“${testimonial.body}”`}</p>
                              </blockquote>
                              <figcaption className="mt-6 flex items-center gap-x-4">
                                <img
                                  alt=""
                                  src={testimonial.author.imageUrl}
                                  className="size-10 rounded-full bg-gray-50"
                                />
                                <div>
                                  <div className="font-semibold">{testimonial.author.name}</div>
                                  <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                                </div>
                              </figcaption>
                            </figure>
                          ))}
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div> */}
        </main>

        {/* Footer */}
        {/* <footer className="mt-32 bg-gray-900 sm:mt-56">
          <div className="mx-auto max-w-7xl px-6 pb-8 pt-16 sm:pt-24 lg:px-8 lg:pt-32">
            <div className="xl:grid xl:grid-cols-3 xl:gap-8">
              <img
                alt="Company name"
                src="https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=500"
                className="h-9"
              />
              <div className="mt-16 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-sm/6 font-semibold text-white">Solutions</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {footerNavigation.solutions.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-sm/6 text-gray-400 hover:text-white">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-10 md:mt-0">
                    <h3 className="text-sm/6 font-semibold text-white">Support</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {footerNavigation.support.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-sm/6 text-gray-400 hover:text-white">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="md:grid md:grid-cols-2 md:gap-8">
                  <div>
                    <h3 className="text-sm/6 font-semibold text-white">Company</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {footerNavigation.company.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-sm/6 text-gray-400 hover:text-white">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="mt-10 md:mt-0">
                    <h3 className="text-sm/6 font-semibold text-white">Legal</h3>
                    <ul role="list" className="mt-6 space-y-4">
                      {footerNavigation.legal.map((item) => (
                        <li key={item.name}>
                          <a href={item.href} className="text-sm/6 text-gray-400 hover:text-white">
                            {item.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-16 border-t border-white/10 pt-8 sm:mt-20 lg:mt-24 lg:flex lg:items-center lg:justify-between">
              <div>
                <h3 className="text-sm/6 font-semibold text-white">Subscribe to our newsletter</h3>
                <p className="mt-2 text-sm/6 text-gray-300">
                  The latest news, articles, and resources, sent to your inbox weekly.
                </p>
              </div>
              <form className="mt-6 sm:flex sm:max-w-md lg:mt-0">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email-address"
                  type="email"
                  required
                  placeholder="Enter your email"
                  autoComplete="email"
                  className="w-full min-w-0 appearance-none rounded-md border-0 bg-white/5 px-3 py-1.5 text-base text-white shadow-sm ring-1 ring-inset ring-white/10 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:w-56 sm:text-sm/6"
                />
                <div className="mt-4 sm:ml-4 sm:mt-0 sm:shrink-0">
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
                  >
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
            <div className="mt-8 border-t border-white/10 pt-8 md:flex md:items-center md:justify-between">
              <div className="flex gap-x-6 md:order-2">
                {footerNavigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-300">
                    <span className="sr-only">{item.name}</span>
                    <item.icon aria-hidden="true" className="size-6" />
                  </a>
                ))}
              </div>
              <p className="mt-8 text-sm/6 text-gray-400 md:order-1 md:mt-0">
                &copy; 2024 Your Company, Inc. All rights reserved.
              </p>
            </div>
          </div>
        </footer> */}
      </div>
    </Layout>
  )
}
