'use client'

import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Menu, MenuButton, MenuItem, MenuItems, TransitionChild } from '@headlessui/react'
import {

    PlusCircleIcon,

} from '@heroicons/react/24/outline'
import Layout from '../../layout'




function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Notities() {
    const [todos, setTodos] = useState([
        {
            id: 1,
            name: 'Wade Cooper',
            text: '',
            completed: false,
            date: '',
            person: '',
        },
    ]);
    const addTodo = () => {
        const newTodo = {
            id: Date.now(),
            name: 'Nieuw Item',
            text: '',
            completed: false,
            date: '',
            person: '',
        };
        setTodos([newTodo, ...todos]);
    };



    return (
        <>
            <Layout>
                <div className='h-screen overflow-auto'>


                    <main className="py-10 bg-white rounded-l-lg  min-h-screen ">
                        <div className="px-4 sm:px-6 lg:px-8 space-y-6 ">
                            {/* Page Title */}
                            <div>
                                <h1 className="text-3xl font-bold text-slate-700">Notities</h1>
                            </div>

                            {/* Project Title */}
                            <div className="flex items-center justify-between">
                                {/* Text Section */}
                                <div>
                                    <div className="mt-2 text-xl font-semibold text-slate-700">Kringloop Winkel</div>
                                    <h2 className="text-lg font-base text-slate-400 mb-4">Volgorde op laatst geplaatst</h2>
                                </div>

                                {/* Button Section */}
                                <div className="pt-4">
                                    <button
                                        type="button"
                                        onClick={addTodo}
                                        className="inline-flex items-center justify-start w-full bg-white px-4 py-2 text-sm font-medium text-blue-700 hover:text-blue-400 space-x-2"
                                    >
                                        <PlusCircleIcon className="size-5 text-blue-700 hover:text-blue-400" aria-hidden="true" />
                                        <span>Nieuwe update schrijven</span>
                                    </button>
                                </div>
                            </div>

                            {/* To-Do Table */}
                            <div className="space-y-4">
                                {todos.map((todo) => (
                                    <div key={todo.id} className="bg-white p-4 border-t border-gray-200 flex flex-col space-y-4">
                                        {/* Titel met de naam van de persoon */}
                                        <div className="text-base font text-gray-900">{todo.name}</div>

                                        {/* Checkbox en Dynamisch Textveld */}
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                checked={todo.completed}
                                                onChange={(e) =>
                                                    setTodos(
                                                        todos.map((t) =>
                                                            t.id === todo.id ? { ...t, completed: e.target.checked } : t
                                                        )
                                                    )
                                                }
                                                className="h-5 w-5 mr-4 rounded text-blue-700 focus:ring-indigo-500 border-gray-300"
                                            />
                                            <textarea
                                                placeholder="Wat heeft het Talent vandaag gedaan?"
                                                value={todo.text}
                                                onChange={(e) =>
                                                    setTodos(
                                                        todos.map((t) =>
                                                            t.id === todo.id ? { ...t, text: e.target.value } : t
                                                        )
                                                    )
                                                }
                                                rows={1}
                                                onInput={(e) => {
                                                    const target = e.target as HTMLTextAreaElement;
                                                    target.style.height = 'auto'; // Reset de hoogte om juiste scrollHeight te krijgen
                                                    target.style.height = `${target.scrollHeight}px`; // Stel de hoogte in op basis van inhoud
                                                }}
                                                className="w-full border-none focus:ring-0 text-gray-900 placeholder-gray-400 resize-none overflow-hidden"
                                            ></textarea>
                                        </div>

                                        {/* Action Buttons */}
                                        <div className="flex items-center justify-between">
                                            {/* Opslaan Button */}
                                            <button
                                                type="button"
                                                className="inline-flex items-center px-3 py-1.5 text-xs font-medium rounded-lg bg-blue-600 text-white hover:bg-indigo-500 focus:ring-2 focus:ring-indigo-500"
                                            >
                                                Opslaan
                                            </button>

                                            {/* Datum Selector en Persoon Selector */}
                                            <div className="flex items-center space-x-4">
                                                {/* Datum Selector */}
                                                <input
                                                    type="date"
                                                    value={todo.date}
                                                    onChange={(e) =>
                                                        setTodos(
                                                            todos.map((t) =>
                                                                t.id === todo.id ? { ...t, date: e.target.value } : t
                                                            )
                                                        )
                                                    }
                                                    className="inline-flex items-center px-3 py-1.5 text-xs font-medium rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-2 focus:ring-indigo-500"
                                                />

                                                {/* Persoon Selector */}
                                                <Listbox value={todo.person} onChange={(person) =>
                                                    setTodos(
                                                        todos.map((t) =>
                                                            t.id === todo.id ? { ...t, person } : t
                                                        )
                                                    )
                                                }>
                                                    {({ open }) => (
                                                        <div className="relative">
                                                            <Listbox.Button className="inline-flex items-center px-3 py-1.5 text-xs font-medium rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200 focus:ring-2 focus:ring-indigo-500">
                                                                Persoon
                                                            </Listbox.Button>
                                                            {open && (
                                                                <Listbox.Options className="absolute mt-1 w-48 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
                                                                    <input
                                                                        type="text"
                                                                        placeholder="Zoek een persoon"
                                                                        className="w-full px-3 py-2 border-b text-sm text-gray-900 focus:outline-none focus:ring-indigo-500"
                                                                    />
                                                                    <Listbox.Option
                                                                        className="px-4 py-2 text-sm text-gray-900 hover:bg-gray-100"
                                                                        value="Persoon A"
                                                                    >
                                                                        Persoon A
                                                                    </Listbox.Option>
                                                                    <Listbox.Option
                                                                        className="px-4 py-2 text-sm text-gray-900 hover:bg-gray-100"
                                                                        value="Persoon B"
                                                                    >
                                                                        Persoon B
                                                                    </Listbox.Option>
                                                                </Listbox.Options>
                                                            )}
                                                        </div>
                                                    )}
                                                </Listbox>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        {/* Add Task Button */}
                        <div className="pt-4 ">
                            <button
                                type="button"
                                className="inline-flex items-center justify-start w-full bg-white px-4 py-2 text-sm font-medium text-slate-400 hover:text-blue-700  space-x-2"
                            >
                                <PlusCircleIcon className="size-5 text-slate-400 hover:text-blue-700" aria-hidden="true" />
                                <span>Nieuwe update schrijven</span>
                            </button>
                        </div>
                </div>
            </main>
        </div >
            </Layout >
        </>
    )
}
