import React, { useEffect, useState } from 'react';
import { createEvaluatie, EvaluatieModel, NewEvaluatieModel } from '../../domain/evaluatie-utils';
import { UserModel } from '../../domain/user-utils';
import { Listbox } from '@headlessui/react';
import { Controller } from 'react-hook-form';
import talenten from '../subnav/talenten/talenten';
import { getTalents, TalentModel } from '../../domain/talent-utils';



const Updateschrijven: React.FC<{ user: UserModel, talentId?: string }> = ({ user, talentId }) => {
    const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([]);
    const [newUpdate, setNewUpdate] = useState<string>('');
    const [rating, setRating] = useState<number>(10);
    const [moodLevel, setMoodLevel] = useState<number>(5);
    const [message, setMessage] = useState<string | null>(null);
    const [selectedTalentId, setSelectedTalentId] = useState(talentId);
    const [talenten, setTalenten] = useState<TalentModel[]>([]);

    const moodEmojiMap = [
        '😡', // 1
        '😟', // 2
        '😔', // 3
        '😞', // 4
        '😐', // 5
        '🙂', // 6
        '😊', // 7
        '😃', // 8
        '😁', // 9
        '😍', // 10
    ];

    const handleUpdateSubmit = () => {
        if (!newUpdate.trim()) return;

        const newEntry: NewEvaluatieModel = {
            content: newUpdate,
            auteurId: user.ref.id,
            talentId: selectedTalentId!,
            cijfer: rating,
            gevoel: moodLevel
        };

        createEvaluatie(user, newEntry).then((res) => {
            window.location.reload()

            createEvaluatie(user, newEntry)
            .then(() => {
                setMessage('✅ Update opgeslagen!');
                setNewUpdate('');
                setTimeout(() => setMessage(null), 3000); 
            })
            .catch(() => {
                setMessage('❌ Er ging iets mis, probeer opnieuw.');
            });
        })
    };


    
    useEffect(() => {
        if (talentId)return
        getTalents(200, user!).then((talenten)=>{
            setTalenten(talenten)
        })
    },[])


    return (
        <div className="">
            {/* <h2 className="text-lg font-semibold text-slate-700">Nieuwe Update</h2> */}

            {!talentId &&
                <Listbox
                    value={selectedTalentId}
                    onChange={setSelectedTalentId}
                >
                    {({ open }) => (
                        <div className="relative">
                            <Listbox.Button className="px-3 py-1.5 text-sm font-medium rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200">
                                {selectedTalentId ? talenten.find((talent) => talent.id == selectedTalentId)?.volledige_naam : "Selecteer het Talent"}
                            </Listbox.Button>
                            {open && (
                                <Listbox.Options className="absolute mt-1 w-48 bg-white shadow-lg ring-1 ring-black/5 rounded-md max-h-96 overflow-scroll text-gray-700">
                                    {talenten.map((talent) =>
                                        <Listbox.Option key={talent.id} value={talent.id} className="px-4 py-2 hover:bg-gray-100 text-sm">
                                            {talent.volledige_naam}
                                        </Listbox.Option>
                                    )}
                                </Listbox.Options>
                            )}
                        </div>
                    )}
                </Listbox>
            }

            <textarea
                value={newUpdate}
                onChange={(e) => setNewUpdate(e.target.value)}
                rows={8}
                placeholder="Schrijf een update over het Talent, beschrijf hoe het gaat en wat er gedaan is vandaag"
                className="block w-full rounded-md border-slate-200 mt-2 text-slate-700 sm:text-sm placeholder-black/50"
            />
            <div className="mt-4 grid grid-cols-1 gap-6 sm:grid-cols-2">
                {/* Slider voor cijfer */}
                <div>
                    <label htmlFor="rating" className="block text-sm font-medium text-slate-700">
                        Cijfer voor vandaag
                    </label>
                    <div className="flex items-center space-x-4 mt-2">
                        <input
                            id="rating"
                            type="range"
                            min="1"
                            max="10"
                            value={rating}
                            onChange={(e) => setRating(Number(e.target.value))}
                            className="w-full accent-blue-700"
                        />
                        <span className="w-8 text-center text-2xl font-semibold text-slate-700">
                            {rating}
                        </span>
                    </div>
                </div>

                {/* Slider voor gevoel */}
                <div>
                    <label htmlFor="mood" className="block text-sm font-medium text-slate-700">
                        Hoe voelt het Talent zich?
                    </label>
                    <div className="flex items-center space-x-4 mt-2">
                        <input
                            id="mood"
                            type="range"
                            min="1"
                            max="10"
                            value={moodLevel}
                            onChange={(e) => setMoodLevel(Number(e.target.value))}
                            className="w-full accent-blue-700"
                        />
                        <span className="w-8 text-center text-4xl font-semibold text-white">
                            {moodEmojiMap[moodLevel - 1]}
                        </span>
                    </div>
                </div>
            </div>
            <div className="mt-4 bottom">
                <button
                    onClick={handleUpdateSubmit}
                    className="w-full inline-flex justify-center items-center rounded-md border border-blue-700 px-4 py-2 text-sm font-medium text-blue-700 hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                    Opslaan
                </button>
            </div>
            {message && (
                <p className="mt-2 text-center text-sm font-medium">
                    {message}
                </p>
            )}
        </div>
    );
};

export default Updateschrijven;