import { useState } from 'react';

type Prompt = {
  id: number;
  title: string;
  content: string;
};

const initialPrompts: Prompt[] = [
  { id: 1, title: 'Prompt 1', content: 'Enter your first prompt here.' },
  { id: 2, title: 'Prompt 2', content: 'Enter your second prompt here.' },
  { id: 3, title: 'Prompt 3', content: 'Enter your third prompt here.' },
  { id: 4, title: 'Prompt 4', content: 'Enter your fourth prompt here.' },
  { id: 5, title: 'Prompt 5', content: 'Enter your fifth prompt here.' },
  { id: 6, title: 'Prompt 6', content: 'Enter your sixth prompt here.' },
];

export default function PromptsEditor() {
  const [prompts, setPrompts] = useState(initialPrompts);
  const [editingId, setEditingId] = useState<number | null>(null);
  const [newPrompt, setNewPrompt] = useState({ title: '', content: '' });

  // Handle Editing
  const handleEdit = (id: number) => setEditingId(id);
  const handleSave = (id: number, title: string, content: string) => {
    setPrompts((prev) =>
      prev.map((p) => (p.id === id ? { ...p, title, content } : p))
    );
    setEditingId(null);
  };
  const handleDelete = (id: number) =>
    setPrompts((prev) => prev.filter((p) => p.id !== id));

  // Handle New Prompt Addition
  const handleAddPrompt = () => {
    if (!newPrompt.title || !newPrompt.content) return;
    setPrompts((prev) => [
      ...prev,
      { id: prev.length + 1, title: newPrompt.title, content: newPrompt.content },
    ]);
    setNewPrompt({ title: '', content: '' });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Edit Prompts</h1>
      <table className="w-full border-collapse border border-gray-300">
        <thead>
          <tr className="bg-gray-100">
            <th className="border p-2 text-left">Title</th>
            <th className="border p-2 text-left">Content</th>
            <th className="border p-2 text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {prompts.map((prompt) => (
            <tr key={prompt.id} className="border">
              {editingId === prompt.id ? (
                <>
                  <td className="p-2">
                    <input
                      type="text"
                      className="w-full p-1 border rounded"
                      defaultValue={prompt.title}
                      onChange={(e) => (prompt.title = e.target.value)}
                    />
                  </td>
                  <td className="p-2">
                    <input
                      type="text"
                      className="w-full p-1 border rounded"
                      defaultValue={prompt.content}
                      onChange={(e) => (prompt.content = e.target.value)}
                    />
                  </td>
                  <td className="p-2 text-center">
                    <button
                      onClick={() => handleSave(prompt.id, prompt.title, prompt.content)}
                      className="px-3 py-1 bg-green-500 text-white rounded mr-2"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setEditingId(null)}
                      className="px-3 py-1 bg-gray-500 text-white rounded"
                    >
                      Cancel
                    </button>
                  </td>
                </>
              ) : (
                <>
                  <td className="p-2">{prompt.title}</td>
                  <td className="p-2">{prompt.content}</td>
                  <td className="p-2 text-center">
                    <button
                      onClick={() => handleEdit(prompt.id)}
                      className="px-3 py-1 bg-blue-500 text-white rounded mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(prompt.id)}
                      className="px-3 py-1 bg-red-500 text-white rounded"
                    >
                      Delete
                    </button>
                  </td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      {/* Add New Prompt */}
      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-2">Add New Prompt</h2>
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="Title"
            className="flex-1 p-2 border rounded"
            value={newPrompt.title}
            onChange={(e) => setNewPrompt({ ...newPrompt, title: e.target.value })}
          />
          <input
            type="text"
            placeholder="Content"
            className="flex-1 p-2 border rounded"
            value={newPrompt.content}
            onChange={(e) => setNewPrompt({ ...newPrompt, content: e.target.value })}
          />
          <button
            onClick={handleAddPrompt}
            className="px-4 py-2 bg-indigo-600 text-white rounded"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}