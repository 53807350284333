import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { getTalentById, TalentModel } from '../../../domain/talent-utils';
import { getLopendTrajectById, LopendTrajectModel } from '../../../domain/lopend-traject-utils';
import Layout from '../../../layout';
import Breadcrumbs from '../../../components/breadcrumb/breadcrumbs';
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import Tabbarmobile from '../../../components/tabbar/folder/Tabbarmobile';
import Trajectprogress from '../../../components/traject/trajectprogress/Trajectprogress';
import TalentTab from '../../../components/talent/App';
import { BedrijfModel } from '../../../domain/bedrijf-utils';

export default function Example() {

  const { talentId } = useParams()

  const [talent, setTalent] = useState<TalentModel>()
  const [lopendTraject, setLopendTraject] = useState<LopendTrajectModel | null>(null)
  const [bedrijf, setBedrjf] = useState<BedrijfModel | null>(null);

  useEffect(() => {
    getTalentById(talentId, { traject: true }).then((talent) => {
      getLopendTrajectById(talent.lopend_traject_id).then((lopendTraject) => {
        setTalent(talent)
        setLopendTraject(lopendTraject)
      })
    });
  }, [])

  return (
    <>
      <Layout>
        <Breadcrumbs personName={talent?.volledige_naam ?? ""} />


        <div className="min-h-full pb-36">
          <div className="flex h-full flex-col overflow-y-scroll bg-white">
            <div>
              <div className="pb-1 sm:pb-6">
                <div>
                  <div className="relative h-96">
                    <img
                      alt=""
                      src={talent?.foto}
                      className="absolute size-full object-cover"
                    />
                  </div>
                  <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                    <div className="sm:flex-1">
                      <div>
                        <div className="flex items-center">
                          <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{talent?.volledige_naam}</h3>
                          <span className="ml-2.5 inline-block size-2 shrink-0 rounded-full bg-green-400">
                            <span className="sr-only">Online</span>
                          </span>
                        </div>
                        <p className="text-sm text-gray-500">@ashleyporter</p>
                      </div>
                      <div className="mt-5 flex flex-wrap space-y-3 sm:space-x-3 sm:space-y-0">
                        <button
                          type="button"
                          className="inline-flex w-full shrink-0 items-center justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:flex-1"
                        >
                          Message
                        </button>
                        <button
                          type="button"
                          className="inline-flex w-full flex-1 items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                        >
                          Call
                        </button>
                        <div className="ml-3 inline-flex sm:ml-0">
                          <Menu as="div" className="relative inline-block text-left">
                            <MenuButton className="relative inline-flex items-center rounded-md bg-white p-2 text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                              <span className="absolute -inset-1" />
                              <span className="sr-only">Open options menu</span>
                              <EllipsisVerticalIcon aria-hidden="true" className="size-5" />
                            </MenuButton>
                            <MenuItems
                              transition
                              className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                            >
                              <div className="py-1">
                                <MenuItem>
                                  <a
                                    href="#"
                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                  >
                                    View profile
                                  </a>
                                </MenuItem>
                                <MenuItem>
                                  <a
                                    href="#"
                                    className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 data-[focus]:outline-none"
                                  >
                                    Copy profile link
                                  </a>
                                </MenuItem>
                              </div>
                            </MenuItems>
                          </Menu>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(talent) && TalentTab(talent, bedrijf!, lopendTraject)}

            </div>
            <div className='mx-auto'>
                <Trajectprogress traject={talent?.traject} lopendTraject={lopendTraject} />
              </div>
          </div>

        </div>
        <Tabbarmobile talentId={talentId} />
      </Layout>
    </>
  )
}
