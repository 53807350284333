import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import Layout from '../../../layout';
import Tabbarmobile from '../../../components/tabbar/folder/Tabbarmobile';
import Breadcrumbs from '../../../components/breadcrumb/breadcrumbs';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getTalentById, TalentModel } from '../../../domain/talent-utils';

const days = [
  { date: '2022-01-16', day: 'S' },
  { date: '2022-01-17', day: 'M' },
  { date: '2022-01-18', day: 'T' },
  { date: '2022-01-19', day: 'W' },
  { date: '2022-01-20', day: 'T', isToday: true },
  { date: '2022-01-21', day: 'F' },
  { date: '2022-01-22', day: 'S', isSelected: true },
];

const times = Array.from({ length: 16 }, (_, i) => {
  const hour = 6 + i;
  return `${hour.toString().padStart(2, '0')}:00`;
});

export default function CalendarPage() {
  const { talentId } = useParams();
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [talent, setTalent] = useState<TalentModel>()

  const events = [
    {
      title: 'Research new project',
      time: '08:00 - 09:00',
      color: 'bg-blue-100',
      borderColor: 'border-blue-500',
      textColor: 'text-blue-700',
      startHour: 8,
      endHour: 12,
    },
    {
      title: 'Fietsenstalling',
      time: '07:30 - 09:00',
      color: 'bg-pink-50',
      borderColor: 'border-pink-500',
      textColor: 'text-pink-700',
      startHour: 7.5, // 7:30 AM as 7.5
      endHour: 14, // 9:00 AM
    },
  ];

  useEffect(() => {
    getTalentById(talentId).then((talent) => {
      setTalent(talent)
    });
  }, [])

  const handleDayClick = (date: string) => {
    setSelectedDay(new Date(date));
  };

  return (
    <Layout>
      <Breadcrumbs personName={talent?.volledige_naam ?? ""} />
      <div className="flex h-full flex-col">
        {/* Sticky Header */}
        <header className="sticky top-0 z-10 flex items-center justify-between border-b border-gray-200 bg-white px-4 py-3 shadow-md">
          <div>
            <h1 className="text-lg font-semibold text-gray-900">Rooster</h1>
            <p className="text-sm text-gray-500">
              {selectedDay.toLocaleDateString('nl-NL', {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
              })}
            </p>
          </div>
          <div className="flex items-center space-x-2">
            <button className="flex items-center justify-center rounded-full bg-gray-100 p-2 text-gray-600 hover:bg-gray-200">
              <ChevronLeftIcon className="h-5 w-5" />
            </button>
            <button className="flex items-center justify-center rounded-full bg-sky-400 px-4 py-2 text-sm font-semibold text-white hover:bg-indigo-500">
              Vandaag
            </button>
            <button className="flex items-center justify-center rounded-full bg-gray-100 p-2 text-gray-600 hover:bg-gray-200">
              <ChevronRightIcon className="h-5 w-5" />
            </button>
          </div>
        </header>

        {/* Days Navigation */}
        <div className="flex items-center justify-between bg-white px-4 py-2">
          {days.map((day, index) => (
            <button
              key={index}
              className={`flex flex-col items-center text-sm font-medium ${
                day.isToday ? 'text-blue-700' : 'text-gray-700'
              } ${
                day.date === selectedDay.toISOString().split('T')[0]
                  ? 'bg-indigo-100 rounded-lg px-2 py-1'
                  : ''
              }`}
              onClick={() => handleDayClick(day.date)}
            >
              <span>{day.day}</span>
              <span className="mt-1">{new Date(day.date).getDate()}</span>
            </button>
          ))}
        </div>

        {/* Calendar Content */}
        <div className="relative flex-1 overflow-y-auto bg-gray-50 px-4 py-4">
          <div className="relative grid grid-cols-8">
            {/* Left column for times */}
            <div className="col-span-1">
              {times.map((time, index) => (
                <div key={index} className="h-16 flex items-center text-xs text-gray-500">
                  {time}
                </div>
              ))}
            </div>

            {/* Right column for events */}
            <div className="col-span-7 relative">
              <div className="absolute w-full h-full border-l border-gray-200"></div>
              {events.map((event, index) => (
                <div
                  key={index}
                  className={`absolute left-1 w-[90%] rounded-lg border-l-4 p-3 shadow-md ${event.color} ${event.borderColor}`}
                  style={{
                    top: `${(event.startHour - 6) * 4}rem`,
                    height: `${(event.endHour - event.startHour) * 4}rem`,
                  }}
                >
                  <p className={`text-sm font-medium ${event.textColor}`}>{event.title}</p>
                  <p className={`text-xs ${event.textColor}`}>{event.time}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Floating Action Button */}
        <div className="fixed bottom-6 right-6 flex flex-col space-y-2">
          <button className="flex items-center justify-center rounded-full bg-indigo-600 p-4 text-white shadow-lg hover:bg-indigo-500">
            +
          </button>
        </div>

        <Tabbarmobile talentId={talentId} />
      </div>
    </Layout>
  );
}