import { LopendTrajectModel } from '../../domain/lopend-traject-utils'
import { TalentModel } from '../../domain/talent-utils'
import { BedrijfModel } from '../../domain/bedrijf-utils'
import Toggle from '../toggles/toggle/Toggle'
import Trajectprogress from '../traject/trajectprogress/Trajectprogress'
import updateUser from '../../domain/user-utils'
import { useState } from 'react'

export default function TalentTab(talent: TalentModel, bedrijf?: BedrijfModel, lopendTraject?: LopendTrajectModel| null) {



  const activateringOnChange = () => {
    const val = (!talent?.actief) ? true : !talent.actief
    updateUser(talent.ref, { actief: val }).then((_) => {
      talent.actief = val
      console.log("saved")
    })
  }

  const uitstroomOnChange = () => {
    const val = (!talent?.uitstroom) ? true : !talent.uitstroom
    updateUser(talent.ref, { "talent.uitstroom": val }).then((_) => {
      talent.uitstroom = val
      console.log("saved")
    })
  }

  return (
    <div className=''>
      <div className="mt-1">
        <div className=''>
          <div className="col-span-3">
            <dl className="grid grid-cols-2 sm:grid-cols-3">
              <div className="px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Traject</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.traject?.naam ?? "Onboarding"}</dd>
              </div>
              <div className="px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Casemanager</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{lopendTraject?.consulent?.voornaam}</dd>
              </div>


              <div className="px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Locatie</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{lopendTraject?.werkbedrijf?.naam}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Adres</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.straat} {talent?.huisnummer} {talent?.postcode} {talent?.gemeente}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Email</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  <a href={`mailto:${talent?.email}`} className="text-blue-600 hover:underline">
                    {talent?.email}
                  </a>
                </dd>              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Telefoon</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.telefoonnummer}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Gemeente</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{bedrijf?.plaats}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Rol</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.rol}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Nationaliteit</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{talent?.nationaliteit}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Actief</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2"><Toggle defaultValue={talent.actief} onChange={activateringOnChange} /></dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Uitstroom</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2"><Toggle defaultValue={talent.uitstroom} onChange={uitstroomOnChange} /></dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  <button
                    type="button"
                    className="flex-1 sm:flex-none items-center justify-center rounded-md bg-slate-100 px-3 py-2 text-sm font-semibold text-black "
                  >
                    <span>Traject wijzigen</span>
                    
                  </button>
                </dd>
              </div>

              <div className="border-t border-gray-100 px-4 py-6 col-span-2 sm:col-span-3 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Talent samenvatting</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
                  **Samenvatting**
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}
