import { useState, useEffect } from 'react';
import { PlusIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon, MinusCircleIcon } from '@heroicons/react/20/solid';
import { useAuth } from '../../context/AuthContext';

type ScheduleEntry = {
  id: number;
  name: string;
  planned: string;
  registered: string;
  break: string;
  hoursPlanned: string;
  hoursRegistered: string;
  difference: string;
  status: 'pending' | 'approved' | 'denied';
};

export default function SchedulerPage() {
  const [data, setData] = useState<ScheduleEntry[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [formVisible, setFormVisible] = useState(false);
  const { user } = useAuth();
  const [selectedEntry, setSelectedEntry] = useState<ScheduleEntry | null>(null);

  const [afkeuringFormVisible, setAfkeuringFormVisible] = useState(false);
  const [afkeuringReden, setAfkeuringReden] = useState('');
  const [entryToDeny, setEntryToDeny] = useState<ScheduleEntry | null>(null);

  const [acceptModalVisible, setAcceptModalVisible] = useState(false);
  const [acceptMessage, setAcceptMessage] = useState('');
  const [approvedEntries, setApprovedEntries] = useState<ScheduleEntry[]>([]);

  const [formState, setFormState] = useState({
    name: `${user?.voornaam} ${user?.achternaam}`,
    planned: '00:00 - 00:00',
    registered: '00:00 - 00:00',
    break: '00:00',
    hoursPlanned: '00:00',
    hoursRegistered: '00:00',
    difference: '',
  });

  const breakIntervals: string[] = [];
  for (let i = 0; i < 24 * 4; i++) {
    const hours = Math.floor(i / 4).toString().padStart(2, '0');
    const minutes = (i % 4) * 15;
    breakIntervals.push(`${hours}:${minutes.toString().padStart(2, '0')}`);
  }

  const validateTime = (time: string): boolean => {
    const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    if (time.includes(' - ')) {
      const [start, end] = time.split(' - ');
      return timeRegex.test(start) && timeRegex.test(end);
    }
    return timeRegex.test(time);
  };

  const handleDeny = (entry: ScheduleEntry) => {
    setEntryToDeny(entry);
    setAfkeuringFormVisible(true);
  };

  const handleDenySubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!afkeuringReden.trim()) {
      alert('Please provide a reason for denial.');
      return;
    }

    setData((prevData) =>
      prevData.filter((item) => item.id !== entryToDeny?.id)
    );

    setAfkeuringReden('');
    setEntryToDeny(null);
    setAfkeuringFormVisible(false);
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      setTimeout(() => {
        setData([]);
        setIsLoading(false);
      }, 1000);
    };

    fetchData();
  }, []);

  const formattedDate = new Intl.DateTimeFormat('nl-NL', {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  }).format(currentDate);

  const timeToHoursAndMinutes = (time: string, breakTime: string = '00:00') => {
    const [start, end] = time.split(' - ');
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);
    const [breakHours, breakMinutes] = breakTime.split(':').map(Number);

    const startTotalMinutes = startHours * 60 + startMinutes;
    const endTotalMinutes = endHours * 60 + endMinutes;
    const breakTotalMinutes = breakHours * 60 + breakMinutes;

    const totalMinutes = endTotalMinutes - startTotalMinutes - breakTotalMinutes;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
  };

  const calculateDifference = (planned: string, registered: string) => {
    const [plannedHours, plannedMinutes] = planned.split(':').map(Number);
    const [registeredHours, registeredMinutes] = registered.split(':').map(Number);

    const plannedTotalMinutes = plannedHours * 60 + plannedMinutes;
    const registeredTotalMinutes = registeredHours * 60 + registeredMinutes;

    const diffMinutes = registeredTotalMinutes - plannedTotalMinutes;

    const diffHours = Math.floor(Math.abs(diffMinutes) / 60);
    const remainingMinutes = Math.abs(diffMinutes % 60);

    return `${diffMinutes >= 0 ? '+' : '-'}${String(diffHours).padStart(2, '0')}:${String(remainingMinutes).padStart(2, '0')}`;
  };

  const handleAddOrUpdateEntry = (e: React.FormEvent) => {
    e.preventDefault();

    if (!validateTime(formState.planned)) {
      setFormState((prev) => ({ ...prev, planned: 'Invalid Format' }));
      return;
    }
    if (!validateTime(formState.registered)) {
      setFormState((prev) => ({ ...prev, registered: 'Invalid Format' }));
      return;
    }
    if (!validateTime(formState.break)) {
      setFormState((prev) => ({ ...prev, break: 'Invalid Format' }));
      return;
    }

    const hoursPlanned = timeToHoursAndMinutes(formState.planned);
    const hoursRegistered = timeToHoursAndMinutes(formState.registered, formState.break);
    const difference = calculateDifference(hoursPlanned, hoursRegistered);

    if (selectedEntry) {
      setData((prevData) =>
        prevData.map((item) =>
          item.id === selectedEntry.id
            ? { ...item, ...formState, hoursPlanned, hoursRegistered, difference }
            : item
        )
      );
    } else {
      const newEntry: ScheduleEntry = {
        id: Date.now(),
        name: formState.name,
        planned: formState.planned,
        registered: formState.registered,
        break: formState.break,
        hoursPlanned,
        hoursRegistered,
        difference,
        status: 'pending',
      };
      setData((prevData) => [...prevData, newEntry]);
    }

    setFormState({
      name: `${user?.voornaam} ${user?.achternaam}`,
      planned: '00:00 - 00:00',
      registered: '00:00 - 00:00',
      break: '00:00',
      hoursPlanned: '00:00',
      hoursRegistered: '00:00',
      difference: '',
    });
    setFormVisible(false);
    setSelectedEntry(null);
  };

  const handleAction = (action: 'update' | 'accept' | 'deny', entry: ScheduleEntry) => {
    setSelectedEntry(entry);
    if (action === 'update') {
      setFormState({
        name: entry.name,
        planned: entry.planned,
        registered: entry.registered,
        break: entry.break,
        hoursPlanned: entry.hoursPlanned,
        hoursRegistered: entry.hoursRegistered,
        difference: entry.difference,
      });
      setFormVisible(true);
    } else if (action === 'accept') {
      setData((prevData) =>
        prevData.filter((item) => item.id !== entry.id)
      );
      setApprovedEntries((prevApproved) => [...prevApproved, { ...entry, status: 'approved' }]);
      setAcceptMessage(`De uren van ${entry.name} zijn goedgekeurd`);
      setAcceptModalVisible(true);
    } else if (action === 'deny') {
      handleDeny(entry);
    }
  };

  // Filter data based on user role
  const filteredData = data.filter(entry => 
    user?.gebruikers_rol === 'admin' || entry.name === `${user?.voornaam} ${user?.achternaam}`
  );
  

  return (
    <div className="flex flex-col min-h-screen bg-gray-100 p-6">
      <header className="flex justify-between items-center bg-indigo-600 text-white px-6 py-4 rounded-md shadow-md mb-6">
        <div className="flex items-center gap-4">
          <button
            className="bg-indigo-500 hover:bg-indigo-700 p-2 rounded-full shadow-md"
            onClick={() =>
              setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() - 1)))
            }
          >
            <ChevronLeftIcon className="w-5 h-5" />
          </button>
          <span className="font-semibold text-lg">{formattedDate}</span>
          <button
            className="bg-indigo-500 hover:bg-indigo-700 p-2 rounded-full shadow-md"
            onClick={() =>
              setCurrentDate(new Date(currentDate.setDate(currentDate.getDate() + 1)))
            }
          >
            <ChevronRightIcon className="w-5 h-5" />
          </button>
        </div>
      </header>

      {/* Schedule Table */}
      <div className="bg-white rounded-lg shadow-lg overflow-hidden mb-6">
        <table className="table-auto w-full text-left text-sm text-gray-700">
          <thead className="bg-gray-50 border-b border-gray-200">
            <tr>
              <th className="px-6 py-3 font-medium text-gray-900">Medewerker</th>
              <th className="px-6 py-3 font-medium text-gray-900">Gepland</th>
              <th className="px-6 py-3 font-medium text-gray-900">Geregistreerd</th>
              <th className="px-6 py-3 font-medium text-gray-900">Pauze</th>
              <th className="px-6 py-3 font-medium text-gray-900">Uren Gepland</th>
              <th className="px-6 py-3 font-medium text-gray-900">Uren Geregistreerd</th>
              <th className="px-6 py-3 font-medium text-gray-900">Uren Verschil</th>
              {user?.gebruikers_rol === 'admin' && (
                <th className="px-6 py-3 font-medium text-center text-gray-900">
                  Acties
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td
                  colSpan={user?.gebruikers_rol === 'admin' ? 8 : 7}
                  className="text-center py-8 text-gray-500"
                >
                  Loading...
                </td>
              </tr>
            ) : filteredData.length > 0 ? (
              
              filteredData.map((entry) => (
                <tr key={entry.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4">{entry.name}</td>
                  <td className="px-6 py-4 text-indigo-500 font-semibold">{entry.planned}</td>
                  <td className="px-6 py-4 text-green-500 font-semibold">{entry.registered}</td>
                  <td className="px-6 py-4">{entry.break}</td>
                  <td className="px-6 py-4">{entry.hoursPlanned}</td>
                  <td className="px-6 py-4">{entry.hoursRegistered}</td>
                  <td
                    className={`px-6 py-4 ${entry.difference.startsWith('-') ? 'text-red-500' : 'text-green-500'
                      }`}
                  >
                    {entry.difference}
                  </td>
                  {user?.gebruikers_rol === 'admin' && (
                    <td className="px-6 py-4">
                      {entry.status === 'pending' && (
                        <>
                          <button
                            className="text-green-600 hover:text-green-800"
                            onClick={() => handleAction('accept', entry)}
                          >
                            <CheckIcon className="w-5 h-5" />
                          </button>
                          <button
                            className="text-red-600 hover:text-red-800 ml-3"
                            onClick={() => handleAction('deny', entry)}
                          >
                            <MinusCircleIcon className="w-5 h-5" />
                          </button>
                        </>
                      )}
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={user?.gebruikers_rol === 'admin' ? 8 : 7}
                  className="text-center py-8 text-gray-500"
                >
                  No schedule entries available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Add Entry Button */}
      {user?.gebruikers_rol  === 'talent' && (
        <div className="flex justify-end mb-8">
          <button
            onClick={() => setFormVisible(!formVisible)}
            className="bg-indigo-600 hover:bg-indigo-700 p-3 rounded-full shadow-lg text-white"
          >
            <PlusIcon className="w-6 h-6" />
          </button>
        </div>
      )}

      {/* Add/Update Form */}
      {formVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-25 flex items-center justify-center">
          <form
            onSubmit={handleAddOrUpdateEntry}
            className="bg-white rounded-lg shadow-lg p-6 w-96 space-y-4"
          >
            <h2 className="text-lg font-medium text-gray-900">
              {selectedEntry ? 'Update Schedule Entry' : 'Add Schedule Entry'}
            </h2>
            <input
              type="text"
              placeholder="Name"
              className="w-full px-4 py-2 border rounded-md"
              value={formState.name}
              onChange={(e) =>
                setFormState({ ...formState, name: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Planned (e.g., 08:00 - 17:00)"
              className="w-full px-4 py-2 border rounded-md"
              value={formState.planned}
              onChange={(e) =>
                setFormState({ ...formState, planned: e.target.value })
              }
            />
            <input
              type="text"
              placeholder="Registered (e.g., 08:00 - 17:00)"
              className="w-full px-4 py-2 border rounded-md"
              value={formState.registered}
              onChange={(e) =>
                setFormState({ ...formState, registered: e.target.value })
              }
            />
            <select
              className="w-full px-4 py-2 border rounded-md"
              value={formState.break}
              onChange={(e) =>
                setFormState({ ...formState, break: e.target.value })
              }
            >
              {breakIntervals.map((interval) => (
                <option key={interval} value={interval}>
                  {interval}
                </option>
              ))}
            </select>
            <div className="flex gap-4">
              <button
                type="submit"
                className="w-full bg-indigo-600 hover:bg-indigo-700 text-white font-medium py-2 rounded-md"
              >
                Save
              </button>
              <button
                type="button"
                className="w-full bg-gray-300 hover:bg-gray-400 py-2 rounded-md"
                onClick={() => {
                  setFormState({
                    name: `${user?.voornaam} ${user?.achternaam}`,
                    planned: '00:00 - 00:00',
                    registered: '00:00 - 00:00',
                    break: '00:00',
                    hoursPlanned: '00:00',
                    hoursRegistered: '00:00',
                    difference: '',
                  });
                  setFormVisible(false);
                  setSelectedEntry(null);
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      {/* Deny Form */}
      {afkeuringFormVisible && (
        <form onSubmit={handleDenySubmit}>
          <textarea
            value={afkeuringReden}
            onChange={(e) => setAfkeuringReden(e.target.value)}
            placeholder="Geef een reden voor afkeuren"
            required
          />
          <button type="submit">Bevestigen</button>
        </form>
      )}

      {/* Accept Modal */}
      {acceptModalVisible && (
        <div className="bg-white p-6 rounded-md shadow-lg">
          <p>{acceptMessage}</p>
          <button
            onClick={() => setAcceptModalVisible(false)}
            className="bg-blue-500 text-white py-2 px-4 rounded"
          >
            Sluiten
          </button>
        </div>
      )}
    </div>
  );
}
