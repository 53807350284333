import { collection, query, getDocs, where, limit, DocumentReference, getDoc, QueryDocumentSnapshot, SnapshotOptions, addDoc, setDoc, doc, updateDoc, WriteBatch, writeBatch } from "firebase/firestore";
import { db } from "../utils/firebase";
import { NewTrajectModel } from "../domain/traject-utils";
import { getAllUserDocumentsByTrajectId } from "./user-utils";
import { getLopendeTrajectenDocumentsByTrajectId } from "./lopend-traject-utils";
import { User } from "./interfaces/collection";
export type RemoteTraject = {
    duur: number,
    traject_consulent_reference?: DocumentReference,
    aantal_rapportages: number,
    evaluatie_momenten: number,
    jobcoach_interventies: number,
    praktijkverklaring: boolean,
    talentportfolio: boolean,
    loonwaardemeting: boolean,
    gemeente: string,
    naam: string,
    omschrijving: string,
    reference: DocumentReference,
    casemanager_references?: DocumentReference[],
    casemanagers: User[]
};

const trajectConverter = {
    toFirestore: (traject: RemoteTraject) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteTraject {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            duur: data.duur ?? 0,
            traject_consulent_reference: data.consulent_reference,
            aantal_rapportages: data.aantal_rapportages ?? 0,
            evaluatie_momenten: data.evaluatie_momenten ?? 0,
            jobcoach_interventies: data.jobcoach_interventies ?? 0,
            praktijkverklaring: data.praktijkverklaring ?? false,
            talentportfolio: data.talentportfolio ?? false,
            loonwaardemeting: data.loonwaardemeting ?? false,
            gemeente: data.gemeente,
            naam: data.naam,
            omschrijving: data.omschrijving,
            casemanager_references: data.casemanager_references ?? [],
            casemanagers: data.casemanagers ?? []
        }
    }
}

export default async (dataOwner: DocumentReference) => {

    const q = query(collection(db, "traject"), where("data_eigenaar_ref", "==", dataOwner)).withConverter(trajectConverter);
    const querySnapshot = await getDocs(q);
    const list: RemoteTraject[] = []
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list;
}

export const getTrajectDocumentById = async (id?: string) => {
    if (!id) {
        return null;
    }
    const docSnap = await getDoc(doc(db, "traject", id).withConverter(trajectConverter));

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null;
    }
};

export const getTrajectByID = async (ID?: DocumentReference) => {
    if (!ID) {
        return null;
    }
    const docSnap = await getDoc(ID.withConverter(trajectConverter));

    if (docSnap.exists()) {
        return docSnap.data()
    } else {
        return null;
    }
};

export const updateTrajectDocument = async (id: string, dataOwner: DocumentReference, data: NewTrajectModel) => {

    const batch = writeBatch(db)

    const users = await getAllUserDocumentsByTrajectId(dataOwner, id)
    const lopendTrajecten = await getLopendeTrajectenDocumentsByTrajectId(dataOwner, id)

    const updatedModel = {
        reference: id,
        aantal_rapportages: data.aantal_rapportages,
        duur: data.duur,
        evaluatie_momenten: data.evaluatie_momenten,
        gemeente: data.gemeente,
        jobcoach_interventies: data.jobcoach_interventies,
        loonwaardemeting: data.loonwaardemeting,
        naam: data.naam,
        omschrijving: data.omschrijving ?? null,
        praktijkverklaring: data.praktijkverklaring,
        talentportfolio: data.talentportfolio
    }

    for (let user of users) {
        batch.update(user.ref, {"talent.traject": updatedModel})
    }

    for (let lopendTraject of lopendTrajecten) {
        batch.update(lopendTraject.reference, {"traject": updatedModel})
    }

    batch.update(doc(db, "traject", id), data)

    // // get all users with current traject to update associated values
    // // update all lopende trajecten with traject
    // // update traject

    return batch.commit()

    // return updateDoc(doc(db, "traject", id), data)
}

export const createTrajectDocument = (data: NewTrajectModel) => {
    return addDoc(collection(db, "traject"), data)
}

export const createTrajectDocumentWithBatch = (data: NewTrajectModel, batch: WriteBatch) => {
    const reference = doc(collection(db, 'traject'))

    batch.set(reference, data)

    return reference
}

// return allTrajecten.filter((traject: TrajectModel) =>
//     traject.naam === selectedTraject.naam && traject.gemeente === selectedTraject.gemeente
//   );


export const findTrajectDocumentByCasemanager = async (casemanagerId: string) => {
    const q = query(collection(db, "traject"), where("casemanagers", "in", ['casemanagers', 'casemanager_reference'])).withConverter(trajectConverter);
    const querySnapshot = await getDocs(q);
    const list: RemoteTraject[] = []
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });  
}


