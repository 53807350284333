import { Navigate, redirect, useLocation } from "react-router-dom";
import { Analytics } from "@vercel/analytics/react"
import Tabbar from "././components/tabbar/main/Tabbar";
import Sidebar from "./components/sidebar/Sidebar";
import { useState } from "react";
import { MagnifyingGlassCircleIcon } from "@heroicons/react/24/outline";
import ReactGA from "react-ga4";
import { MagnifyingGlassIcon, UserCircleIcon } from "@heroicons/react/20/solid";

ReactGA.initialize("G-4VDSFNWWLT");

const Layout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();

  // Bepaal dynamisch de titel
  const getPageTitle = () => {
    switch (location.pathname) {
      case "/":
        return "Dashboard";
      case "/rooster":
        return "Rooster";
      case "/talentenhub":
        return "Talentenhub";
      case "/bijenkorf":
        return "Bijenkorf";
      default:
        return "Bloeiindex"; // Default titel
    }
  };

  return (
    <>
      <div className="overflow-auto h-screen">
        <Analytics />
        {/* <Navbar /> */}
        <Sidebar />

        {/* Hidden till mobile */}
        {/* <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-slate-50 px-4 pt-20 shadow-sm lg:hidden">
          <div className="absolute top-6 left-4 right-4 flex justify-between items-center">
            <h1 className="text-3xl font-bold">Bloeiindex</h1>
            <div className="flex gap-4">
              <button className="p-2 bg-gray-800 rounded-full">
                <MagnifyingGlassIcon className="w-6 h-6 text-white" />
              </button>
              <button className="p-2 bg-gray-800 rounded-full">
                <UserCircleIcon className="w-6 h-6 text-white" />
              </button>
            </div>
          </div>
        </div> */}


        <main className="lg:pl-72 h-[calc(100vh-80px)] pb-6">{children}</main>


        <Tabbar />
      </div>
    </>
  )
}

export default Layout