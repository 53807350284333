
import Layout from '../../../layout'
import Beheer from '../../../components/subnav/beheer/Beheer'
import { useEffect, useState } from 'react'
import { sendEmailVerification, sendPasswordResetEmail, User } from 'firebase/auth'
import { getAllNonTalents, UserModel } from '../../../domain/user-utils'
import { useAuth } from '../../../context/AuthContext'
import DefaultImage from '../../../components/defaultimage/defaultimage'
import { auth, functions } from '../../../utils/firebase'
import { httpsCallable } from 'firebase/functions'
import CreateAccountModal from '../../../components/modal/adduser/Adduser'
import { SubmitHandler, useForm } from 'react-hook-form'

type Inputs = {
  rol: string,
  email: string
}

export default function Example() {

  const { user } = useAuth()
  const [personen, setPersonen] = useState<UserModel[]>([])
  const [values, setValues] = useState<{rol: string, email: string}>()

  const {
      register,
      handleSubmit,
      reset,
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = (data) => {
      setValues(data)
      setIsModalOpen(true)
    }

  useEffect(() => {
    getAllNonTalents(user!).then((personen) => setPersonen(personen))
  }, [])

  const inviteUser = (data) => {
    httpsCallable<{ email: string, voornaam: string, achternaam: string, geslacht: string, rol: string, profiel_foto: string, data_eigenaar: string }, { status: string }>(functions, 'generate-account')({ email: data.email, voornaam: data.voornaam, achternaam: data.achternaam, geslacht: data.geslacht, rol: data.rol, profiel_foto: "", data_eigenaar: user!.data_eigenaar_ref!.id }).then(() => {
      setIsModalOpen(false)
      reset()
    }).catch(() => {
      alert("Er is iets fout gegaan.")
    })
  }

  const sendPasswordReset = (email) => {
    sendPasswordResetEmail(auth, email).then(() => {
      alert("Wachtwoord reset mail is verzonden.")
    })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    getAllNonTalents(user!).then((personen) => setPersonen(personen))
  }, [])

  return (
    <Layout>
      <div className="mx-auto lg:flex lg:gap-x-16 lg:px-8">
        <div>
          <Beheer />
        </div>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
          <div className="mx-auto max-w-md sm:max-w-3xl">
            <div>
              <div className="text-center">
                <svg
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 48 48"
                  aria-hidden="true"
                  className="mx-auto h-12 w-12 text-gray-400"
                >
                  <path
                    d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <h2 className="mt-2 text-base font-semibold text-gray-900">Add team members</h2>
                <p className="mt-1 text-sm text-gray-500">You haven’t added any team members to your project yet.</p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)} className='flex justify-between gap-x-2 py-5'>
                <label htmlFor="emails" className="sr-only">
                  Email addresses
                </label>
                <div className="grid grid-cols-1 sm:flex-auto">
                  <input
                    {...register("email", {required: true})}
                    type="text"
                    placeholder="Enter an email"
                    className="peer relative col-start-1 row-start-1 border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm/6"
                  />
                  <div
                    aria-hidden="true"
                    className="col-start-1 col-end-3 row-start-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 peer-focus:ring-2 peer-focus:ring-indigo-600"
                  />
                  <div className="col-start-2 row-start-1 flex items-center">
                    <span aria-hidden="true" className="h-4 w-px flex-none bg-gray-200" />
                    <label htmlFor="role" className="sr-only">
                      Role
                    </label>
                    <select
                      {...register("rol", {required: true})}
                      className="rounded-md border-0 bg-transparent py-1.5 pl-4 pr-7 text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                    >
                      {/* Gemeente */}
                      <option value={"werkvloerbegeleider"}>Werkvloer Begeleider</option>
                      {/* Gemeente */}
                      <option value={"consulent"}>Consulent</option>
                      {/* Uitstroom */}
                      <option value={"admin"}>Admin</option>
                      {/* Jobcoach */}
                      <option value={"jobcoach"}>Jobcoach</option>
                      {/* Intern */}
                      <option value={"uitstroom"}>Uitstroom</option>
                    </select>
                  </div>
                </div>
                <div className="mt-3 sm:ml-4 sm:mt-0 sm:flex-shrink-0">
                  <button
                    type="submit"
                    className="block w-full rounded-md bg-blue-700 px-6 py-6 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Create account
                  </button>
                </div>
              </form>
            </div>
            <div className="mt-10">
              <h3 className="text-sm font-medium text-gray-500">Recommended team members</h3>
              <ul role="list" className="divide-y divide-gray-100">
                {personen.map((persoon) => (
                  <li key={persoon?.email} className="flex justify-between gap-x-6 py-5">
                    <div className="flex min-w-0 gap-x-4">
                      <DefaultImage profiel_foto={persoon?.profiel_foto} className={"h-12 w-12 flex-none rounded-full bg-gray-50"} />
                      <div className="min-w-0 flex-auto">
                        <p className="text-sm/6 font-semibold text-gray-900">{persoon?.voornaam} {persoon?.achternaam}</p>
                        <p className="text-sm/6 text-gray-900">{persoon?.gebruikers_rol}</p>
                        <p className="mt-1 truncate text-xs/5 text-gray-500">{persoon?.email}</p>
                      </div>
                    </div>
                    <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                      {/* Buttons and Switch */}
                      <div className="mt-2 flex items-center space-x-3">
                        {!persoon.heeftAccount ?
                          <button
                            type="button"
                            className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-indigo-600 rounded-md shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-indigo-500 focus-visible:ring-offset-2"
                          >
                            <svg
                              className="w-4 h-4 mr-1"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M11 17l-1 4m-5-4l-1 4m1-4H3a1 1 0 011-1h4a1 1 0 011 1zm13-2a4 4 0 11-8 0 4 4 0 018 0zM15 10h2a2 2 0 00-2-2V6m2 2H9a2 2 0 00-2 2v4a2 2 0 002 2h8" />
                            </svg>
                            Invite
                          </button>
                          :
                          <>
                            <button
                              onClick={() => { sendPasswordReset(persoon.email) }}
                              type="button"
                              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-red-600 rounded-md shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                            >
                              <svg
                                className="w-4 h-4 mr-1"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path d="M19 7H5m7-4v4M6 7v12a2 2 0 002 2h8a2 2 0 002-2V7m-5 4v4m0-4H9m4 0v4m0-4h2" />
                              </svg>
                              Reset wachtwoord
                            </button>
                            <label className="flex items-center">
                              <input
                                type="checkbox"
                                className="sr-only"
                                onChange={(e) => console.log(`${persoon?.voornaam} account is ${e.target.checked ? 'active' : 'inactive'}`)}
                              />
                              <div className="w-10 h-5 bg-gray-200 rounded-full shadow-inner">
                                <div className="h-5 w-5 transform bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out" />
                              </div>
                              <span className="ml-2 text-sm text-gray-600">{`${true ? 'Active' : 'Inactive'
                                }`}</span>
                            </label>
                          </>
                        }
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </main>
      </div>
      <CreateAccountModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} onSuccess={(data) => inviteUser(data)} values={values} />

    </Layout>
  )
  
}
