'use client';

import { Dialog } from '@headlessui/react';
import { useEffect, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../utils/firebase";
import { TalentModel } from '../../../domain/talent-utils';
import { getAllBedrijven, BedrijfModel } from '../../../domain/bedrijf-utils';

interface EditUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  userData: {
    voornaam: string;
    achternaam: string;
    locatie: string;
    nationaliteit: string;
    traject: string;
    gemeente: string;
    telefoon_nummer: string;
    straat: string;
    huisnummer: string;
    postcode: string;
    casemanager: string;
    profiel_foto?: string;
    bedrijf?: {  
      Plaats: string;
    };
  };
  selectedPerson: TalentModel | null;
  bedrijf: BedrijfModel | null;
  onSave: () => void;
}


// Kijk of dit uit 'bedrijf" kan komen "naam" en "locatie"
const locationGemeenteMap: Record<string, string> = {
  "Martinifabriek": "Groningen",
  "FietsenwachtRijswijk": "Rijswijk",
  "CleanCircle": "Den Haag",
  "BouwTechniek": "Pijnacker"
};

// Kringloop Rijswijk : Rijswijk
//Clean Circle : Rijswijk
//Dude Goed : Rijswijk
//Bouw en Techniek : Rijswijk
//Fietsenwacht Station Nootdorp : Pijnacker(?)
//Fietsenwacht Bogaardplein : Den Haag(?)
//Fietsenwacht Station Rijswijk : Den Haag(?)
//Fietsenwacht Station Pijnacker : Den Haag(?)
//Werk aan de winkel : Rijswijk 
//Fietsenwacht :  Rijwijk 
//Dude Goed Toko : Rijswijk 
//Leyweg : Rijswijk(?)
//De Martinifabriek : Rijswijk
   

//  useEffect(() => {
//     const getBedrijven = async () => {
//       const fetchedBedrijven = await getAllBedrijven();
//       setBedrijven(fetchedBedrijven);
//     }; 
//     getBedrijven();
//   }, []);                                  


export default function EditUserModal({ isOpen, onClose, userData, selectedPerson, onSave }: EditUserModalProps) {
  const [formData, setFormData] = useState(userData);
  //const [bedrijven, setBedrijven] = useState<BedrijfModel[]>([]);
  // const [selectedPerson, setSelectedPerson] = useState<TalentModel | null>(null);

  console.log("Selected Person Data:", selectedPerson);

  const handleSave = async () => {
    try {
      if (!selectedPerson?.id) {
        alert("Geen gebruiker geselecteerd. Probeer opnieuw.");
        return;
      }

      const userDocRef = doc(db, "user", selectedPerson.id,);

      const updatedData = Object.fromEntries(
        Object.entries(formData).filter(([key, value]) =>
          value !== selectedPerson[key as keyof TalentModel] &&
          value !== undefined &&
          value !== null
        ).map(([key, value]) => {
          if (["gemeente", "huisnummer", "nationaliteit", "straat", "postcode"].includes(key)) {
            return [`talent.${key}`, value];
          }
          if (["naam", "gemeente", "huisnummer"].includes(key)) {
            return [`traject.${key}`, value];
          }
          return [key, value];
        })
      );
     
    //lupdYUTmV2mBNchdEyce (Maria)
    // Gemeente (werkt niet helemaal)
    // Gemeente veranderd ook de laatste gedeelte van adress
    // Casemanager (Ook al veranderd het het staat er in als id, als het wordt aangepast komt de value
    // (de naam) erin te staan.
    

      //0fmYdYDgnnU7eSmKeSds
      // telefoon_nummer, Voornaam en achternaam moet misschien gesplist worden.
      // profiel_foto staat goed maar wordt erin gezet niet bijgewerkt.
      // Casemanger staat id in en op talenthub naam, veranderd niet.
      

      if (Object.keys(updatedData).length === 0) {
        alert("Geen wijzigingen om op te slaan.");
        return;
      }

      console.log("Updating with data:", updatedData);

      await updateDoc(userDocRef, updatedData);

      console.log("User updated successfully");
      onSave();
      onClose();
    } catch (error) {
      console.error("Error updating user:", error);
      alert("Failed to save changes. Please try again.");
    }
  };



  // const handleChange = (field: keyof typeof userData, value: string) => {
  //   setFormData((prev) => ({
  //     ...prev,
  //     [field]: value,
  //   }));
  // };


  const handleChange = (field: keyof typeof userData, value: string) => {
    setFormData((prev) => {
      const updatedData = { ...prev, [field]: value };

      
      if (field === "locatie") {
        updatedData.gemeente = locationGemeenteMap[value] || "";
      }

      return updatedData;
    });
  };


  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prev) => ({
          ...prev,
          profiel_foto: reader.result as string,
        }));
      };
      reader.readAsDataURL(file);
    }
  };




  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black bg-opacity-50" />
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="max-w-lg w-full bg-white rounded-lg shadow-lg">
          <div className="flex justify-between items-center border-b border-gray-200 px-6 py-4">
            <Dialog.Title className="text-lg font-medium text-gray-900">Edit User</Dialog.Title>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <XMarkIcon className="w-5 h-5" />
            </button>
          </div>
          <div className="px-6 py-4 overflow-y-auto max-h-[600px]">
            <div className="space-y-4">
              <div>
              <div className="flex space-x-4">
                <div className="flex-1">
                  <label htmlFor="voornaam" className="block text-sm font-medium text-gray-700">
                    Voornaam
                  </label>
                  <input
                    id="voornaam"
                    type="text"
                    value={formData.voornaam}
                    onChange={(e) => handleChange('voornaam', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="achternaam" className="block text-sm font-medium text-gray-700">
                    Achternaam
                  </label>
                  <input
                    id="achternaam"
                    type="text"
                    value={formData.achternaam}
                    onChange={(e) => handleChange('achternaam', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              
              <div>
                <label htmlFor="nationaliteit" className="block text-sm font-medium text-gray-700">
                  Nationaliteit
                </label>
                <select
                  id="nationaliteit"
                  value={formData.nationaliteit}
                  onChange={(e) => handleChange('nationaliteit', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Selecteer een optie</option>
                  <option value="Nederlands">Nederlands</option>
                  <option value="Belgisch">Belgisch</option>
                  <option value="Duits">Duits</option>
                  <option value="Frans">Frans</option>
                </select>
              </div>
              <div>
                <label htmlFor="telefoon_nummer" className="block text-sm font-medium text-gray-700">
                  Telefoon
                </label>
                <input
                  id="telefoon"
                  type="tel"
                  value={formData.telefoon_nummer}
                  onChange={(e) => handleChange('telefoon_nummer', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="profiel_foto" className="block text-sm font-medium text-gray-700">
                  Profiel Foto
                </label>
                <div className="mt-1 flex items-center">
                  {formData.profiel_foto ? (
                    <img
                      src={formData.profiel_foto}
                      alt="Profile"
                      className="w-16 h-16 rounded-full object-cover mr-4"
                    />
                  ) : (
                    <div className="w-16 h-16 rounded-full bg-gray-200 mr-4" />
                  )}
                  <input
                    id="profiel_foto"
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    className="block text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="traject" className="block text-sm font-medium text-gray-700">
                  Traject
                </label>
                <select
                  id="traject"
                  value={formData.traject}
                  onChange={(e) => handleChange('traject', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Selecteer een optie</option>
                  <option value="WEP">WEP</option>
                  <option value="Banenafspraak">Banenafspraak</option>
                  <option value="WOT">WOT</option>
                  <option value="WerkFitDH">WerkFit Den Haag</option>
                </select>
              </div>
              <div>
                <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                  Locatie
                </label>
                <select
                  id="location"
                  value={formData.locatie}
                  onChange={(e) => handleChange('locatie', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Selecteer een optie</option>
                  <option value="Martinifabriek">De Martinifabriek</option>
                  <option value="FietsenwachtRijswijk">Fietsenwacht Bogaardplein Rijswijk</option>
                  <option value="CleanCircle">Clean Circe</option>
                  <option value="BouwTechniek">Bouw en Techniek</option>
                </select>
              </div>
              <div>
                <label htmlFor="gemeente" className="block text-sm font-medium text-gray-700">
                  Gemeente
                </label>
                <input
                  id="gemeente"
                  type="text"
                  value={formData.gemeente}
                  readOnly
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm bg-gray-100 cursor-not-allowed sm:text-sm"
                />
              </div>
              <div>
                <label htmlFor="straatnaam" className="block text-sm font-medium text-gray-700">
                  Straatnaam
                </label>
                <input
                  id="straatnaam"
                  type="text"
                  value={formData.straat}
                  onChange={(e) => handleChange('straat', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
              </div>

              <div className="flex space-x-4">
                <div className="flex-1">
                  <label htmlFor="postcode" className="block text-sm font-medium text-gray-700">
                    Postcode
                  </label>
                  <input
                    id="postcode"
                    type="text"
                    value={formData.postcode}
                    onChange={(e) => handleChange('postcode', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="nummer" className="block text-sm font-medium text-gray-700">
                    Huisnummer
                  </label>
                  <input
                    id="huisnummer"
                    type="text"
                    value={formData.huisnummer}
                    onChange={(e) => handleChange('huisnummer', e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="casemanager" className="block text-sm font-medium text-gray-700">
                  Casemanager
                </label>
                <select
                  id="casemanager"
                  value={formData.casemanager}
                  onChange={(e) => handleChange('casemanager', e.target.value)}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                >
                  <option value="">Selecteer een optie</option>
                  <option value="Brenda">Brenda</option>
                  <option value="Mo">Mo</option>
                  <option value="Saida">Saida</option>
                  <option value="Hennie">Hennie</option>
                </select>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-3 border-t border-gray-200 px-6 py-4">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="px-4 py-2 text-sm text-white bg-indigo-600 rounded-lg hover:bg-indigo-500"
            >
              Save
            </button>
          </div>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
