import { Switch } from "@headlessui/react"
import { PDFDownloadLink } from "@react-pdf/renderer"
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import { MultipleCasemanagerSelection } from "../../../../components/dropdown/casemanager/App"
import ConsulentSelection, { ConsulentSelectionV2 } from "../../../../components/dropdown/consulent/App"
import AanmeldPDFFile from "../../../../components/pdf/AanmeldPDF"
import AanvragenPDFFile from "../../../../components/pdf/AanvragenPDF"
import OntwikkeldoelenPDFFile from "../../../../components/pdf/OntwikkeldoelenPDF"
import RapportagePDFFile from "../../../../components/pdf/RapportagePDF"
import { TrajectModel } from "../../../../domain/traject-utils"
import { UserModel } from "../../../../domain/user-utils"
import { CasemanagerModel } from "../../../../domain/casemanager-utils"
import { useRef } from "react"
import Select from 'react-select-virtualized';
import gemeentes from '../../../../../src/datalists/gemeentes.json';

export default function TrajectForm({traject, consulenten, casemanagers, submitHandler}: {traject: TrajectModel, consulenten: UserModel[], casemanagers: CasemanagerModel[], submitHandler}) {

    type Inputs = {
        trajectId?: string,
        naam: string,
        traject_duur: number,
        traject_consulent_id: string,
        gemeente: string,
        casemanager_ids: string[],
        aantal_rapportages: number,
        jobcoach_interventies: number,
        evaluatie_momenten: number,
        praktijkverklaring: boolean,
        talentportfolio: boolean,
        loonwaardemeting: boolean
    }

    const {
            register,
            handleSubmit,
            formState: { errors },
            control
        } = useForm<Inputs>({
            defaultValues: {
                trajectId: traject.reference?.id,
                naam: traject.naam ?? undefined,
                aantal_rapportages: traject.aantal_rapportages,
                casemanager_ids: (traject.casemanager_references) ? traject.casemanager_references!.map((reference) => reference.id) : [],
                gemeente: traject.gemeente,
                traject_consulent_id: traject.traject_consulent_reference?.id,
                evaluatie_momenten: traject.evaluatie_momenten,
                loonwaardemeting: traject.loonwaardemeting ?? false,
                praktijkverklaring: traject.praktijkverklaring ?? false,
                talentportfolio: traject.talentportfolio ?? false,
                traject_duur: traject.duur,
                jobcoach_interventies: traject.jobcoach_interventies
              }
        })
        const onSubmit: SubmitHandler<Inputs> = (data) => {
            submitHandler(data)
        }

return (
    <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-sky-600 text-sm leading-6 bg-white shadow-lg sm:rounded-lg py-1 sm:px-12 pb-6 mb-12 ">
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Traject naam</dt>
                <dd className="mt-1 w- flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div >
                        <input
                            {...register("naam")}
                            type="text"
                            placeholder="WOT (WerkontwikkelTraject)"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        />

                    </div >
                </dd>
            </div>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Traject duur</dt>
                <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">Contractuele duur van een traject, minimaal 1 maand maximaal 12 maanden (hierna volgt een eventuele verlenging)</dt>
                <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="mt-2 flex rounded-md shadow-sm">

                        <input
                            {...register("traject_duur", {valueAsNumber: true})}
                            type="number"
                            placeholder="6"
                            className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-r-0 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        />
                        <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                            maanden
                        </span>
                    </div>
                </dd>
            </div>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Traject Consulent</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <Controller
                        {...register("traject_consulent_id")}
                        control={control}
                        rules={{ required: { value: true, message: "Consulent is verplicht" } }}
                        render={({ field, fieldState: { error } }) => <ConsulentSelectionV2 {...field} consulenten={consulenten} consulent={consulenten.find((consulent) => consulent.ref.id == traject.traject_consulent_reference?.id)}/>}
                    />
                </dd>
            </div>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Casemanagers</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                <Controller
                                    {...register("casemanager_ids")}
                                    control={control}
                                    rules={{ required: { value: true, message: "Casemanagers is verplicht" } }}
                                    render={({ field, fieldState: { error } }) => <MultipleCasemanagerSelection {...field} casemanagers={casemanagers} selectedCasemanagers={casemanagers.filter((casemanager) => {return traject.casemanager_references?.map((reference) => reference.id).includes(casemanager.reference.id)})} />}
                                />
                </dd>
            </div>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Gemeente</dt>
                <dd className="mt-1 w- flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div >
                        <Controller
                        {...register("gemeente")} 
                        control={control}
                        rules={{ required: { value: true, message: "Gemeente is verplicht" } }}
                        render={({ field: {onChange}, fieldState: { error } }) => <Select onChange={(e) => onChange(e?.value)} options={gemeentes.gemeentes.map((pair) => { return {'value': pair.value, 'label': pair.name}})} defaultValue={{'value': traject.gemeente?.toLowerCase(), 'label': traject.gemeente}} />}
                        />
                    </div >
                </dd>
            </div>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Aantal Rapportages</dt>
                <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">Het aantal momenten dat er een document gegenereerd moet worden binnen de duur van een traject</dt>
                <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="mt-2 flex rounded-md shadow-sm">

                        <input
                            {...register("aantal_rapportages", {valueAsNumber: true})}
                            type="number"
                            placeholder="4"
                            className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-r-0 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        />
                        <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                            stuks
                        </span>
                    </div>
                </dd>
            </div>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Aantal jobcoach interventies</dt>
                <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">Het aantal momenten dat er een document gegenereerd moet worden binnen de duur van een traject</dt>
                <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="mt-2 flex rounded-md shadow-sm">

                        <input
                            {...register("jobcoach_interventies", {valueAsNumber: true})}
                            type="number"
                            placeholder="4"
                            className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-r-0 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        />
                        <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                            stuks
                        </span>
                    </div>
                </dd>
            </div>
            {/* <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
                    Rapportage Document
                </dt>
                <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className=" min-w-0 flex-1 ">
                        <select
                            className="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            onChange={handleSelectionChange}
                            value={selectedOption}
                        >
                            <option value="">Select a document</option>
                            <option value="default">Rapportage Default</option>
                            <option value="denHaag">Rapportage Den Haag</option>
                        </select>
                    </div>

                    {selectedOption === 'denHaag' && (
                        <PDFDownloadLink document={<RapportagePDFFile content={null} onderwerp={undefined} datum={undefined} />} fileName={"Rapportage"}>
                            <button type="button" className="ml-4 px-1 py-2 bg-blue-600 text-white rounded-md sm:w-16 w-12 md:w-32 w-24 lg:w-48 w-28">
                                Preview Rapportage Den Haag
                            </button>
                        </PDFDownloadLink>
                    )}
                    {selectedOption === 'default' && (
                        <button type="button" className="ml-4 px-4 py-2 bg-gray-600 text-white rounded-md sm:w-16 w-12 md:w-32 w-24 lg:w-48 w-28">
                            Default niet beschikbaar
                        </button>
                    )}
                </dd>
            </div> */}


            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Evaluatie momenten</dt>
                <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">Momenten om gesprekken te plannen gedurende het traject</dt>
                <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="mt-2 flex rounded-md shadow-sm">

                        <input
                            {...register("evaluatie_momenten", {valueAsNumber: true})}
                            type="number"
                            placeholder="2"
                            className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-r-0 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                        />
                        <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-gray-500 sm:text-sm">
                            stuks
                        </span>
                    </div>
                </dd>
            </div>
            {/* <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Ontwikkeldoelen Document</dt>
                <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className=" min-w-0 flex-1 ">
                        <select
                            className="rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                            onChange={handleSelectionChangeO}
                            value={selectedOptionO}
                        >
                            <option value="">Select a document</option>
                            <option value="default">Ontwikkeldoelen Default</option>
                            <option value="denHaagO">Ontwikkeldoelen Den Haag</option>
                        </select>
                    </div>

                    {selectedOptionO === 'denHaagO' && (
                        <PDFDownloadLink document={<OntwikkeldoelenPDFFile content={null} traject={null} />} fileName={"Ontwikkeldoelen"}>
                            <button type="button" className="ml-4 px-1 py-2 bg-blue-600 text-white rounded-md sm:w-16 w-12 md:w-32 w-24 lg:w-48 w-28">
                                Preview Ontwikkeldoelen Den Haag
                            </button>
                        </PDFDownloadLink>
                    )}
                    {selectedOptionO === 'default' && (
                        <button type="button" className="ml-4 px-4 py-2 bg-gray-600 text-white rounded-md sm:w-16 w-12 md:w-32 w-24 lg:w-48 w-28">
                            Default niet beschikbaar
                        </button>
                    )}
                </dd>
            </div> */}
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Praktijkverklaring</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">De Praktijkverklaring wordt in het laatste van het termijn gegenereerd</dt>
                    <dd className="flex flex-auto items-center justify-end">
                        <Controller
                            {...register("praktijkverklaring")}
                            control={control}
                            render={({ field: {value, onChange}, fieldState: { error } }) => <Switch
                            checked={Boolean(value)}
                            onChange={onChange}
                            id='trajectpraktijkverklaring'
                            className="group flex w-8 cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                        >
                            <span
                                aria-hidden="true"
                                className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                            />
                        </Switch>}
                        />
                        
                        
                    </dd>
                </dd>
            </div>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Talentportfolio</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">De Praktijkverklaring wordt in het laatste van het termijn gegenereerd</dt>
                    <dd className="flex flex-auto items-center justify-end">
                    <Controller
                            {...register("talentportfolio")}
                            control={control}
                            render={({ field: {value, onChange}, fieldState: { error } }) => <Switch
                            checked={Boolean(value)}
                            onChange={onChange}
                            className="group flex w-8 cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                        >
                            <span
                                aria-hidden="true"
                                className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                            />
                        </Switch>}
                        />
                    </dd>
                </dd>
            </div>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Loonwaardemeting</dt>
                <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6">De Praktijkverklaring wordt in het laatste van het termijn gegenereerd</dt>
                    <dd className="flex flex-auto items-center justify-end">
                    <Controller
                            {...register("loonwaardemeting")}
                            control={control}
                            render={({ field: {value, onChange}, fieldState: { error } }) => <Switch
                            checked={Boolean(value)}
                            onChange={onChange}
                            id='trajectpraktijkverklaring'
                            className="group flex w-8 cursor-pointer rounded-full bg-gray-200 p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 data-[checked]:bg-indigo-600"
                        >
                            <span
                                aria-hidden="true"
                                className="h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out group-data-[checked]:translate-x-3.5"
                            />
                        </Switch>}
                        />
                    </dd>
                </dd>
            </div>
            <input
                {...register("trajectId", {value: traject?.reference?.id})}
                type="hidden"
                placeholder="6"
                className="block w-full min-w-0 flex-1 rounded-none rounded-l-md border-r-0 border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
            />
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Aanvragen Verlenging Document</dt>
                <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6"></dt>
                <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="mt-2 flex rounded-md shadow-sm">
                        <PDFDownloadLink document={<AanvragenPDFFile content={null} traject={null} />} fileName={"Aanvragen"}>
                            <button type="button" className="px-4 py-2 bg-blue-600 text-white rounded-md">
                                Preview Aanvragen Verlenging
                            </button>
                        </PDFDownloadLink>
                    </div>
                </dd>
            </div>
            <div className="pt-6 sm:flex">
                <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Aanmeld Document</dt>
                <dt className="font text-gray-600 sm:w-64 sm:flex-none sm:pr-6"></dt>
                <dd className="mt-1 flex justify-end gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="mt-2 flex rounded-md shadow-sm">
                        <PDFDownloadLink document={<AanmeldPDFFile content={null} aanmelding={null} />} fileName={"Aanmeld"}>
                            <button type="button" className="px-4 py-2 bg-blue-600 text-white rounded-md">
                                Preview Aameld
                            </button>
                        </PDFDownloadLink>
                    </div>
                </dd>
            </div>
            {/* <Button type='submit'>Opslaan</Button> */}
            <div className="mt-12 flex items-center justify-end gap-x-6">
                <button type="button" className="text-sm/6 font-semibold text-gray-900">
                    Annuleren
                </button>
                <button
                    type="submit"
                    className="rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                    Opslaan
                </button>
            </div>
        </form>
    </dl>)
}