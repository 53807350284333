import React from "react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import { EvaluatieModel } from "../../domain/evaluatie-utils";

interface UpdateCardProps {
evaluatie:EvaluatieModel
}

const moodEmojiMap = [
  '😡', // 1
  '😟', // 2
  '😔', // 3
  '😞', // 4
  '😐', // 5
  '🙂', // 6
  '😊', // 7
  '😃', // 8
  '😁', // 9
  '😍', // 10
];

const UpdateCard: React.FC<UpdateCardProps> = ({
evaluatie
}) => {
  return (
    <div className="bg-black bg-opacity-40 backdrop-blur-lg rounded-lg p-4 shadow-lg text-white w-full mb-4">
      {/* Header */}
      <div className="flex justify-between items-center">
        <h3 className="font-semibold text-lg">{evaluatie.talent?.naam}</h3>
        <button className="text-gray-400 hover:text-gray-300">
          <EllipsisHorizontalIcon className="w-5 h-5" />
        </button>
      </div>

      {/* Description */}
      <p className="text-gray-300 text-sm mt-1">{evaluatie.content}</p>

      {/* Footer */}
      <div className="flex justify-between items-center mt-4">
        <span className="text-white text-xs">{evaluatie.aanmaak_datum.toLocaleDateString()}</span>

        {/* Completion Score */}
        <div className="flex items-center space-x-2 bg-gray-800 px-3 py-1 rounded-full">
          <span className="text-lg">{moodEmojiMap[evaluatie.gevoel??0]}</span>
          <span className="text-gray-200 font-semibold">{evaluatie.cijfer}/10</span>
        </div>
      </div>
    </div>
  );
};

export default UpdateCard;