import { DocumentReference, writeBatch } from "firebase/firestore"
import { createLicentienemerDocument, getAllLicentienemerDocuments, getLicentienemerDocumentById, getLicentienemerDocumentByName, RemoteLicentienemer } from "../datasource/licentienemer-utils"
import { getUserById } from "./user-utils"
import { ImportObject } from "../pages/beheer/import/Importer"
import { db } from "../utils/firebase"
import { addUserDocument } from "../datasource/user-utils"
import { createTrajectDocument, createTrajectDocumentWithBatch } from "../datasource/traject-utils"
import { createBedrijfDocument } from "../datasource/bedrijf-utils"
import { addTalentDocument, NewRemoteTalent, updateTalentDocument } from "../datasource/talent-utils"
import { addLopendTrajectDocument } from "../datasource/lopend-traject-utils"
import dayjs from "dayjs"
import { Traject, User, Werkbedrijf } from "../datasource/interfaces/collection"
import { UserModel } from "./user-utils"

export type LicentienemerModel = {
    id?: null | undefined,
    reference: DocumentReference,
    naam: string,
    url_naam: string,
    logo: string,
    titel1: string,
    beschrijving1: string,
    titel2: string,
    beschrijving2: string,
    feature1: string,
    feature_description1: string,
    feature2: string,
    feature_description2: string,
    feature3: string,
    feature_description3: string,
    straat: string,
    nummer: string,
    postcode: string,
    telefoonnummer: string,
    email: string,
    plaats: string,
    contact_persoon?: {
        naam: string
    },
    contact_persoon_id?: string
}

export type NewLicentienemerModel = {
    naam: string,
    logo: string,
    titel1: string,
    beschrijving1: string,
    titel2: string,
    beschrijving2: string,
    feature1: string,
    feature_description1: string,
    feature2: string,
    feature_description2: string,
    feature3: string,
    feature_description3: string,
    straat: string,
    nummer: string,
    postcode: string,
    telefoonnummer: string,
    email: string,
    plaats: string,
    url_naam?: string,
    // contact_persoon?: {naam: string, email: string}
}

function convertRemoteToModel(remote: RemoteLicentienemer) {
    return {
        reference: remote.reference,
        naam: remote.naam,
        url_naam: remote.url_naam,
        logo: remote.logo,
        titel1: remote.titel1,
        beschrijving1: remote.beschrijving1,
        titel2: remote.titel2,
        beschrijving2: remote.beschrijving2,
        feature1: remote.feature1,
        feature_description1: remote.feature_description1,
        feature2: remote.feature2,
        feature_description2: remote.feature_description2,
        feature3: remote.feature3,
        feature_description3: remote.feature_description3,
        straat: remote.straat,
        nummer: remote.nummer,
        postcode: remote.postcode,
        telefoonnummer: remote.telefoonnummer,
        email: remote.email,
        plaats: remote.plaats
    } as LicentienemerModel
}

export const getLicentienemers = async (user: UserModel) => {
    const list = await getAllLicentienemerDocuments();
    const licentienemers: LicentienemerModel[] = [];

    for (let licentienemer of list) { 
        licentienemers.push({
            reference: licentienemer.reference,
            naam: licentienemer.naam,
            url_naam: licentienemer.url_naam,
            logo: licentienemer.logo,
            titel1: licentienemer.titel1,
            beschrijving1: licentienemer.beschrijving1,
            titel2: licentienemer.titel2,
            beschrijving2: licentienemer.beschrijving2,
            feature1: licentienemer.feature1,
            feature_description1: licentienemer.feature_description1,
            feature2: licentienemer.feature2,
            feature_description2: licentienemer.feature_description2,
            feature3: licentienemer.feature3,
            feature_description3: licentienemer.feature_description3,
            straat: licentienemer.straat,
            nummer: licentienemer.nummer,
            postcode: licentienemer.postcode,
            telefoonnummer: licentienemer.telefoonnummer,
            email: licentienemer.email,
            plaats: licentienemer.plaats
        });

        // if (options?.contact_persoon) {
        //     let contactPersoon = await getUserById(licentienemer.contact_persoon)
        //     model.contact_persoon = {
        //         naam: `${contactPersoon?.voornaam} ${contactPersoon?.achternaam}`
        //     }
        // }
    }

    return  licentienemers;
}


//Ander naam geven
export const getAllLicentienemers = async (options?: { contact_persoon: true }) => {

    const list: LicentienemerModel[] = []
    const licentienemers = await getAllLicentienemerDocuments()

    for (const licentienemer of licentienemers) {
        let model = convertRemoteToModel(licentienemer)

        if (options?.contact_persoon) {
            let contactPersoon = await getUserById(licentienemer.contact_persoon)
            model.contact_persoon = {
                naam: `${contactPersoon?.voornaam} ${contactPersoon?.achternaam}`
            }
        }

        list.push(model)
    }

    return list
}

export const getLicentienemerById = async (id?: string) => {

    if (!id) return Promise.reject("Fout.")

    const licentienemer = await getLicentienemerDocumentById(id)

    if (!licentienemer) return Promise.reject("Geen licentienemer gevonden.")

    return convertRemoteToModel(licentienemer)

}

export const getLicentienemerByName = async (name?: string) => {
    if (!name) return Promise.reject("Fout.");

    // console.log("🟢 Opgezocht naam:", name);

    const allLicentienemers = await getAllLicentienemers();
    // console.log("🔍 Alle namen in DB:", allLicentienemers.map(l => `[${l.naam}]`));

    const normalizedSearchName = name.toLowerCase().trim();
    
    const licentienemer = allLicentienemers.find(
        l => l.naam.toLowerCase().trim() === normalizedSearchName
    );

    if (!licentienemer) {
        console.error("🔴 Geen licentienemer gevonden voor:", name);
        return Promise.reject("Geen licentienemer gevonden.");
    }

    // console.log("🟢 Gevonden licentienemer:", licentienemer.naam);
    return licentienemer;
};




const DEFAULT_LOGO = "https://storage.googleapis.com/glide-prod.appspot.com/uploads-v2/hOclsK0v6r1MxJZCAkio/pub/IEkBbvHC60erLuhE68MC.png";

export const fetchLogos = async (Naam: string) => {
    
    // console.log("Logo ophalen voor:", Naam);
    const allLicentienemers = await getAllLicentienemers();
    // console.log("Alle licentienemers:", allLicentienemers.map(l => l.naam.toLowerCase()));

    // console.log("Zoeken naar:", Naam.toLowerCase());




    try {
        const licentienemer = await getLicentienemerByName(Naam);
        if (licentienemer) {
            //  console.log("Licentienemer gevonden:", licentienemer);
            return licentienemer.logo || DEFAULT_LOGO;
        } else {
            console.log("Licentienemer niet gevonden, standaard logo gebruiken.");
            return DEFAULT_LOGO;
        }
    } catch (error) {
        console.error("Fout bij ophalen licentienemer:", error);
        return DEFAULT_LOGO;
    }
    
};






export const createNewLicentienemerWithPredefinedData = async (importObject: ImportObject) => {
    if (!importObject.consulenten || !importObject.licentienemer || !importObject.trajecten || !importObject.talenten ||
        !importObject.werkbedrijven || !importObject.werkvloerbegeleiders
    ) return Promise.reject("Fatal error")

    const batch = writeBatch(db)

    const licentienemerRef = createLicentienemerDocument({
        beschrijving1: importObject.licentienemer.beschrijving1,
        beschrijving2: importObject.licentienemer.beschrijving2,
        titel1: importObject.licentienemer.titel1,
        titel2: importObject.licentienemer.titel2,
        email: importObject.licentienemer.email,
        feature1: importObject.licentienemer.feature1,
        feature2: importObject.licentienemer.feature2,
        feature3: importObject.licentienemer.feature3,
        feature_description1: importObject.licentienemer.feature_description1,
        feature_description2: importObject.licentienemer.feature_description2,
        feature_description3: importObject.licentienemer.feature_description3,
        logo: importObject.licentienemer.logo,
        naam: importObject.licentienemer.naam,
        nummer: importObject.licentienemer.nummer,
        plaats: importObject.licentienemer.plaats,
        postcode: importObject.licentienemer.postcode,
        straat: importObject.licentienemer.straat,
        telefoonnummer: importObject.licentienemer.telefoonnummer,
    }, batch)

    if (!licentienemerRef) return Promise.reject("Error")

    const licentienemerUser = addUserDocument({
        achternaam: importObject.licentienemer.contact_persoon.achternaam,
        email: importObject.licentienemer.email,
        gebruikers_rol: "licentienemer",
        geslacht: importObject.licentienemer.contact_persoon.geslacht,
        telefoon_nummer: importObject.licentienemer.contact_persoon.telefoon_nummer,
        voornaam: importObject.licentienemer.contact_persoon.voornaam,
        profiel_foto: importObject.licentienemer.contact_persoon.profiel_foto,
        data_eigenaar_ref: licentienemerRef
    }, batch)

    batch.update(licentienemerRef, { contact_persoon: licentienemerUser })

    const consulentenRefs: Map<string, DocumentReference> = new Map()
    const consulentenExploded: Map<string, User> = new Map()

    for (let consulent of importObject.consulenten) {
        let _consulent = addUserDocument({
            achternaam: consulent.achternaam,
            email: consulent.email,
            gebruikers_rol: "consulent",
            geslacht: consulent.geslacht,
            telefoon_nummer: consulent.telefoon_nummer,
            voornaam: consulent.voornaam,
            profiel_foto: consulent.profiel_foto,
            data_eigenaar_ref: licentienemerRef
        }, batch)
        consulentenRefs.set(consulent.email.toLowerCase(), _consulent)
        consulentenExploded.set(consulent.email.toLowerCase(), {
            reference: _consulent.id,
            voornaam: consulent.voornaam,
            achternaam: consulent.achternaam,
            profiel_foto: consulent.profiel_foto,
            email: consulent.email
        })
    }

    const werkbegeleidersRefs: Map<string, DocumentReference> = new Map()
    const werkbegeleidersExploded: Map<string, User> = new Map()

    for (let werkbegeleider of importObject.werkvloerbegeleiders) {
        let _werkbegeleider = addUserDocument({
            achternaam: werkbegeleider.achternaam,
            email: werkbegeleider.email,
            gebruikers_rol: "werkvloerbegeleider",
            geslacht: werkbegeleider.geslacht,
            telefoon_nummer: werkbegeleider.telefoon_nummer,
            voornaam: werkbegeleider.voornaam,
            profiel_foto: werkbegeleider.profiel_foto,
            data_eigenaar_ref: licentienemerRef
        }, batch)
        werkbegeleidersRefs.set(werkbegeleider.email.toLowerCase(), _werkbegeleider)
        werkbegeleidersExploded.set(werkbegeleider.email.toLowerCase(), {
            reference: _werkbegeleider.id,
            voornaam: werkbegeleider.voornaam,
            achternaam: werkbegeleider.achternaam,
            profiel_foto: werkbegeleider.profiel_foto,
            email: werkbegeleider.email
        })
    }

    const trajectenRefs: Map<string, { reference: DocumentReference, consulent_reference: DocumentReference }> = new Map()
    const trajectenExploded: Map<string, Traject> = new Map()

    for (let traject of importObject.trajecten) {
        trajectenRefs.set(traject.naam!.toLowerCase(), {
            reference: createTrajectDocumentWithBatch({
                aantal_rapportages: traject.aantal_rapportages,
                consulent_reference: consulentenRefs.get(traject.consulent.email.toLowerCase()),
                duur: traject.duur,
                evaluatie_momenten: traject.evaluatie_momenten,
                gemeente: traject.gemeente,
                jobcoach_interventies: traject.jobcoach_interventies,
                loonwaardemeting: traject.loonwaardemeting,
                naam: traject.naam,
                omschrijving: traject.omschrijving,
                praktijkverklaring: traject.praktijkverklaring,
                talentportfolio: traject.talentportfolio,
                data_eigenaar_ref: licentienemerRef
            }, batch), consulent_reference: consulentenRefs.get(traject.consulent.email.toLowerCase())!
        })
        trajectenExploded.set(traject.naam!.toLowerCase(), {
            duur: traject.duur,
            evaluatie_momenten: traject.evaluatie_momenten,
            gemeente: traject.gemeente,
            jobcoach_interventies: traject.jobcoach_interventies,
            loonwaardemeting: traject.loonwaardemeting,
            naam: traject.naam,
            omschrijving: traject.omschrijving,
            praktijkverklaring: traject.praktijkverklaring,
            reference: trajectenRefs.get(traject.naam.toLowerCase())!.reference.id,
            talentportfolio: traject.talentportfolio,
            aantal_rapportages: traject.aantal_rapportages
        })
    }

    const bedrijvenRefs: Map<string, { reference: DocumentReference, werkbedrijf_reference: DocumentReference }> = new Map()
    const bedrijvenExploded: Map<string, Werkbedrijf> = new Map()
    const begeleiderByWerkbedrijfExploded: Map<string, User> = new Map()

    for (let bedrijf of importObject.werkbedrijven) {
        let _bedrijf = createBedrijfDocument({
            locatie: bedrijf.locatie,
            logo: bedrijf.logo,
            naam: bedrijf.naam,
            nummer: bedrijf.nummer,
            plaats: bedrijf.plaats,
            postcode: bedrijf.postcode,
            straat: bedrijf.straat,
            begeleider: werkbegeleidersRefs.get(bedrijf.werkvloerbegeleider.email.toLowerCase()),
            data_eigenaar_ref: licentienemerRef
        }, batch)
        bedrijvenRefs.set(bedrijf.naam!.toLowerCase(), { reference: _bedrijf, werkbedrijf_reference: werkbegeleidersRefs.get(bedrijf.werkvloerbegeleider.email.toLowerCase())! })
        bedrijvenExploded.set(bedrijf.naam!.toLowerCase(), {
            reference: _bedrijf.id,
            naam: bedrijf.naam
        })
        begeleiderByWerkbedrijfExploded.set(bedrijf.naam!.toLowerCase(), werkbegeleidersExploded.get(bedrijf.werkvloerbegeleider.email.toLowerCase())!)
    }

    for (let talent of importObject.talenten) {

        const talentObj: NewRemoteTalent = {
            achternaam: `${talent?.achternaam}`,
            actief: true,
            email: `${talent?.email}`,
            gebruikers_rol: "talent",
            geslacht: talent.geslacht,
            telefoon_nummer: talent.telefoon_nummer,
            voornaam: `${talent?.voornaam}`,
            talent: {
                geboorte_datum: talent.talent!.geboorte_datum,
                gemeente: talent.talent!.gemeente,
                huisnummer: talent.talent!.huisnummer,
                nationaliteit: talent.talent!.nationaliteit,
                plaats: talent.talent!.plaats,
                postcode: talent.talent!.postcode,
                straat: talent.talent!.straat,
                traject_ref: trajectenRefs.get(talent.traject!.naam.toLowerCase())!.reference,
                traject: trajectenExploded.get(talent.traject!.naam.toLowerCase())!,
                uitstroom: false
            },
            data_eigenaar_ref: licentienemerRef
        }

        if (talent.profiel_foto) {
            talentObj.profiel_foto = talent.profiel_foto
        }

        const talentReference = addTalentDocument(talentObj, batch)

        const lopendTrajectRef = addLopendTrajectDocument({
            begindatum: dayjs(`${talent.lopend_traject?.start_datum ?? 0}`).toDate(),
            status: talent.lopend_traject!.status!,
            werkdagen: talent.lopend_traject!.werkdagen!,
            talent_ref: talentReference,
            consulent: consulentenExploded.get(talent.traject!.naam.toLowerCase())!,
            talent: {
                reference: talentReference.id,
                voornaam: `${talent?.voornaam}`,
                achternaam: `${talent?.achternaam}`,
                profiel_foto: null,
                email: `${talent?.email}`
            },
            traject: trajectenExploded.get(talent.traject!.naam.toLowerCase())!,
            werkbedrijf: bedrijvenExploded.get(talent.bedrijf!.naam.toLowerCase())!,
            werkvloerbegeleider: begeleiderByWerkbedrijfExploded.get(talent.bedrijf!.naam.toLowerCase())!,
            traject_ref: trajectenRefs.get(talent.traject!.naam.toLowerCase())!.reference,
            consulent_ref: trajectenRefs.get(talent.traject!.naam.toLowerCase())!.consulent_reference,
            werkbedrijf_ref: bedrijvenRefs.get(talent.bedrijf!.naam.toLowerCase())!.reference,
            werkvloer_begeleider_ref: bedrijvenRefs.get(talent.bedrijf!.naam.toLowerCase())!.werkbedrijf_reference,
            data_eigenaar_ref: licentienemerRef
        }, batch)

        batch.update(talentReference, { "talent.lopendtraject_ref": lopendTrajectRef })
        batch.update(talentReference, {
            "talent.lopendtraject": {
                status: talent.lopend_traject!.status!,
                begindatum: dayjs(`${talent.lopend_traject?.start_datum ?? 0}`).toDate(),
                reference: lopendTrajectRef.id
            }
        })

    }

    return batch.commit()

}