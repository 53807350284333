import Layout from '../../../layout'
import { useEffect, useRef, useState } from 'react'
import { getAllConsulenten, UserModel } from '../../../domain/user-utils'
import { getAllTrajecten, TrajectModel } from '../../../domain/traject-utils'
import ConsulentSelection, { ConsulentSelectionV2 } from '../../../components/dropdown/consulent/App'
import TrajectSelection, { TrajectSelectionV2 } from '../../../components/dropdown/traject/App'
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { TrajectContext } from '../../../components/context/TrajectContext'
import { createAanmelding } from '../../../domain/aanmelding-utils'
import { POSTCODE_REGEX } from '../../../utils/regex'
import { useAuth } from '../../../context/AuthContext'
import Succes from '../../../components/modal/succes/Succes'
import { getLicentienemerByReference, LicentienemerModel } from '../../../domain/bedrijf-utils'

import afkomst from "../../../datalists/afkomst.json"
import nationaliteiten from "../../../datalists/nationaliteiten.json"
import { useNavigate } from 'react-router-dom'
import getWerkvloerbegeleiders, { WerkvloerbegeleiderModel } from '../../../domain/werkvloerbegeleider-utils'
import { WerkvloerbegeleiderSelectionV2 } from '../../../components/dropdown/werkvloerbegeleider/App'
import { CasemanagerSelectionV2 } from '../../../components/dropdown/casemanager/App'
import getCasemanagers, { CasemanagerModel } from '../../../domain/casemanager-utils'

export default function Example() {

    const { user } = useAuth()

    const [trajecten, setTrajecten] = useState<TrajectModel[]>([])
    const [werkvloerbegeleiders, setWerkvloerbegeleiders] = useState<WerkvloerbegeleiderModel[]>([])
    const [casemanagers, setCasemanagers] = useState<CasemanagerModel[]>([])
    const [bedrijf, setBedrijf] = useState<LicentienemerModel>()
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate(); // Initialize navigate hook


    useEffect(() => {
        Promise.all([getLicentienemerByReference(user!.data_eigenaar_ref!), getWerkvloerbegeleiders(user!), getAllTrajecten(user!)]).then(([bedrijf, werkvloerbegeleiders, trajecten]) => {
            setBedrijf(bedrijf)
            setTrajecten(trajecten)
            setWerkvloerbegeleiders(werkvloerbegeleiders)
        })
    }, [])


    useEffect(() => {
        Promise.all([getLicentienemerByReference(user!.data_eigenaar_ref!), getCasemanagers(user!), getAllTrajecten(user!)]).then(([casemanager]) => {
            setCasemanagers(casemanagers)
        })
    }, [])

    type Inputs = {
        voornaam: string,
        achternaam: string,
        dob: Date,
        gender: string,
        email: string,
        telefoon: string,
        nationaliteit: string,
        afkomst: string,
        straat: string,
        huisnummer: string,
        postcode: string,
        woonplaats: string,
        trajectId: string,
        gemeente: string,
        casemanagerId: string,
        werkvloerbegeleiderId: string,
        korteSituatieSchets: string,
        doelstellingen: string,
        bijzonderheden: string
    }

    const {
        register,
        handleSubmit,
        control,
        formState: { errors },
        watch
    } = useForm<Inputs>()
    const onSubmit: SubmitHandler<Inputs> = (data) => {
        setIsLoading(true);
        createAanmelding(
            {
                voornaam: data.voornaam,
                achternaam: data.achternaam,
                geboortedatum: data.dob,
                geslacht: data.gender,
                email: data.email,
                telefoon: data.telefoon,
                nationaliteit: data.nationaliteit,
                afkomst: data.afkomst,
                straat: data.straat,
                huisnummer: data.huisnummer,
                postcode: data.postcode,
                woonplaats: data.woonplaats,
                gemeente: "",
                situatie_schets: data.korteSituatieSchets,
                doelstellingen: data.doelstellingen,
                bijzonderheden: data.bijzonderheden,
                trajectId: data.trajectId,
                casemanagerId: data.casemanagerId,
                werkvloerbegeleiderId: data.werkvloerbegeleiderId,
            },
            user!
        )
            .then((response) => {
                setIsLoading(false);
                navigate("/talentenhub/aanmeldingen"); // Redirect after submission
            })
            .catch((e) => {
                console.error(e);
                setIsLoading(false);
            });
    };

    const selectedTraject = watch("trajectId");
    // console.log("Selected Traject:", selectedTraject);
    // console.log(
    //     "Found Casemanagers:",
    //     trajecten.find((traject) => traject.reference!.id == selectedTraject)?.casemanagers ?? "None"
    // );


    return (
        <Layout>
            {isLoading && <Succes />}
            <form className='mx-auto max-w-7xl bg-orange-50' onSubmit={handleSubmit(onSubmit)}>
                <div className="space-y-12 mx-auto max-w-3xl pt-12 rounded-md">
                    <div className="border-b border-gray-900/10 ">
                        <h2 className="text-base/7 font-semibold text-gray-900">Trajectgegevens</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">Hoe gaan we het Talent aansturen</p>

                        <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label className="block text-sm/6 font-medium text-gray-900">
                                    Soort traject
                                </label>
                                <Controller
                                    name='trajectId'
                                    control={control}
                                    rules={{ required: { value: true, message: "Traject is verplicht" } }}
                                    render={({ field, fieldState: { error } }) => <><TrajectSelectionV2 {...field} trajecten={trajecten} /><span className='text-red-400 text-xs'>{error?.message}</span></>}
                                />
                            </div>

                            <div className="sm:col-span-3">
                                <label className="block text-sm/6 font-medium text-gray-900">
                                    Werkvloerbegeleider
                                </label>
                                <Controller
                                    name='werkvloerbegeleiderId'
                                    control={control}
                                    rules={{ required: "werkvloerbegeleider is verplicht" }}
                                    render={({ field, fieldState: { error } }) => <><WerkvloerbegeleiderSelectionV2 {...field} werkvloerbegeleiders={werkvloerbegeleiders} /><span className='text-red-400 text-xs'>{error?.message}</span></>}
                                />
                            </div>
                            <div className="sm:col-span-3">
                                <label className="block text-sm/6 font-medium text-gray-900">
                                    Casemanager
                                </label>
                                {selectedTraject &&
                                    <Controller
                                        name='casemanagerId'
                                        control={control}
                                        rules={{ required: "Casemanager is verplicht" }}
                                        render={({ field, fieldState: { error } }) => <><CasemanagerSelectionV2 {...field} trajectId={selectedTraject} casemanagers={trajecten.find((traject) => traject.reference!.id == selectedTraject)?.casemanagers ?? []} /><span className='text-red-400 text-xs'>{error?.message}</span></>}
                                    />}
                            </div>

                        </div>
                    </div>

                    <div className="border-b border-gray-900/10 ">
                        <h2 className="text-base/7 font-semibold text-gray-900">Talentgegevens</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">De basisgegevens van het Talent</p>

                        <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label className="block text-sm/6 font-medium text-gray-900">
                                    Voornaam
                                </label>
                                <div className="mt-2">
                                    <input
                                        {...register("voornaam", { required: { value: true, message: "Voornaam is verplicht" } })}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                </div>
                                {errors["voornaam"] && <span className='text-red-400 text-xs'>{errors["voornaam"]?.message}</span>}
                            </div>

                            <div className="sm:col-span-3">
                                <label className="block text-sm/6 font-medium text-gray-900">
                                    Achternaam
                                </label>
                                <div className="mt-2">
                                    <input
                                        {...register("achternaam", { required: { value: true, message: "Achternaam is verplicht" } })}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                </div>
                                {errors["achternaam"] && <span className='text-red-400 text-xs'>{errors["achternaam"]?.message}</span>}
                            </div>

                            <div className="sm:col-span-3">
                                <label className="block text-sm/6 font-medium text-gray-900 ">
                                    Geboortedatum
                                </label>
                                <input {...register("dob", { required: { value: true, message: "Geboortedatum is verplicht" }, valueAsDate: true })} aria-label="Date" type="date" className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6 mt-2" />
                                {errors["dob"] && <span className='text-red-400 text-xs'>{errors["dob"]?.message}</span>}
                            </div>

                            <div className="sm:col-span-3">
                                <label className="block text-sm/6 font-medium text-gray-900">
                                    Gender
                                </label>
                                <div className="mt-2">
                                    <Controller
                                        name='gender'
                                        control={control}
                                        rules={{ required: { value: true, message: "Gender is verplicht" } }}
                                        render={({ field, fieldState: { error } }) => <><select {...field}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6"
                                        >
                                            <option disabled selected hidden>Selecteer geslacht</option>
                                            <option value="vrouw">Vrouw </option>
                                            <option value="man">Man</option>
                                            <option value="anders">Anders</option>
                                        </select>
                                            <span className='text-red-400 text-xs'>{error?.message}</span></>}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label className="block text-sm/6 font-medium text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        {...register("email", { required: { value: true, message: "Email is verplicht" } })}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {errors["email"] && <span className='text-red-400 text-xs'>{errors["email"]?.message}</span>}
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                                    Telefoon
                                </label>
                                <div className="mt-2">
                                    <input
                                        {...register("telefoon", { required: { value: true, message: "Telefoonnummer is verplicht" } })}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {errors["telefoon"] && <span className='text-red-400 text-xs'>{errors["telefoon"]?.message}</span>}
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900">
                                    Nationaliteit
                                </label>
                                <div className="mt-2">
                                    <Controller
                                        name='nationaliteit'
                                        control={control}
                                        rules={{ required: { value: true, message: "Nationaliteit is verplicht" } }}
                                        render={({ field, fieldState: { error } }) => <><select {...field}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6"
                                        >
                                            <option disabled selected hidden>Selecteer een nationaliteit</option>
                                            {nationaliteiten.nationaliteit.map((val) =>
                                                <option value={val.value}>{val.name}</option>
                                            )}
                                        </select>
                                            <span className='text-red-400 text-xs'>{error?.message}</span></>}
                                    />
                                </div>

                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm/6 font-medium text-gray-900">
                                    Afkomst
                                </label>
                                <div className="mt-2">
                                    <Controller
                                        name='afkomst'
                                        control={control}
                                        rules={{ required: { value: true, message: "Afkomst is verplicht" } }}
                                        render={({ field, fieldState: { error } }) => <><select {...field}
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm/6"
                                        >
                                            <option disabled selected hidden>Selecteer een afkomst</option>
                                            {afkomst.afkomst.map((val) =>
                                                <option value={val.value}>{val.name}</option>
                                            )}
                                        </select>
                                            <span className='text-red-400 text-xs'>{error?.message}</span></>}
                                    />
                                </div>
                            </div>

                            <div className="col-span-full">
                                <label htmlFor="street-address" className="block text-sm/6 font-medium text-gray-900">
                                    Straat
                                </label>
                                <div className="mt-2">
                                    <input
                                        {...register("straat", { required: { value: true, message: "Straat is verplicht" } })}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {errors["straat"] && <span className='text-red-400 text-xs'>{errors["straat"]?.message}</span>}
                                </div>
                            </div>

                            <div className="sm:col-span-2 sm:col-start-1">
                                <label htmlFor="city" className="block text-sm/6 font-medium text-gray-900">
                                    Huisnummer
                                </label>
                                <div className="mt-2">
                                    <input
                                        {...register("huisnummer", { required: { value: true, message: "Huisnummer is verplicht" } })}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {errors["huisnummer"] && <span className='text-red-400 text-xs'>{errors["huisnummer"]?.message}</span>}
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label className="block text-sm/6 font-medium text-gray-900">
                                    Postcode
                                </label>
                                <div className="mt-2">
                                    <input
                                        {...register("postcode", { required: { value: true, message: "Postcode is verplicht" }, pattern: { value: RegExp(POSTCODE_REGEX), message: "De postcode is niet juist (voorbeeld: 2288EL)" } })}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {errors["postcode"] && <span className='text-red-400 text-xs'>{errors["postcode"]?.message}</span>}
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <label htmlFor="postal-code" className="block text-sm/6 font-medium text-gray-900">
                                    Woonplaats
                                </label>
                                <div className="mt-2">
                                    <input
                                        {...register("woonplaats", { required: { value: true, message: "Woonplaats is verplicht" } })}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {errors["woonplaats"] && <span className='text-red-400 text-xs'>{errors["woonplaats"]?.message}</span>}
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base/7 font-semibold text-gray-900">Verwachting</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">Hoe gaan we het Talent aansturen</p>

                        <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-1 sm:grid-cols-6">
                            <div className="sm:col-span-full">
                                <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900">
                                    Korte situatieschets
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        {...register("korteSituatieSchets", { required: { value: true, message: "Korte situatieschets is verplicht" } })}
                                        rows={3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {errors["korteSituatieSchets"] && <span className='text-red-400 text-xs'>{errors["korteSituatieSchets"]?.message}</span>}
                                </div>
                            </div>

                            <div className="sm:col-span-full">
                                <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900">
                                    Doelstellingen
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        {...register("doelstellingen", { required: { value: true, message: "Doelstellingen is verplicht" } })}
                                        rows={3}
                                        placeholder='Beschrijf wat je met het Talent wilt bereiken en welk traject je in zou willen zetten.'
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {errors["doelstellingen"] && <span className='text-red-400 text-xs'>{errors["doelstellingen"]?.message}</span>}
                                </div>
                            </div>

                            <div className="sm:col-span-full">
                                <label htmlFor="first-name" className="block text-sm/6 font-medium text-gray-900">
                                    Bijzonderheden
                                </label>
                                <div className="mt-2">
                                    <textarea
                                        {...register("bijzonderheden", { required: { value: true, message: "Bijzonderheden is verplicht" } })}
                                        rows={3}
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                                    />
                                    {errors["bijzonderheden"] && <span className='text-red-400 text-xs'>{errors["bijzonderheden"]?.message}</span>}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="border-b border-gray-900/10 pb-12">
                        <h2 className="text-base/7 font-semibold text-gray-900">Het Talent en de Bloeiindex</h2>
                        <p className="mt-1 text-sm/6 text-gray-600">
                            Check, check dubbel check.
                        </p>

                        <div className="mt-10 space-y-10">
                            <fieldset>
                                <legend className="text-sm/6 font-semibold text-gray-900">Talent is akkoord met:</legend>
                                <div className="mt-6 space-y-6">
                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <input
                                                id="comments"
                                                name="comments"
                                                type="checkbox"
                                                required
                                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                            />
                                        </div>
                                        <div className="text-sm/6">
                                            <label htmlFor="comments" className="font-medium text-gray-900">
                                                Aanmelding bij {bedrijf?.naam}
                                            </label>
                                            <p className="text-gray-500">Het Talent is akkoord met het verwerken van de aanmelding</p>
                                        </div>
                                    </div>
                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <input
                                                id="candidates"
                                                name="candidates"
                                                type="checkbox"
                                                required
                                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                            />
                                        </div>
                                        <div className="text-sm/6">
                                            <label htmlFor="candidates" className="font-medium text-gray-900">
                                                Verwerkingsovereenkomst
                                            </label>
                                            <p className="text-gray-500">Het Talent is akkoord met het verwerken van zijn of haar gegevens</p>
                                        </div>
                                    </div>
                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <input
                                                id="offers"
                                                name="offers"
                                                type="checkbox"
                                                required
                                                className="h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600"
                                            />
                                        </div>
                                        <div className="text-sm/6">
                                            <label htmlFor="offers" className="font-medium text-gray-900">
                                                Delen van gegevens
                                            </label>
                                            <p className="text-gray-500">Het Talent is akkoord met het delen van de gegevens</p>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>

                        </div>
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6 sticky bottom-0 bg-orange-50 px-20 pb-12 pt-6">
                    <button type="button" className="text-sm/6 font-semibold text-gray-900">
                        Annuleren
                    </button>
                    <button
                        type="submit"
                        className="rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                        Aanmelding opslaan
                    </button>
                </div>
            </form>
        </Layout>
    )
}
