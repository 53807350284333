import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/react"
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/20/solid"
import { HTMLAttributes, forwardRef, useEffect, useRef, useState } from "react"
import { UserModel } from "../../../domain/user-utils"
import DefaultImage from "../../defaultimage/defaultimage"
import { CasemanagerModel } from "../../../domain/casemanager-utils"
import { User } from "../../../datasource/interfaces/collection"

type IMultipleCasemanagerSelection = HTMLAttributes<HTMLInputElement> &  {
    selectedCasemanagers: CasemanagerModel[],
    casemanagers: CasemanagerModel[],
    onChange: any
  }
  
  export const MultipleCasemanagerSelection = forwardRef<HTMLInputElement, IMultipleCasemanagerSelection>(({selectedCasemanagers = [], casemanagers, onChange, ...props}, ref) => {
  
    const [selected, setSelected] = useState(selectedCasemanagers)

    const casemanagersIdsRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (casemanagersIdsRef.current) {
          casemanagersIdsRef.current.dispatchEvent(new Event('input', { bubbles: true }))
        }
      }, [selected])
  
    return (
      <Listbox value={selected} onChange={(e) => {setSelected(e); } } multiple>
        <div className="relative">
          <ListboxButton className="relative min-w-52 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm/6">
            {selected.map((person) => <><span className="flex items-center">
              <DefaultImage profiel_foto={person?.profiel_foto} className="h-5 w-5 flex-shrink-0 rounded-full" />
              <span className="ml-3 block truncate">{person?.voornaam ?? "Selecteer een consulent"}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            </span>
            </>)}
            <input ref={casemanagersIdsRef} type="hidden" name="casemanagers[id]" onInput={(e) => {onChange(selected.map((casemanager) => casemanager.reference.id))}} value={selected.map((casemanager) => casemanager.reference.id)} />
            
          </ListboxButton>
  
          <ListboxOptions
            transition
            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {casemanagers.map((consulent) => (
              <ListboxOption
                key={consulent.reference.id}
                value={consulent}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
              >
                <div className="flex items-center">
                  <DefaultImage profiel_foto={consulent.profiel_foto} className="h-5 w-5 flex-shrink-0 rounded-full" />
                  <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                    {consulent.voornaam}
                  </span>
                </div>
  
                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-700 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                  <CheckIcon aria-hidden="true" className="h-5 w-5" />
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    )
  })


  export function CasemanagerSelectionV2({
    casemanager, 
    casemanagers,
    trajectId,
    onChange,
  }: {
    casemanager?: User,
    casemanagers: User[],
    trajectId: string,
    onChange: any
  }) {
  
    const trajectIdRef = useRef<HTMLInputElement | null>(null);
  
    const [selected, setSelected] = useState((casemanager) ? casemanager : undefined)
    const [currentTrajectId, setCurrentTrajectId] = useState(trajectId)

    if (currentTrajectId != trajectId) {
      setCurrentTrajectId(trajectId)
      setSelected(undefined)
    }
  
    useEffect(() => {
      if (casemanager)
        setSelected(casemanager)
    }, [casemanager])
  
    useEffect(() => {
      if (trajectIdRef.current) {
        trajectIdRef.current.dispatchEvent(new Event('input', { bubbles: true }))
      }
    }, [selected])
  
    return (
      <Listbox key={"test"} value={selected} onChange={(e) => {setSelected(e); }}>
        <div className="relative">
          <ListboxButton className="relative min-w-52 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm/6">
          <span className="flex items-center">
              {selected ? <DefaultImage profiel_foto={selected?.profiel_foto} className="h-5 w-5 flex-shrink-0 rounded-full" /> : <></>}
              <span className="ml-3 block truncate">{selected?.voornaam ?? "Selecteer een casemanager"}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            </span>
            <input ref={trajectIdRef} onInput={(e) => {onChange(selected?.reference)}} type="hidden" name="casemanager[id]" value={selected?.reference} />        </ListboxButton>
  
          <ListboxOptions
            transition
            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {casemanagers.map((casemanager) => (
              <ListboxOption
                key={casemanager.reference}
                value={casemanager}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
              >
                <div className="flex items-center">
                  <DefaultImage profiel_foto={casemanager.profiel_foto} className="h-5 w-5 flex-shrink-0 rounded-full" />
                  <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                    {casemanager.voornaam}
                  </span>
                </div>
  
                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-700 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                  <CheckIcon aria-hidden="true" className="h-5 w-5" />
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    )
  }