import { collection, query, getDocs, where, limit, DocumentReference, getDoc, doc, getCountFromServer, orderBy, and, QueryDocumentSnapshot, SnapshotOptions, QueryConstraint, startAfter, WriteBatch } from "firebase/firestore";
import { db } from "../utils/firebase";
import { LopendTraject, Traject } from "./interfaces/collection";

export type RemoteTalent = {
    reference: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    talent?: {
        gemeente: string,
        huisnummer: string,
        intake_ref: DocumentReference,
        lopendtraject_ref: DocumentReference,
        lopendtraject: LopendTraject,
        nationaliteit: string,
        plaats: string,
        postcode: string,
        straat: string,
        traject_ref: DocumentReference,
        traject: Traject,
        uitstroom: boolean
    },
    telefoon_nummer: string,
    voornaam: string,
    actief?: boolean
};

export type NewRemoteTalent = {
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    talent?: {
        intake_ref?: DocumentReference,
        gemeente: string,
        huisnummer: string,
        lopendtraject_ref?: DocumentReference,
        nationaliteit: string,
        plaats: string,
        postcode: string,
        straat: string,
        traject_ref: DocumentReference,
        traject: Traject,
        uitstroom: boolean,
        geboorte_datum: Date
    },
    telefoon_nummer: string,
    voornaam: string,
    actief: boolean,
    data_eigenaar_ref: DocumentReference
};

const talentConverter = {
    toFirestore: (talent: RemoteTalent) => {
        throw new Error("Function not implemented.");
    },
    fromFirestore(snapshot: QueryDocumentSnapshot,
        options: SnapshotOptions): RemoteTalent {
        const data = snapshot.data(options)!;
        return {
            reference: snapshot.ref,
            achternaam : data.achternaam,
                  email :data.email,
                  gebruikers_rol : data.gebruikers_rol,
                  geslacht : data.geslacht,
                  profiel_foto : data.profiel_foto,
                  telefoon_nummer : data.telefoon_nummer,
                  talent: {
                    gemeente: data.talent?.gemeente,
                    huisnummer: data.talent?.huisnummer,
                    intake_ref: data.talent?.intake_ref,
                    lopendtraject_ref: data.talent?.lopendtraject_ref,
                    lopendtraject: {
                        begindatum: data.talent?.lopendtraject?.begindatum?.toDate(),
                        reference: data.talent?.lopendtraject?.reference,
                        status: data.talent?.lopendtraject?.status
                    },
                    nationaliteit: data.talent?.nationaliteit,
                    plaats: data.talent?.plaats,
                    postcode: data.talent?.postcode,
                    straat: data.talent?.straat,
                    traject_ref: data.talent?.traject_ref,
                    traject: data.talent?.traject,
                    uitstroom: data.talent?.uitstroom
                },
                  voornaam : data.voornaam,
                  actief : data.actief,
        }
    }
}

async function getTalents(max: number, dataOwner: DocumentReference, filters?: QueryConstraint[], lastVisible?: {voornaam: string, achternaam: string}){

    let constraints: QueryConstraint[] = [where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "talent")]

    if (filters) {
        for (let query of filters) {
            constraints.push(query)
        }
    }

    constraints.push(orderBy("voornaam"), orderBy("achternaam"),)

    if (lastVisible) {
        constraints.push(startAfter(lastVisible.voornaam, lastVisible.achternaam))
    }

    constraints.push(limit(max))

    const q = query(collection(db, "user"), ...constraints).withConverter(talentConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteTalent[] =  [] 
    querySnapshot.forEach((doc) => list.push(doc.data()));

    return list; 
}

export const getTalentDocuments = async (dataOwner: DocumentReference, max: number, filters?: QueryConstraint[], lastVisible?: {voornaam: string, achternaam: string}) => {
    return getTalents(max, dataOwner, filters, lastVisible)
}

export const getTalentDocumentById = async (id: DocumentReference | string ) => {

    if(!id ){
        return null;
    }
    const docSnap =  (typeof id === "string" ) ?await getDoc( doc(db, "user", id.toString()).withConverter(talentConverter)):await getDoc((id as DocumentReference).withConverter(talentConverter));

    /* = await getDoc(id); */
    
    if (docSnap.exists()) {
       return docSnap.data()
      } else {
        return null; 
      }
}
export const getVerjaardagen = async () => {
    const currentDate = new Date();
    const verjaardag = query(collection(db, 'user'), where('geboortedatum', '>', currentDate)).withConverter(talentConverter);

    const querySnapshot = await getDocs(verjaardag );
    const list: RemoteTalent[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 

}

export const getTalentenWithUitstroom = async (dataOwner: DocumentReference) => {
    const q = query(collection(db, "user"), and(where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "talent"), where("talent.uitstroom", "==", true))).withConverter(talentConverter);

    const querySnapshot = await getDocs(q);
    const list: RemoteTalent[] =  [] 
    querySnapshot.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 

}

export const getGevoel = async () => {
    const gevoel = query(collection(db, 'mood_talent'), where('gevoel', '<=', 3))
}

//For the filter function
 export const getTalentDocumentsByTraject = async (reference: DocumentReference, dataOwner: DocumentReference) => {

    const q = query(collection(db, 'user'), and(where("data_eigenaar_ref", "==", dataOwner), where('talent.traject_ref', '==', reference)), orderBy("voornaam")).withConverter(talentConverter)
    const trajectQuery = await getDocs(q);

    const list: RemoteTalent[] =  [] 
    trajectQuery.forEach((doc) => {
        list.push(doc.data())
    });

    return list; 
}

export const getCountTalentenByTraject = async (reference: DocumentReference, dataOwner: DocumentReference) => {
    const q = query(collection(db, "user"), and(where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "talent"), where("talent.traject_ref", "==", reference)));
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count
}

export const getCountTalentenDocuments = async (dataOwner: DocumentReference, filters?: QueryConstraint[]) => {
    let constraints: QueryConstraint[] = [where("data_eigenaar_ref", "==", dataOwner), where("gebruikers_rol", "==", "talent")]

    if (filters) {
        for (let filter of filters) {
            constraints.push(filter)
        }
    }

    const q = query(collection(db, "user"), ...constraints);
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count
}

export const addTalentDocument = (talent: NewRemoteTalent, batch: WriteBatch, id?: string) => {
    const reference = (id) ? doc(db, "user", id) :  doc(collection(db, 'user'))

    batch.set(reference, talent)

    return reference
}

export const updateTalentDocument = (doc: DocumentReference, data: any, batch: WriteBatch) => {
    batch.update(doc, data)
}
