import { EnvelopeIcon, PhoneIcon, PlusIcon } from '@heroicons/react/20/solid';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import Layout from '../../../layout';
import { useState, useEffect } from 'react';
import getWerkvloerbegeleiders, { getTotalTalentsByWerkvloerbegeleiders, WerkvloerbegeleiderModel } from '../../../domain/werkvloerbegeleider-utils';
// import getCasemanagers, { getTotalTalentsByCasemanager, CasemanagerModel } from '../../../domain/casemanager-utils';
import Talenten from '../../../components/subnav/talenten/talenten';
import { useAuth } from '../../../context/AuthContext';
// import AddCasemanagerModal from '../../../components/modal/addcasemanger/AddCasemanagerModel';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

interface StatesProps {
  total: number;
  previousTotal: number;
  gemVerdeling: number;
}

export function States({ total, previousTotal, gemVerdeling }: StatesProps) {
  const calculateChange = (current, previous) => {
    if (previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };

  const stats = [
    {
      name: 'Totaal Talenten',
      stat: total,
      previousStat: previousTotal,
      change: calculateChange(total, previousTotal).toFixed(),
      changeType: total > previousTotal ? 'increase' : total < previousTotal ? 'decrease' : 'no-change',
    },
    {
      name: 'Gem. Verdeling',
      stat: `${gemVerdeling}%`,
      previousStat: '0.00%',
      change: calculateChange(gemVerdeling, 0).toFixed(2),
      changeType: gemVerdeling > 0 ? 'increase' : gemVerdeling < 0 ? 'decrease' : 'no-change',
    },
    {
      name: 'Gem. Gevoel',
      stat: '0%',
      previousStat: '0.00%',
      change: calculateChange(0.00, 0.00).toFixed(2),
      changeType: 0.00 > 0.00 ? 'increase' : 0.00 < 0.00 ? 'decrease' : 'no-change',
    },
  ];

  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 divide-y divide-gray-200 rounded-lg bg-white md:grid-cols-3 md:divide-x md:divide-y-0">
        {stats.map((item) => (
          <div key={item.name} className="px-4 py-5 sm:p-6">
            <dt className="text-base font-normal text-gray-900">{item.name}</dt>
            <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
              <div className="flex items-baseline text-2xl font-semibold text-blue-700">
                {item.stat}
                <span className="ml-2 text-sm font-medium text-gray-500">from {item.previousStat}</span>
              </div>

              <div
                className={classNames(
                  item.changeType === 'increase'
                    ? 'bg-green-100 text-green-800'
                    : item.changeType === 'decrease'
                      ? 'bg-red-100 text-red-800'
                      : 'bg-orange-100 text-orange-800',
                  'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0',
                )}
              >
                {item.changeType === 'increase' ? (
                  <ArrowUpIcon
                    aria-hidden="true"
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                  />
                ) : item.changeType === 'decrease' ? (
                  <ArrowDownIcon
                    aria-hidden="true"
                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                  />
                ) : (
                  <span className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-orange-500">—</span>
                )}
                <span className="sr-only">
                  {item.changeType === 'increase'
                    ? 'Increased'
                    : item.changeType === 'decrease'
                      ? 'Decreased'
                      : 'No change'}{' '}
                  by{' '}
                </span>
                {item.change}%
              </div>
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
}


export default function Caseload() {
  const [werkvloerBegeleiders, setWerkvloerBegeleiders] = useState<WerkvloerbegeleiderModel[]>([]);
  const [total, setTotal] = useState(0);
  const [previousTotal, setPreviousTotal] = useState(0);
  const [gemVerdeling, setGemVerdeling] = useState(0);
  const [isCaseModalOpen, setIsCaseModalOpen] = useState(false);
  // const [caseManagers, setCaseManagers] = useState<CasemanagerModel[]>([]);

  const { user } = useAuth();

  useEffect(() => {
    getWerkvloerbegeleiders(user!).then((werkvloerBegeleiders) => {
      // console.log("Fetched WerkvloerBegeleiders:", werkvloerBegeleiders);
      setWerkvloerBegeleiders(werkvloerBegeleiders);

    // getCasemanagers(user!).then((caseManagers) => {
    //   console.log("Fetched Casemanagers:", caseManagers);
    //   setCaseManagers(caseManagers);

      getTotalTalentsByWerkvloerbegeleiders(werkvloerBegeleiders, user!).then((newTotal) => {
        const roundedTotal = Math.round(newTotal);
        setTotal(roundedTotal);

      // getTotalTalentsByCasemanager(caseManagers, user!).then((newTotal) => {
      //   const roundedTotal = Math.round(newTotal);
      //   setTotal(roundedTotal);

        const totalTalentsAcrossBegeleiders = werkvloerBegeleiders.reduce(
          (acc, begeleider) => acc + (begeleider.talenten?.length || 0),
          0
        );

        // const totalTalentsAcrossCasemanager = werkvloerBegeleiders.reduce(
        //   (acc, begeleider) => acc + (begeleider.talenten?.length || 0),
        //   0
        // );

        const gemVerdelingVanTalenten =
          werkvloerBegeleiders.length > 0
        ? totalTalentsAcrossBegeleiders / werkvloerBegeleiders.length
            : 0;

        const percentageVerdeling =
          newTotal > 0 ? (gemVerdelingVanTalenten / newTotal) * 100 : 0;

        console.log('gemVerdeling:', percentageVerdeling);
        setGemVerdeling(Number(percentageVerdeling.toFixed(2)));

        // const gemVerdelingVanTalenten =
        //   caseManagers.length > 0
        //     ? totalTalentsAcrossCasemanager / caseManagers.length
        //     : 0;

        // const percentageVerdeling =
        //   newTotal > 0 ? (gemVerdelingVanTalenten / newTotal) * 100 : 0;

        // console.log('gemVerdeling:', percentageVerdeling);
        // setGemVerdeling(Number(percentageVerdeling.toFixed(2)));


        const savedPreviousTotal = localStorage.getItem('previousTalentsTotal');
        const previous = savedPreviousTotal ? parseInt(savedPreviousTotal, 10) : 0;
        setPreviousTotal(previous);

        localStorage.setItem('previousTalentsTotal', newTotal.toString());
        // console.log('werkvloerBegeleiders:', werkvloerBegeleiders);
        // console.log('newTotal:', newTotal);
        // console.log('totalTalentsAcrossBegeleiders:', totalTalentsAcrossBegeleiders);
        // console.log('gemVerdelingVanTalenten:', gemVerdelingVanTalenten);

      });
    });
  }, [user]);


  return (
    <div className="">
      <Layout>
        <Talenten />
        <div className="mx-auto px-8">
          <div>
            <States total={total} previousTotal={previousTotal} gemVerdeling={gemVerdeling} />
          </div>

          {/* <button
            onClick={() => setIsCaseModalOpen(true)}
            className="flex items-center px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-500 mt-4"

          >
            <PlusIcon className="h-5 w-5 mr-2" /> Nieuwe Casemanager
          </button> */}

          <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 pt-6">
            {werkvloerBegeleiders.map((werkvloerbegeleider) => (
            // {caseManagers.map((casemanager) => (
              <li
                key={werkvloerbegeleider.email}
                className="col-span-1 flex flex-col rounded-lg bg-white text-center shadow-2xl overflow-hidden"
              >
                <Link to={`/talentenhub/caseload/details/${werkvloerbegeleider.reference.id}`}>
                  <div className="flex flex-1 flex-col p-8">
                    <img
                      alt=""
                      src={werkvloerbegeleider.profiel_foto}
                      className="mx-auto h-32 w-32 flex-shrink-0 rounded-full"
                    />
                    <h3 className="mt-6 text-sm font-medium text-gray-900">
                      {werkvloerbegeleider.voornaam}&nbsp;{werkvloerbegeleider.achternaam}
                    </h3>
                    <dl className="mt-1 flex flex-grow flex-col justify-between">
                      <dt className="sr-only">Title</dt>
                      <dd className="text-sm text-gray-500">{werkvloerbegeleider.gebruikers_rol}</dd>
                      <dt className="sr-only">Role</dt>
                      <dd className="mt-3">
                        <span className="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                          {werkvloerbegeleider.gebruikers_rol}
                        </span>
                      </dd>
                    </dl>
                  </div>
                </Link>

                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                      <a
                        href={`mailto:${werkvloerbegeleider.email}`}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <EnvelopeIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        Email
                      </a>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                      <a
                        href={`tel:${werkvloerbegeleider.telefoon_nummer}`}
                        className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <PhoneIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        Call
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </Layout>
      {/* {isCaseModalOpen && (
        <AddCasemanagerModal
        isOpen={isCaseModalOpen}
        onClose={() => setIsCaseModalOpen(false)}
        onSave={() => {
          setIsCaseModalOpen(false);
          getCasemanagers(user!).then((updatedCaseManagers) => {
            console.log("Updated casemanagers:", updatedCaseManagers);
            setCaseManagers(updatedCaseManagers);
          });
        }}
        casemanagerData={{
          voornaam: '',
          achternaam: '',
          telefoon_nummer: '',
          profiel_foto: '',
          email: '',
          geslacht: '',
        }}
      />
      
      )} */}


    </div>
  );
}
