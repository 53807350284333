'use client'
import Layout from '../../layout'
import { useState, useEffect } from 'react'
import { Dialog, DialogPanel, Field, Label, Switch } from '@headlessui/react'
import { Bars3Icon } from '@heroicons/react/20/solid'
import {
  BellIcon,
  CreditCardIcon,
  CubeIcon,
  FingerPrintIcon,
  UserCircleIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import Beheer from '../../components/subnav/beheer/Beheer'
import { getBedrijfByReference, BedrijfModel } from '../../domain/bedrijf-utils'
import { useAuth } from '../../context/AuthContext'
import Logo from "logo512.png"
import { useProfilePhoto } from '../../hooks/useProfilePhoto';

const navigation = [
  { name: 'Home', href: '#' },
  { name: 'Invoices', href: '#' },
  { name: 'Clients', href: '#' },
  { name: 'Expenses', href: '#' },
]
const secondaryNavigation = [
  { name: 'General', href: '#', icon: UserCircleIcon, current: true },
  { name: 'Security', href: '#', icon: FingerPrintIcon, current: false },
  { name: 'Notifications', href: '#', icon: BellIcon, current: false },
  { name: 'Plan', href: '#', icon: CubeIcon, current: false },
  { name: 'Billing', href: '#', icon: CreditCardIcon, current: false },
  { name: 'Team members', href: '#', icon: UsersIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Settings() {

  const { user } = useAuth()

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
  const [bedrijven, setBedrijven] = useState<BedrijfModel[]>([]);
  const { profileFoto, handleFileChange } = useProfilePhoto();

  return (
    <Layout>
      <>
        <div className="mx-auto lg:flex lg:gap-x-16 lg:px-8">
          <h1 className="sr-only">General Settings</h1>

          <Beheer />

          <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-20">
            <div className="mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Algemene instellingen</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">
                  Hier vind je al je persoonlijke instellingen
                </p>

                <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Volledige naam</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{user?.voornaam} {user?.achternaam}</div>
                      {user?.gebruikers_rol == "admin" && <button type="button" className="font-semibold text-blue-700 hover:text-blue-500">
                        Update
                      </button>}
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Email</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{user?.email}</div>
                      {user?.gebruikers_rol == "admin" && <button type="button" className="font-semibold text-blue-700 hover:text-blue-500">
                        Update
                      </button>}
                    </dd>
                  </div>
                  <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Role</dt>
                    <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                      <div className="text-gray-900">{user?.gebruikers_rol}</div>
                      {user?.gebruikers_rol == "admin" && <button type="button" className="font-semibold text-blue-700 hover:text-blue-500">
                        Update
                      </button>}
                    </dd>
                  </div>
                  {/* <div className="pt-6 sm:flex">
                    <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Profile foto</dt>
                    <div className="mt-1 flex items-center">
                      {profileFoto ? (
                        <img
                          src={profileFoto}
                          alt="Profile"
                          className="w-16 h-16 rounded-full object-cover mr-4"
                        />
                      ) : (
                        <div className="w-16 h-16 rounded-full bg-gray-200 mr-4 border-gray-300 " />
                      )}
                      <input
                        id="profileFoto"
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="block text-sm text-gray-700 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 "
                      />
                      <button type="button" className="font-semibold text-blue-700 hover:text-blue-500 sm:w-64 sm:flex-none sm:pr-6">
                        Update
                      </button>
                    </div>
                  </div> */}
                </dl>
              </div>

              <div>
                <h2 className="text-base font-semibold leading-7 text-gray-900">Bedrijfsinstellingen</h2>
                <p className="mt-1 text-sm leading-6 text-gray-500">Connect bank accounts to your account.</p>

                <ul role="list" className="mt-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                  <li className="flex justify-between gap-x-6 py-6">
                    <div className="font-medium text-gray-900">[Bedrijf]</div>
                    <button type="button" className="font-semibold text-blue-700 hover:text-blue-500">
                      Update
                    </button>
                  </li>
                  <li className="flex justify-between gap-x-6 py-6">
                    <div className="font-medium text-gray-900">[Adres]</div>
                    <button type="button" className="font-semibold text-blue-700 hover:text-blue-500">
                      Update
                    </button>
                  </li>
                </ul>

                <div className="flex border-t border-gray-100 pt-6">
                  <button type="button" className="text-sm font-semibold leading-6 text-blue-700 hover:text-blue-500">
                    <span aria-hidden="true">+</span> Gegevens toevoegen
                  </button>
                </div>
              </div>




            </div>
          </main>
        </div>
      </>
    </Layout>
  )
}
