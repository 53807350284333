import { PaperClipIcon } from "@heroicons/react/20/solid";
import { useContext } from "react";
import { StepperContext } from "../../../../context/StepperContext";
import { createNewIntake } from "../../../../domain/intake-utils";
import { useNavigate } from "react-router-dom";

export default function TalentConfirm({ user, handleClick, aanmeldId }: { user: any, handleClick: any, aanmeldId?: string }) {
  const { data, setData } = useContext(StepperContext);
  const navigate = useNavigate(); // Initialize navigate

  const handleSave = () => {
    createNewIntake(user, data, aanmeldId).then((response) => {
      console.log("Data saved:", response);
      alert("Intake is succesvol opgeslagen, klik om door te gaan");
      navigate("/talentenhub/intakes"); // Redirect to the desired page
    });
  };

  const handleBack = () => {
    // Logic for navigating back
    handleClick("back")
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-md">
      <div className="mb-4">
        <h3 className="text-xl font-semibold text-gray-900">Confirm Your Information</h3>
        <p className="mt-1 text-sm text-gray-500">
          Please review all details carefully. Make sure you haven’t missed anything.
        </p>
      </div>
      <div className="bg-gray-50 p-4 rounded-md mb-4">
        <h4 className="text-md font-semibold text-gray-700">Applicant Information</h4>
        <ul className="mt-2 space-y-2 text-sm text-gray-600">
          {Object.entries(data).map(([key, value]) => (
            <li key={key}>
              <span className="font-medium capitalize">{key}:</span>{" "}
              {value !== undefined ? value.toString() : "Not provided"}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex justify-between">
        <button
          onClick={handleBack}
          className="px-4 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          Vorige
        </button>
        <button
          onClick={handleSave}
          className="px-4 py-2 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Intake Opslaan
        </button>
      </div>
    </div>
  );
}