import { DocumentReference } from "firebase/firestore"
import { UserModel } from "./user-utils"
import getCasemanagerDocuments from "../datasource/casemanager-utils"
import { getTotalCountOfTalentsByCasemanager } from "../datasource/lopend-traject-utils"
import { TalentModel } from "./talent-utils"
import { getBedrijfDocumentByReference } from "../datasource/bedrijf-utils"
import { getLopendeTrajectenByWerkvloerbegeleider } from "../datasource/lopend-traject-utils"
import { getTalentDocumentById } from "../datasource/talent-utils"
import { getCasemanagerById as _getCasemanagerById} from "../datasource/casemanager-utils"
import { TrajectModel, getTrajecten } from "./traject-utils"
import { findTrajectenByCasemanager  as _getCasemanagerTraject } from "./traject-utils"

export type CasemanagerModel = {
    reference: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,
    talenten?: TalentModel[],
    werkbedrijf?: {
        locatie: string
    }
}

const getCasemanagers = async (user: UserModel) => {
    const casemanagers = await getCasemanagerDocuments(50, user?.data_eigenaar_ref!)

    const list: CasemanagerModel[] = []

    casemanagers.forEach((casemanager) => {
        list.push({
            reference: casemanager.reference,
            achternaam: casemanager.achternaam,
            email: casemanager.email,
            gebruikers_rol: casemanager.gebruikers_rol,
            geslacht: casemanager.geslacht,
            profiel_foto: casemanager.profiel_foto,
            telefoon_nummer: casemanager.telefoon_nummer,
            voornaam: casemanager.voornaam,
        })
    })

    return list

}

export const getCasemanagerById = async (id: string, user: UserModel, options?: { werkbedrijf: boolean, talenten: boolean }) => {
    const werkvloerbegeleider = await _getCasemanagerById(id)

    if (!werkvloerbegeleider) {
        return Promise.reject("Geen werkbegeleider gevonden.")
    }

    let model: CasemanagerModel = {
        reference: werkvloerbegeleider.reference,
        achternaam: werkvloerbegeleider.achternaam,
        email: werkvloerbegeleider.email,
        gebruikers_rol: werkvloerbegeleider.gebruikers_rol,
        geslacht: werkvloerbegeleider.geslacht,
        profiel_foto: werkvloerbegeleider.profiel_foto,
        telefoon_nummer: werkvloerbegeleider.telefoon_nummer,
        voornaam: werkvloerbegeleider.voornaam,
    }

    // if (options?.werkbedrijf) {
    //     const werkbedrijf = await getBedrijfDocumentByReference(werkvloerbegeleider.werkbedrijf_reference.id)
    //     model.werkbedrijf = {
    //         locatie: `${werkbedrijf?.plaats}`
    //     }
    // }

    if (options?.talenten) {
        const lopendeTrajecten = await getLopendeTrajectenByWerkvloerbegeleider(werkvloerbegeleider.reference, user?.data_eigenaar_ref!)

        let talenten: TalentModel[] = []

        for (const lopendTraject of lopendeTrajecten) {
            if (lopendTraject.talent_ref) {
                let talent = await getTalentDocumentById(lopendTraject.talent_ref)

                if (talent)
                    talenten.push({
                        id: talent.reference.id,
                        ref: talent.reference,
                        gemeente: talent.talent?.gemeente,
                        huisnummer: talent.talent?.huisnummer,
                        nationaliteit: talent.talent?.nationaliteit,
                        plaats: talent.talent?.plaats,
                        postcode: talent.talent?.postcode,
                        straat: talent.talent?.straat,
                        telefoonnummer: talent.telefoon_nummer,
                        voornaam: talent.voornaam,
                        achternaam: talent.achternaam,
                        volledige_naam: talent.voornaam + " " + talent.achternaam,
                        foto: talent.profiel_foto,
                        email: talent.email,
                        rol: talent.gebruikers_rol,

                    })
            }
        }

        model.talenten = talenten
    }

    return model

}

export const getTotalTalentsByCasemanager = async (casemanagers: CasemanagerModel[], user: UserModel) => {
    const total = await getTotalCountOfTalentsByCasemanager(casemanagers.map((casemanager) => { return casemanager.reference }), user?.data_eigenaar_ref!)
    return total
}


export const getCasemanagerTraject = async (casemanager: CasemanagerModel, user: UserModel) => {
    const trajectList: TrajectModel[] = [];

 
        try {
            const traject = await _getCasemanagerTraject(casemanager.reference.id, casemanager.reference);
            if (traject) {
                trajectList.push(traject);
            }
        } catch (error) {
            console.error(`Fout bij ophalen traject voor casemanager ${casemanager.reference.id}:`, error);
        }

    return trajectList;
};


export default getCasemanagers