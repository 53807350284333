'use client'

import { useState } from 'react';
import {
    CalendarIcon,
    EnvelopeIcon,
    PhoneIcon,
    MapPinIcon,
    UserCircleIcon,
    PencilIcon,
    TrashIcon,
    ChevronRightIcon,
    CheckCircleIcon,
} from '@heroicons/react/24/outline';
import Layout from '../../layout';

export default function ProfileDetailPage() {
    const [selectedTab, setSelectedTab] = useState('Talentgegevens');
    const [editMode, setEditMode] = useState(false);

    const tabs = ['Talentgegevens', 'Traject', 'Opleiding', 'Rooster'];
    const timelineEvents = [
        { id: 1, title: 'Start Traject', date: '17/09/2024', status: 'Afgerond' },
        { id: 2, title: '1e Rapportage', date: '17/10/2024', status: 'Afgerond' },
        { id: 3, title: '2e Rapportage', date: '16/11/2024', status: 'Afgerond' },
        { id: 4, title: '3e Rapportage', date: '17/12/2024', status: 'Actief' },
    ];

    return (
        <Layout>
            <div className="min-h-screen bg-gray-50">
                {/* Header Section */}
                <header className="bg-white/90 backdrop-blur-lg shadow-md">
                    <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8 flex items-center justify-between">
                        <h1 className="text-2xl font-semibold text-gray-900">Profiel Overzicht</h1>
                        <button
                            onClick={() => setEditMode(!editMode)}
                            className="rounded-lg bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-md hover:bg-indigo-500"
                        >
                            {editMode ? 'Stop Bewerken' : 'Bewerken'}
                            <PencilIcon className="ml-2 h-4 w-4 inline" />
                        </button>
                    </div>
                </header>

                {/* Submenu */}
                <nav className="bg-white/90 backdrop-blur-lg shadow-sm sticky top-0">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <ul className="flex space-x-6 border-b">
                            {tabs.map((tab) => (
                                <li key={tab}>
                                    <button
                                        className={`py-3 px-4 text-sm font-medium ${
                                            selectedTab === tab
                                                ? 'text-blue-700 border-b-2 border-indigo-600'
                                                : 'text-gray-500 hover:text-gray-700'
                                        }`}
                                        onClick={() => setSelectedTab(tab)}
                                    >
                                        {tab}
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </nav>

                {/* Main Content */}
                <main className="mt-6 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 space-y-8">
                    {/* Profile Card */}
                    <div className="bg-white rounded-xl shadow-lg p-6 flex flex-col md:flex-row md:items-center glass-effect">
                        <div className="flex-shrink-0">
                            <img
                                className="h-28 w-28 rounded-full border-4 border-indigo-500"
                                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                alt="Profile Picture"
                            />
                        </div>
                        <div className="mt-4 md:mt-0 md:ml-6 flex-1">
                            <h2 className="text-xl font-bold text-gray-900">Abeer Osman Khair</h2>
                            <p className="text-sm text-gray-500">dr.abeer0009@gmail.com</p>
                            <p className="text-sm text-gray-500">0621228032</p>
                            <p className="mt-2 text-sm text-gray-700">
                                BanjoStraat 32, 2287EL Rijswijk, Nederland
                            </p>
                        </div>
                    </div>

                    {/* Timeline */}
                    <section>
                        <h3 className="text-lg font-semibold text-gray-900">Traject Tijdlijn</h3>
                        <div className="mt-4 border-l-2 border-gray-200 pl-6 space-y-6">
                            {timelineEvents.map((event) => (
                                <div key={event.id} className="relative flex items-start space-x-4">
                                    <div
                                        className={`absolute -left-6 h-4 w-4 rounded-full ${
                                            event.status === 'Afgerond'
                                                ? 'bg-green-500'
                                                : 'bg-indigo-500'
                                        }`}
                                    >
                                        {event.status === 'Afgerond' && (
                                            <CheckCircleIcon className="h-4 w-4 text-white" />
                                        )}
                                    </div>
                                    <div>
                                        <p className="text-sm font-medium text-gray-900">
                                            {event.title}
                                        </p>
                                        <p className="text-sm text-gray-500">{event.date}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </section>

                    {/* Selected Section */}
                    <section>
                        {selectedTab === 'Talentgegevens' && (
                            <div className="bg-white rounded-lg shadow-lg p-6">
                                <h3 className="text-lg font-medium text-gray-900">
                                    Algemene Informatie
                                </h3>
                                <ul className="mt-4 space-y-2">
                                    <li className="flex items-center">
                                        <UserCircleIcon className="h-5 w-5 text-gray-500 mr-3" />
                                        <span>Rol: Talent</span>
                                    </li>
                                    <li className="flex items-center">
                                        <CalendarIcon className="h-5 w-5 text-gray-500 mr-3" />
                                        <span>Startdatum: 12-09-2024</span>
                                    </li>
                                    <li className="flex items-center">
                                        <MapPinIcon className="h-5 w-5 text-gray-500 mr-3" />
                                        <span>Locatie: De Martinitafel</span>
                                    </li>
                                </ul>
                            </div>
                        )}
                        {selectedTab === 'Traject' && (
                            <div className="bg-white rounded-lg shadow-lg p-6">
                                <h3 className="text-lg font-medium text-gray-900">Trajectinformatie</h3>
                                <p className="mt-4 text-gray-700">Hier komt informatie over het traject.</p>
                            </div>
                        )}
                        {selectedTab === 'Opleiding' && (
                            <div className="bg-white rounded-lg shadow-lg p-6">
                                <h3 className="text-lg font-medium text-gray-900">Opleiding</h3>
                                <p className="mt-4 text-gray-700">
                                    Hier komt informatie over opleidingen.
                                </p>
                            </div>
                        )}
                        {selectedTab === 'Rooster' && (
                            <div className="bg-white rounded-lg shadow-lg p-6">
                                <h3 className="text-lg font-medium text-gray-900">Rooster</h3>
                                <p className="mt-4 text-gray-700">Hier komt informatie over het rooster.</p>
                            </div>
                        )}
                    </section>
                </main>
            </div>
        </Layout>
    );
}