import Layout from "../layout";
import { useEffect, useState } from "react";
import { EvaluatieModel, getAllEvaluaties } from './../domain/evaluatie-utils';
import { getRapportageSignalen, SignaalModel } from "../domain/signalen-utils";
import Grafiek from "../components/charts/barchart/Grafiek";
import Aanmeldingen from "../components/lists/aanmeldingen/Aanmeldingen";
import Griddocuments from "../components/grid/documents/Griddocuments";
import { AanmeldingModel, getAanmeldingen } from "../domain/aanmelding-utils";
import TrajectTab from "../components/traject/App";
import { useAuth } from "../context/AuthContext";
import { fetchRapportages, RapportageModel } from "../domain/rapportage-utils";
import Mainloader from "../components/loader/mainloader/Mainloader";
import Drawersignalen from "../components/drawer/drawersignalen/Drawersignalen";
import Signalen from "../components/lists/signalen/Signalen";
import { Button } from "@headlessui/react";
import UpdatesMobile from "../components/updates/updatesmobile/UpdatesMobile";
import { useNavigate } from "react-router-dom";
import HomepageLayout from "../components/layout/HomepageLayout";
// import UpdatesMobile from "../components/updates/updatesmobile/UpdatesMobile";

function Home() {
  const [aanmeldingen, setAanmeldingen] = useState<AanmeldingModel[]>([]);
  const [signalen, setSignalen] = useState<SignaalModel[]>([]);
  const [evaluaties, setEvaluaties] = useState<EvaluatieModel[]>([]);
  const [rapportages, setRapportages] = useState<RapportageModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false)
  const { user } = useAuth();
  const navigate = useNavigate();

  const features = [
    { name: "Nieuwe Intake", icon: "📄", link: "/talentenhub/intakeformulier" },
    { name: "Nieuwe rapportage", icon: "📖", link: "/talentenhub" },
    { name: "Nieuwe aanmelding", icon: "🤗", link: "/talentenhub/aanmeldingformulier" },
    { name: "Gesprek notuleren", icon: "🎧", link: "/gesprek-notuleren", disabled: true },
    { name: "Rooster maken", icon: "🎛️", link: "/rooster", disabled: true },
    { name: "Praktijkverklaring", icon: "🏆", link: "/praktijkverklaring", disabled: true },
  ];


  useEffect(() => {
    Promise.all([
      getAanmeldingen(user!, { aangemaaktDoor: true }),
      getAllEvaluaties(user!, 5),
      fetchRapportages(5, user!, { talent: true })
    ]).then(([aanmeldingen, evaluaties, rapportages]) => {
      setAanmeldingen(aanmeldingen);
      setEvaluaties(evaluaties);
      setRapportages(rapportages);
      setIsLoading(false);
      getRapportageSignalen(user!, 20).then((signalen) => setSignalen(signalen))
    });
  }, []);
  const showSignalen = () => {
    setOpen(true)
  }
  if (isLoading) return <Mainloader />;

  return (
    <Layout>

      <div className="mx-auto">

        {/* Mobile Layout */}
        <div className="block lg:hidden">
          <UpdatesMobile user={user!} />
        </div>


        <div className="max-w-5xl container mx-auto px-6 py-8 mt-12 hidden lg:block">
          <div className="bg-black text-white px-4 py-2 rounded-full flex items-center space-x-2 max-w-max mx-auto">
            <span className="bg-white text-black px-2 py-1 rounded-full text-xs font-semibold">Welkom</span>
            <span>Wat leuk dat je de Bloeiindex gebruikt {user?.voornaam}</span>
            {/* <span className="ml-2 cursor-pointer">→</span> */}
          </div>

          <div className="mt-12 text-left">
            <p className="text-gray-600 text-sm">Bloeiindex x {user?.bedrijf?.naam}</p>
            <h1 className="text-3xl font-bold">Welkom 👋🏻 {user?.voornaam} {user?.achternaam}</h1>
          </div>

          <div className="mt-8 grid grid-cols-3 md:grid-cols-6 gap-4">
            {features.map((feature, index) => (
              <div
                key={index}
                onClick={() => !feature.disabled && navigate(feature.link)}
                className={`flex flex-col items-center cursor-pointer w-full ${feature.disabled ? "cursor-not-allowed opacity-50" : ""
                  }`}
              >
                <div className="bg-orange-50 rounded-lg flex items-center justify-center w-full h-28 hover:bg-gray-200 transition relative ">
                  <span className="text-5xl">{feature.icon}</span>
                  {feature.disabled && (
                    <div className="absolute inset-0 flex items-center justify-center text-center bg-black bg-opacity-50 text-white text-sm font-medium rounded-lg">
                      Binnenkort beschikbaar
                    </div>
                  )}
                </div>
                <p className="mt-2 text-sm text-center w-full">{feature.name}</p>
              </div>
            ))}
          </div>

          <div className="mt-24 grid grid-cols-1 md:grid-cols-1 gap-10">
            <div className="grid-cols-2">
              <div className="mx-auto">
                <div className="grid grid-cols-2 flex">
                  <h2 className="text-lg font-semibold">Laatste aanmeldingen in de Bloeiindex</h2>
                  <Button
                    onClick={() => navigate("/talentenhub/aanmeldingen")}
                    className=" px-4 py-2 bg-gray-200 rounded-md text-gray-700 hover:bg-gray-300 w-52 justify-self-end">
                    Alle aanmeldingen
                  </Button>
                </div>
                <div className="mt-4 space-y-4">
                  <Aanmeldingen aanmeldingen={aanmeldingen} />
                </div>
              </div>

            </div>

            <div>
              <h2 className="text-lg font-semibold">Nieuwste updates</h2>
              <div className="mt-4 space-y-4">
                {TrajectTab(evaluaties)}
              </div>
              <Button
                onClick={() => navigate("/talentenhub")}
                className="mt-4 px-4 py-2 bg-gray-200 rounded-md text-gray-700 hover:bg-gray-300">
                Talentenhub
              </Button>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  );
}

export default Home;