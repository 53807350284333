import Layout from "../../layout";
import RapportagePDFFile from "../../components/pdf/RapportagePDF"
import AanvragenPDFFile from "../../components/pdf/AanvragenPDF"
import AanmeldPDFFile from "../../components/pdf/AanmeldPDF"
import OntwikkeldoelenPDFFile  from "../../components/pdf/OntwikkeldoelenPDF"
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useState } from "react";
import { RapportageModel } from "../../domain/rapportage-utils";

export default function TrajectFormulier() {
    const [rapportage, setRapportage] = useState<RapportageModel>()


    return (
            <Layout>
               <PDFDownloadLink document={<RapportagePDFFile content={""} onderwerp={undefined} datum={undefined} licentienemer={rapportage?.licentienemer}/>} fileName={"Rapportage"}>
                  
                  Download Rapportage
                  </PDFDownloadLink>
                  
                 <br></br>

                  <PDFDownloadLink document={<AanvragenPDFFile content={""}/>} fileName={"Aanvragen"}>
                  
                  Download Aanvragen
                  </PDFDownloadLink>

                  <br></br>

                  <PDFDownloadLink document={<AanmeldPDFFile content={""}/>} fileName={"Aanmeld"}>
                  
                  Download Aanmeld
                  </PDFDownloadLink>

                  <br></br>

                  <PDFDownloadLink document={<OntwikkeldoelenPDFFile content={""}/>} fileName={"Ontwikkeldoelen"}>
                  
                  Download Ontwikkeldoelen
                  </PDFDownloadLink>
                 
            </Layout>
             

    
    )
}
