import { Document, Page, Text, View, StyleSheet, Font, Image } from "@react-pdf/renderer";
import Html from "react-pdf-html";
import { marked } from "marked";
import { useEffect, useState } from "react";
// import { fetchLogos } from "../../domain/bedrijf-utils";
import { fetchLogos } from "../../domain/licentienemer-utils";
import Logo from "../../../src/logo512.png";

// Register Arial font
Font.register({
  family: "Arial",
  src: "https://cdn.jsdelivr.net/gh/ryanoasis/nerd-fonts@2.3.3/src/unpatched-fonts/Arial/Regular/Arial.ttf",
});


const RapportagePDFFile = ({ content, onderwerp, datum, licentienemer }) => {
  const [logo, setLogo] = useState("");

  useEffect(() => {
    const getLogo = async () => {
      if (licentienemer) {
        const fetchedLogo = await fetchLogos(licentienemer);
        setLogo(fetchedLogo);
      }
    };

    getLogo();
  }, [licentienemer]);

//   useEffect(() => {
//     console.log("📄 Bedrijf ontvangen in PDF:", licentienemer);
//     if (licentienemer?.naam) {
//         console.log("🏢 Bedrijfsnaam gevonden:", licentienemer.naam);
//         fetchLogos(licentienemer.naam).then(logo => {
//             console.log("✅ Logo ontvangen en ingesteld:", logo);
//             setLogo(logo);
//         });
//     } else {
//         console.error("🚨 Geen bedrijfsnaam beschikbaar voor logo!");
//     }
// }, [licentienemer]);

// useEffect(() => {
//     console.log("🖨️ Logo klaar voor PDF:", logo);
// }, [logo]); 

  let parsedContent;
  try {
    parsedContent = typeof content === "string" ? JSON.parse(content).text : content.text;
  } catch (error) {
    parsedContent = content;
  }

  if (!parsedContent) parsedContent = "<h1>Geen inhoud beschikbaar</h1>";

  const formattedContent = String(marked(parsedContent));

  const styles = StyleSheet.create({
    page: {
      fontFamily: "Helvetica",
      fontSize: "10px",
      lineHeight: 1.4,
      padding: 30,
      color: "#333",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 20,
    },
    headerImage: {
      width: 70,
      height: 60,
      marginLeft: 5,
    },
    headerInfo: {
      fontSize: 10,
      lineHeight: 1.5,
    },
    body: {
      fontSize: 11,
      color: "#34495e",
      textAlign: "justify",
      margin: 30,
    },
    footer: {
      position: "absolute",
      bottom: 30,
      left: 30,
      right: 30,
      fontSize: 10,
      textAlign: "center",
      color: "#888",
    },
  });


  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerInfo}>
            <Text>Rapportage</Text>
            <Text>Onderwerp: {onderwerp}</Text>
            <Text>Gegenereerd op: {datum}</Text>
          </View>
         <Image style={styles.headerImage} src={Logo}/>
        </View>
        <View>
          <Html style={styles.body}>{formattedContent}</Html>
        </View>
        <View style={styles.footer}>
          <Text>© 2024 - Bloeiindex | Vertrouwelijk</Text>
        </View>
      </Page>
    </Document>
  );
};

export default RapportagePDFFile;
