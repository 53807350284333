import { Button, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { EllipsisVerticalIcon, EyeIcon, UserPlusIcon } from '@heroicons/react/20/solid'
import Layout from '../../../layout'
import Talenten from '../../../components/subnav/talenten/talenten'
import { getIntakes } from '../../../domain/intake-utils'
import { useEffect, useState } from 'react'
import { AanmeldingModel, afmeldenAanmelding, getAanmeldingen, getAfgemeldeAanmeldingen } from '../../../domain/aanmelding-utils'
import { useAuth } from '../../../context/AuthContext'
import { AfmeldButton } from '../../../components/button/afmeld-button'
import { Link } from 'react-router-dom'
import TalentInviteModal from '../../../components/modal/invite/Invite'




export default function Aanmeldingen() {

    const [aanmeldingen, setAanmeldingen] = useState<AanmeldingModel[]>([])
    const [afgemeld, setAfgemeld] = useState<AanmeldingModel[]>([])
    const { user } = useAuth()
    const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
    const [hasInvited, setHasInvited] = useState<Record<string, boolean>>({});



    useEffect(() => {
        Promise.all([getAfgemeldeAanmeldingen(user!, { aangemaaktDoor: true }), getAanmeldingen(user!, { aangemaaktDoor: true })]).then(([afgemeldeAanmeldingen, aanmeldingen]) => {
            setAanmeldingen(aanmeldingen)
            setAfgemeld(afgemeldeAanmeldingen)
        })
    }, [])

    const afmelden = (index) => {
        const aanmeldingToAfmelden = aanmeldingen[index];
        afmeldenAanmelding(aanmeldingToAfmelden.id).then(() => {
            setAanmeldingen(aanmeldingen.filter((_, i) => i !== index));
            setAfgemeld([aanmeldingToAfmelden].concat(afgemeld));
        });
    };

    return (
        <Layout>
            <Talenten />
            <ul role="list" className="mx-auto max-w-7xl divide-y divide-gray-100 px-8">
                {aanmeldingen.map((aanmelding, index) => (
                    <li key={aanmelding.id} className="flex items-center justify-between gap-x-6 py-5">
                        <div className="min-w-0">
                            <Link to={{ pathname: `/talentenhub/aanmeldingdocument/${aanmelding.id}` }}>
                                <div className="flex items-start gap-x-3">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">{aanmelding.volledige_naam}</p>
                                </div>
                                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                    <p className="whitespace-nowrap">
                                        Binnengekomen op {aanmelding.aanmaakDatum.toLocaleDateString()}
                                    </p>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                        <circle r={1} cx={1} cy={1} />
                                    </svg>
                                    <p className="truncate">Casemanager: {aanmelding.aangemaakt_door?.volledige_naam}</p>
                                </div>
                            </Link>
                        </div>
                        <div className="flex flex-none items-center gap-x-4">
                            <div className="relative group">
                                <button
                                    onClick={() => window.location.href = `/talentenhub/aanmeldingdocument/${aanmelding.id}`}
                                    className="hidden sm:flex items-center justify-center rounded-full bg-white p-2 text-gray-700 shadow-sm transition hover:bg-gray-100 hover:text-gray-900"
                                >
                                    <EyeIcon className="h-5 w-5" />
                                </button>
                                {/* Tooltip */}
                                <span className="absolute left-1/2 -translate-x-1/2 top-full mt-2 w-max whitespace-nowrap rounded-md bg-gray-900 px-2 py-1 text-xs text-white opacity-0 transition-opacity duration-200 group-hover:opacity-100">
                                    Bekijken
                                </span>
                            </div>
                            <div className="relative group">
                                <button
                                    onClick={() => {
                                        setIsInviteModalOpen(true);
                                        setTimeout(() => setHasInvited((prevState) => ({ ...prevState, [aanmelding.id]: true })), 1000); // Simulate invite process
                                    }}
                                    className={`hidden sm:flex items-center justify-center rounded-full p-2 shadow-sm transition ${hasInvited[aanmelding.id] ? 'bg-green-600 text-white hover:bg-green-500' : 'bg-white text-blue-700 hover:bg-grey-500'
                                        }`}
                                >
                                    <UserPlusIcon className="h-5 w-5" />
                                </button>
                                {/* Tooltip */}
                                <span className="absolute left-1/2 -translate-x-1/2 top-full mt-2 w-max whitespace-nowrap rounded-md bg-gray-900 px-2 py-1 text-xs text-white opacity-0 transition-opacity duration-200 group-hover:opacity-100">
                                    {hasInvited[aanmelding.id] ? 'Uitgenodigd' : 'Talent uitnodigen'}
                                </span>
                            </div>
                            <a
                                href={`/talentenhub/intakeformulier/${aanmelding.id}`}
                                className="hidden rounded-md bg-blue-700 px-2.5 py-1.5 text-sm font-semibold text-white hover:bg-blue-500 sm:block"
                            >
                                Intake starten
                            </a>
                            <AfmeldButton onClick={() => afmelden(index)} />
                        </div>
                    </li>
                ))}
            </ul>

            {/* Afmeldingen Table */}
            <div className="mx-auto max-w-7xl px-8 mt-10">
                <h2 className="text-lg font-semibold text-gray-900">Gearchiveerd</h2>
                <ul role="list" className="divide-y divide-gray-100 mt-4">
                    {afgemeld.map((afmelding, index) => (
                        <li key={index} className="flex items-center justify-between gap-x-6 py-5">
                            <div className="min-w-0">
                                <div className="flex items-start gap-x-3">
                                    <p className="text-sm font-semibold leading-6 text-gray-900">{afmelding.volledige_naam}</p>
                                </div>
                                <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                                    <p className="whitespace-nowrap">
                                        Afgemeld op {new Date().toLocaleDateString()}
                                    </p>
                                    <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                        <circle r={1} cx={1} cy={1} />
                                    </svg>
                                    <p className="truncate">Casemanager: {afmelding.aangemaakt_door?.volledige_naam}</p>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>

            <TalentInviteModal
                isOpen={isInviteModalOpen}
                onClose={() => setIsInviteModalOpen(false)}
                talentEmail="poep@me.com"
            />
        </Layout>
    );
}