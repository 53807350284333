import { useState } from 'react';

export const useProfilePhoto = () => {
  const [profileFoto, setProfileFoto] = useState<string | undefined>(undefined);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfileFoto(reader.result as string); 
      };
      reader.readAsDataURL(file);
    }
  };

  return {
    profileFoto,
    handleFileChange,
  };
};
