import { ArrowsUpDownIcon, PlusCircleIcon } from "@heroicons/react/20/solid"

const people = [
  {
    name: 'Assistent metaal-, elektro- en installatietechniek',
    title: 'Niveau 1',
    department: 'Crebo: 25744',
    email: 'Startdatum: Gister',
    role: 'Marlijn Academie',
    image:
      'https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  // More people...
]

export default function OpleidenTab() {
  return (
    <div className="mt-6 px-8">
      {/* Header */}
      <div className="px-4 sm:px-0 relative">
        <h3 className="text-base font-semibold leading-7 text-gray-900">Opleidingen en Praktijkverklaringen</h3>
        <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">Documenten en informatie op een rij</p>
        <button
          type="button"
          className="absolute right-0 top-0 flex items-center justify-center w-8 h-8 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none"
          aria-label="Toggle Sort Order"
        >
          <PlusCircleIcon className="h-4 w-4 text-gray-600" />
        </button>
      </div>

      {/* Coming Soon Card */}
      <div className="mt-6 bg-gray-100 rounded-lg min-h-32 p-4 flex items-center justify-center text-center border border-gray-300">
        <div>
          <h4 className="text-lg font-semibold text-gray-700">Binnenkort beschikbaar</h4>
          <p className="text-sm text-gray-500">We werken eraan om meer opleidingsmogelijkheden toe te voegen.</p>
        </div>
      </div>
    </div>
  )
}
