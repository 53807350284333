'use client'

import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { Bars4Icon, CalendarIcon, ChartBarIcon, CheckIcon, ClockIcon, PhotoIcon, SpeakerWaveIcon, TableCellsIcon, ViewColumnsIcon } from '@heroicons/react/24/outline'
import { NewspaperIcon } from '@heroicons/react/20/solid'

const items = [
    {
        title: 'Nieuwe Rapportage',
        description: 'Genereer snel een uitgebreide rapportage op basis van dagelijkse updates en voortgang.',
        icon: Bars4Icon,
        background: 'bg-sky-400',
    },
    {
        title: 'Loonwaardemeting',
        description: 'Houd de prestaties en inzet van talenten overzichtelijk bij in een loonwaarderapport.',
        icon: CalendarIcon,
        background: 'bg-yellow-500',
        disabled: true, // Disable this option
    },
    {
        title: 'Gespreksverslag',
        description: 'Geneer een voorbeeld verslag en gebruik deze bij je gesprek',
        icon: SpeakerWaveIcon,
        background: 'bg-green-500',
        disabled: true, // Disable this option
    },
    {
        title: 'Talent portfolio',
        description: 'Een overzicht van het traject, de behaalde resultaten en het CV',
        icon: ChartBarIcon,
        background: 'bg-blue-500',
        disabled: true, // Disable this option
    },
    {
        title: 'Opleiding toevoegen',
        description: 'Beheer trainingen en opleidingen die talenten ondersteunen in hun groei.',
        icon: TableCellsIcon,
        background: 'bg-indigo-500',
        disabled: true, // Disable this option
    },
    {
        title: 'Praktijkverklaring aanvragen',
        description: 'Start het proces om een officiële praktijkverklaring aan te vragen voor talenten.',
        icon: ClockIcon,
        background: 'bg-purple-500',
        disabled: true, // Disable this option
    },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function Example({ open, setOpen, onclick, onclickparktijk }) {

    return (
        <Dialog open={open} onClose={() => setOpen(false)} className="fixed inset-0 z-50 flex items-center justify-center">
            {/* Background overlay */}
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-md transition-opacity" onClick={() => setOpen(false)} />

            {/* Modal container */}
            <div className="relative z-10 w-screen max-w-3xl mx-auto" onClick={(e) => e.stopPropagation()}>
                <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
                    <DialogPanel
                        className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left transition-all sm:my-8 sm:p-6"
                    >
                        <div>
                            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                                <NewspaperIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                            </div>
                            <div className="mt-3 sm:mt-5">
                                <DialogTitle as="h3" className="text-base font-semibold text-gray-900 text-center">
                                    Nieuw document genereren
                                </DialogTitle>
                                <div className="mt-2">
                                    <ul role="list" className="mt-6 grid grid-cols-1 gap-6 border-b border-t border-gray-200 py-6 sm:grid-cols-2">
                                        {items.map((item, itemIdx) => (
                                            <li key={itemIdx} className="flow-root">
                                                <div
                                                    className={`relative -m-2 flex items-center space-x-4 rounded-xl p-2 focus-within:ring-2 ${item.disabled
                                                            ? 'bg-gray-100 cursor-not-allowed opacity-50 rounded-lg' // Grey out disabled options
                                                            : 'hover:bg-gray-50 focus-within:ring-indigo-500'
                                                        }`}
                                                >
                                                    <div className={`${item.background} flex h-16 w-16 shrink-0 items-center justify-center rounded-lg`}>
                                                        <item.icon aria-hidden="true" className="h-6 w-6 text-white" />
                                                    </div>
                                                    <div>
                                                        <h3 className="text-sm font-medium text-gray-900">
                                                            {!item.disabled ? (
                                                                <a
                                                                    onClick={(item.title === "Praktijkverklaring aanvragen" ? onclickparktijk : onclick)}
                                                                    href="#"
                                                                    className="focus:outline-none"
                                                                >
                                                                    <span aria-hidden="true" className="absolute inset-0" />
                                                                    <span>{item.title}</span>
                                                                    <span aria-hidden="true"> &rarr;</span>
                                                                </a>
                                                            ) : (
                                                                <span className="text-gray-500">{item.title}</span>
                                                            )}
                                                        </h3>
                                                        <p className="mt-1 text-sm text-gray-500">
                                                            {item.disabled ? "Binnenkort beschikbaar" : item.description}
                                                        </p>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 sm:mt-6">
                            <button
                                type="button"
                                onClick={() => setOpen(false)}
                                className="inline-flex w-full justify-center rounded-md bg-sky-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Terug naar de Bloeiindex
                            </button>
                        </div>
                    </DialogPanel>
                </div>
            </div>
        </Dialog>
    )
}
