import { DocumentReference } from "firebase/firestore";
import getAllUsersByCursor, {getAllConsulentenDocuments, getAllNonTalentDocuments, getUserByReference, getUserDocumentById, searchUserByName, updateUserByRef } from "../datasource/user-utils";
import { TrajectModel } from "./traject-utils";
import { getLicentienemerById } from "./licentienemer-utils";

export type UserModel = {
    ref: DocumentReference,
    achternaam: string,
    email: string,
    gebruikers_rol: string,
    geslacht: string,
    profiel_foto?: string,
    telefoon_nummer: string,
    voornaam: string,
    heeftAccount?: boolean,
    geboortedatum?: string,
    data_eigenaar_ref?: DocumentReference,
    bedrijf?: {
        naam: string,
        logo?: string
    }
}

export type NewUserModel = {
    voornaam: string,
    achternaam: string,
    email: string,
    gebruikers_rol: "talent" | "werkvloerbegeleider" | "consulent" | "licentienemer",
    geslacht: "man" | "vrouw" | "anders",
    profiel_foto?: string,
    telefoon_nummer: string,
    talent?: {
        gemeente: string,
        huisnummer: string,
        nationaliteit: string,
        plaats: string,
        postcode: string,
        straat: string,
        geboorte_datum: Date
    },
    data_eigenaar_ref?: DocumentReference,
    traject?: {
        naam: string
    },
    lopend_traject?: {
        start_datum?: Date,
        werkdagen?: string,
        status?: string
    },
    bedrijf?: {
        naam: string
    }
}

export default async (userRef: DocumentReference, data: any) => {
    return updateUserByRef(userRef, data)
}

export const getAllUsersByPage = async (user: UserModel, docRef?: DocumentReference) => {
    var list: UserModel[] = []
    const users = await getAllUsersByCursor(25, [], user.data_eigenaar_ref!, docRef)
    users.forEach((user) => {
        list.push({
            ref: user.ref,
            achternaam: user.achternaam,
            email: user.email,
            gebruikers_rol: user.gebruikers_rol,
            geslacht: user.geslacht,
            profiel_foto: user.profiel_foto,
            telefoon_nummer: user.telefoon_nummer,
            voornaam: user.voornaam,
            geboortedatum: user.geboortedatum,
        })
    });
}

export const searchByTerm = async (user: UserModel, term: string) => {
    var list: UserModel[] = []
    const users = await searchUserByName(user.data_eigenaar_ref!, term)
    users.forEach((user) => {
        list.push({
            ref: user.ref,
            achternaam: user.achternaam,
            email: user.email,
            gebruikers_rol: user.gebruikers_rol,
            geslacht: user.geslacht,
            profiel_foto: user.profiel_foto,
            telefoon_nummer: user.telefoon_nummer,
            voornaam: user.voornaam,
            geboortedatum: user.geboortedatum,
        })
    });
    return list
}

//Get user by id
export const getUserById = async (userRef?: DocumentReference): Promise<UserModel | null> => {

    if (!userRef) return null

    const user = await getUserByReference(userRef)

    if (!user) {
        return null
    }

    return {
        ref: user.ref,
        achternaam: user.achternaam,
        email: user.email,
        gebruikers_rol: user.gebruikers_rol,
        geslacht: user.geslacht,
        profiel_foto: user.profiel_foto,
        telefoon_nummer: user.telefoon_nummer,
        voornaam: user.voornaam,
        geboortedatum: user.geboortedatum,
        data_eigenaar_ref: user.data_eigenaar_ref
    };
};

export const _getUserById = async (id?: string) => {

    if (!id) return null

    const user = await getUserDocumentById(id)

    if (!user) {
        return null
    }

    const company = await getLicentienemerById(user.data_eigenaar_ref?.id)

    return {
        ref: user.ref,
        achternaam: user.achternaam,
        email: user.email,
        gebruikers_rol: user.gebruikers_rol,
        geslacht: user.geslacht,
        profiel_foto: user.profiel_foto,
        telefoon_nummer: user.telefoon_nummer,
        voornaam: user.voornaam,
        geboortedatum: user.geboortedatum,
        data_eigenaar_ref: user.data_eigenaar_ref,
        bedrijf: {
            naam: `${company.naam}`,
            logo: company.logo
        }
    };
};

export const getAllConsulenten = async (user: UserModel) => {
    const consulenten = await getAllConsulentenDocuments(user?.data_eigenaar_ref!);

    const list: UserModel[] = []

    consulenten.forEach((consulent) => {
        list.push({
            ref: consulent.ref,
            achternaam: consulent.achternaam,
            email: consulent.email,
            gebruikers_rol: consulent.gebruikers_rol,
            geslacht: consulent.geslacht,
            telefoon_nummer: consulent.telefoon_nummer,
            voornaam: consulent.voornaam,
            geboortedatum: consulent.geboortedatum,
            profiel_foto: consulent.profiel_foto,
        })
    })

    return list
}

export const getAllNonTalents = async (user: UserModel) => {
    const nonTalents = await getAllNonTalentDocuments(user?.data_eigenaar_ref!);

    const list: UserModel[] = []

    nonTalents.forEach((nonTalent) => {
        list.push({
            ref: nonTalent.ref,
            achternaam: nonTalent.achternaam,
            email: nonTalent.email,
            gebruikers_rol: nonTalent.gebruikers_rol,
            geslacht: nonTalent.geslacht,
            telefoon_nummer: nonTalent.telefoon_nummer,
            voornaam: nonTalent.voornaam,
            geboortedatum: nonTalent.geboortedatum,
            profiel_foto: nonTalent.profiel_foto,
            heeftAccount: nonTalent.heeftAccount
        })
    })

    return list
}
