import { Listbox, ListboxButton, ListboxOptions, ListboxOption } from "@headlessui/react"
import { ChevronUpDownIcon, CheckIcon } from "@heroicons/react/20/solid"
import { HTMLAttributes, forwardRef, useEffect, useRef, useState } from "react"
import { BedrijfModel } from "../../../domain/bedrijf-utils"
import DefaultImage from "../../defaultimage/defaultimage"

type IBedrijfSelection = HTMLAttributes<HTMLInputElement> &  {
    bedrijf?: BedrijfModel,
    bedrijven: BedrijfModel[]
  }
  
  export const BedrijfSelection = forwardRef<HTMLInputElement, IBedrijfSelection>(({bedrijf, bedrijven, ...props}, ref) => {
  
    const [selected, setSelected] = useState<BedrijfModel | null>(null)
  
    return (
      <Listbox value={selected} onChange={(e) => {setSelected(e); dispatchEvent(new Event('change', { bubbles: true }))}}>
        <div className="relative">
          <ListboxButton className="relative min-w-52 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm/6">
            <span className="flex items-center">
              <span className="ml-3 block truncate">{selected?.naam}</span>
              <span className="ml-3 block truncate">{selected?.plaats}</span>
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
              <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
            </span>
            <input ref={ref} onChange={(e) => console.log("Change")} type="hidden" name="trajectId" value={selected?.reference?.id || ""} />
          </ListboxButton>
  
          <ListboxOptions
            transition
            className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
          >
            {bedrijven.map((bedrijf) => (
              <ListboxOption
                key={bedrijf.reference?.id}
                value={bedrijf}
                className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
              >
                <div className="flex items-center">
                  <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                    {bedrijf.naam} {bedrijf.locatie}
                  </span>
                </div>
  
                <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-700 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                  <CheckIcon aria-hidden="true" className="h-5 w-5" />
                </span>
              </ListboxOption>
            ))}
          </ListboxOptions>
        </div>
      </Listbox>
    )
  })

  export function WerkbedrijfSelectionV2({
      werkbedrijf, 
      werkbedrijven,
      onChange,
    }: {
      werkbedrijf?: BedrijfModel,
      werkbedrijven: BedrijfModel[],
      onChange: any
    }) {
    
      const trajectIdRef = useRef<HTMLInputElement | null>(null);
    
      const [selected, setSelected] = useState((werkbedrijf) ? werkbedrijf : undefined)
    
      useEffect(() => {
        if (werkbedrijf)
          setSelected(werkbedrijf)
      }, [werkbedrijf])
    
      useEffect(() => {
        if (trajectIdRef.current) {
          trajectIdRef.current.dispatchEvent(new Event('input', { bubbles: true }))
        }
      }, [selected])
    
      return (
        <Listbox value={selected} onChange={(e) => {setSelected(e); }}>
          <div className="relative">
            <ListboxButton className="relative min-w-52 cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm/6">
            <span className="flex items-center">
                <span className="ml-3 block truncate">{selected?.naam ?? "Selecteer een werkbedrijf"}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
              </span>
              <input ref={trajectIdRef} onInput={(e) => {onChange(selected?.reference.id)}} type="hidden" name="werkbedrijf[id]" value={selected?.reference.id} />        </ListboxButton>
    
            <ListboxOptions
              transition
              className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
            >
              {werkbedrijven.map((werkbedrijf) => (
                <ListboxOption
                  key={werkbedrijf.reference.id}
                  value={werkbedrijf}
                  className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                >
                  <div className="flex items-center">
                    <span className="ml-3 block truncate font-normal group-data-[selected]:font-semibold">
                      {werkbedrijf.naam}
                    </span>
                  </div>
    
                  <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-700 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                    <CheckIcon aria-hidden="true" className="h-5 w-5" />
                  </span>
                </ListboxOption>
              ))}
            </ListboxOptions>
          </div>
        </Listbox>
      )
    }