import React, { useEffect, useState } from "react";
import {
  BriefcaseIcon,
  CalendarIcon,
  CheckIcon,
  ChevronRightIcon,
  CurrencyDollarIcon,
  PencilIcon,
  UserCircleIcon,
  TrashIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import Layout from "../../../layout";
import { Dialog, DialogPanel } from "@headlessui/react";
import { getTrajectById, TrajectModel } from "../../../domain/traject-utils";
import { useParams } from "react-router-dom";

export default function TrajectDetails() {
  const { trajectId } = useParams();
  const [traject, setTraject] = useState<TrajectModel>();
  const [isEditing, setIsEditing] = useState(false);
  const [editedTraject, setEditedTraject] = useState<TrajectModel | null>(null);
  const [doelen, setDoelen] = useState<string[]>([]);
  const [editingDoelIndex, setEditingDoelIndex] = useState<number | null>(null);
  const [newDoel, setNewDoel] = useState("");

  useEffect(() => {
    getTrajectById(trajectId, { consulent: true })
      .then((response) => {
        setTraject(response);
        setEditedTraject(response);
        setDoelen(["Focus op communicatie", "Versterk zelfvertrouwen"]); // Example initial doelen
      })
      .catch((e) => console.error(e));
  }, [trajectId]);

  const handleInputChange = (field: string, value: string) => {
    setEditedTraject({ ...editedTraject, [field]: value });
  };

  const saveChanges = () => {
    if (editedTraject) {
      setTraject(editedTraject);
    }
    setIsEditing(false);
  };

  const addDoel = () => {
    if (newDoel.trim()) {
      setDoelen([...doelen, newDoel]);
      setNewDoel("");
    }
  };

  const editDoel = (index: number) => {
    setNewDoel(doelen[index]);
    setEditingDoelIndex(index);
  };

  const saveDoel = () => {
    if (editingDoelIndex !== null) {
      const updatedDoelen = [...doelen];
      updatedDoelen[editingDoelIndex] = newDoel;
      setDoelen(updatedDoelen);
      setEditingDoelIndex(null);
      setNewDoel("");
    }
  };

  const deleteDoel = (index: number) => {
    const updatedDoelen = doelen.filter((_, i) => i !== index);
    setDoelen(updatedDoelen);
  };

  return (
    <Layout>
      <div className="max-w-7xl mx-auto px-6 py-8">
        {/* Header */}
        <div className="flex items-center justify-between">
          <div>
            <nav aria-label="Breadcrumb" className="flex items-center space-x-4">
              <a href="/trajecten" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                Trajecten
              </a>
              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
              <span className="text-sm font-medium text-gray-900">{traject?.naam}</span>
            </nav>
            <h1 className="mt-4 text-3xl font-bold text-gray-900">{traject?.naam}</h1>
            <div className="mt-2 text-sm text-gray-500 flex space-x-4">
              <div className="flex items-center">
                <BriefcaseIcon className="h-5 w-5 mr-1 text-gray-400" />
                {traject?.gemeente}
              </div>
              <div className="flex items-center">
                <CurrencyDollarIcon className="h-5 w-5 mr-1 text-gray-400" />
                [Budget]
              </div>
              <div className="flex items-center">
                <CalendarIcon className="h-5 w-5 mr-1 text-gray-400" />
                [Contractdatum]
              </div>
            </div>
          </div>
          <div className="space-x-3">
            <button
              type="button"
              onClick={() => setIsEditing(true)}
              className="inline-flex items-center px-4 py-2 border border-gray-300 text-gray-700 text-sm font-medium rounded-md bg-white hover:bg-gray-50"
            >
              <PencilIcon className="h-5 w-5 mr-1" />
              Bewerken
            </button>
            <button
              type="button"
              className="inline-flex items-center px-4 py-2 border border-indigo-600 text-blue-700 text-sm font-medium rounded-md bg-white hover:bg-indigo-50"
            >
              <CheckIcon className="h-5 w-5 mr-1" />
              Activeren
            </button>
          </div>
        </div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
          {/* Left Column */}
          <div className="lg:col-span-2 space-y-6">
            {/* Trajectgegevens */}
            <section>
              <h2 className="text-xl font-semibold text-gray-800">Trajectgegevens</h2>
              <dl className="mt-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                <Detail label="Naam" value={traject?.naam || "Onbekend"} />
                <Detail label="Gemeente" value={traject?.gemeente || "Onbekend"} />
                <Detail label="Casemanager" value={traject?.consulent?.naam || "Onbekend"} />
              </dl>
            </section>

            {/* Doelen */}
            <section>
              <h2 className="text-xl font-semibold text-gray-800">Doelen</h2>
              <ul className="mt-4 space-y-4">
                {doelen.map((doel, index) => (
                  <li
                    key={index}
                    className="flex items-center justify-between bg-white border border-gray-300 rounded-md p-4 shadow-sm"
                  >
                    <span className="text-gray-900">{doel}</span>
                    <div className="space-x-2">
                      <button
                        type="button"
                        className="text-blue-700 hover:text-indigo-900"
                        onClick={() => editDoel(index)}
                      >
                        <PencilIcon className="h-5 w-5" />
                      </button>
                      <button
                        type="button"
                        className="text-red-600 hover:text-red-900"
                        onClick={() => deleteDoel(index)}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="mt-4">
                <input
                  type="text"
                  value={newDoel}
                  onChange={(e) => setNewDoel(e.target.value)}
                  placeholder="Nieuwe doel toevoegen"
                  className="mr-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
                {editingDoelIndex !== null ? (
                  <button
                    onClick={saveDoel}
                    className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white text-sm font-medium rounded-md hover:bg-indigo-500"
                  >
                    Opslaan
                  </button>
                ) : (
                  <button
                    onClick={addDoel}
                    className="inline-flex items-center px-4 py-2 bg-indigo-600 text-white text-sm font-medium rounded-md hover:bg-indigo-500"
                  >
                    <PlusIcon className="h-5 w-5 mr-1" />
                    Toevoegen
                  </button>
                )}
              </div>
            </section>
          </div>

          {/* Right Column */}
          <div className="space-y-6">
            <section>
              <h2 className="text-xl font-semibold text-gray-800">Overzicht</h2>
              <div className="mt-4 bg-gray-50 rounded-lg p-6 shadow">
                <p className="text-sm text-gray-500">Actieve Talenten</p>
                <p className="mt-1 text-2xl font-semibold text-gray-900">23</p>
                <p className="mt-4 text-sm text-gray-500">Contractbeheerder</p>
                <p className="text-base font-medium text-gray-900">Eric Raaphorst</p>
              </div>
            </section>
          </div>
        </div>
      </div>

      {/* Edit Dialog */}
      <Dialog open={isEditing} onClose={() => setIsEditing(false)} className="relative z-50">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <DialogPanel className="w-full max-w-lg rounded-lg bg-white p-6 shadow-lg">
              <h3 className="text-lg font-medium leading-6 text-gray-900">Bewerk Trajectgegevens</h3>
              <div className="mt-4 space-y-4">
                <InputField
                  label="Naam"
                  value={editedTraject?.naam || ""}
                  onChange={(e) => handleInputChange("naam", e.target.value)}
                />
                <InputField
                  label="Gemeente"
                  value={editedTraject?.gemeente || ""}
                  onChange={(e) => handleInputChange("gemeente", e.target.value)}
                />
                <InputField
                  label="Consulent"
                  value={editedTraject?.consulent?.naam || ""}
                  onChange={(e) => handleInputChange("consulent", e.target.value)}
                />
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  onClick={() => setIsEditing(false)}
                >
                  Annuleren
                </button>
                <button
                  type="button"
                  className="rounded-md bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-500"
                  onClick={saveChanges}
                >
                  Opslaan
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
}

const Detail = ({ label, value }: { label: string; value: string }) => (
  <div>
    <dt className="text-sm font-medium text-gray-500">{label}</dt>
    <dd className="mt-1 text-sm text-gray-900">{value}</dd>
  </div>
);

const InputField = ({
  label,
  value,
  onChange,
}: {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <div>
    <label htmlFor={label} className="block text-sm font-medium text-gray-700">
      {label}
    </label>
    <input
      id={label}
      type="text"
      value={value}
      onChange={onChange}
      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
    />
  </div>
);