'use client'

import { useEffect, useState } from 'react';
import { Listbox } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { createEvaluatie, EvaluatieModel, NewEvaluatieModel } from '../../domain/evaluatie-utils';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form'
import { TalentModel } from '../../domain/talent-utils';
import { UserModel } from '../../domain/user-utils';

interface TodoListProps {
  title: string;
  subtitle: string;
  user: UserModel,
  evaluaties: EvaluatieModel[],
  talenten: TalentModel[],
  setEvaluatie: any,
  isLoading: boolean
}

export default function TodoList({ title, subtitle, user, evaluaties, talenten, setEvaluatie, isLoading }: TodoListProps) {

  const [mood, setMood] = useState("3"); // Default to neutral mood
  const [evaluatieValue, setEvaluatieValue] = useState(5); // Default to 5
  const [talentId, setTalentId] = useState("")



  type Inputs = {
    evaluaties:
    {
      content: string,
      talentId: string,
      cijfer: number,
      gevoel: number
    }[]
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<Inputs>()
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    // console.log("Test")
    const newEntry: NewEvaluatieModel = {
      content: data.evaluaties[0].content,
      auteurId: user.ref.id,
      talentId: data.evaluaties[0].talentId,
      cijfer: data.evaluaties[0].cijfer,
      gevoel: data.evaluaties[0].gevoel
    };

    createEvaluatie(user, newEntry).then((res) => {
      remove(0)
      setTalentId("")
      setEvaluatie([res, ...evaluaties])
    })
  }

  const { fields, append, remove } = useFieldArray({
    name: "evaluaties",
    control
  });

  // const newEntry: NewEvaluatieModel = {
  //   content: newUpdate,
  //   auteurId: user.ref.id,
  //   talentId: talentId!,
  //   cijfer: rating,
  //   gevoel: moodLevel
  // };

  // createEvaluatie(user, newEntry).then((res) => {
  //     // window.location.reload()
  // })
  // const addTodo = () => {
  //   const newTodo = {
  //     id: Date.now(),
  //     name: 'Nieuw Item',
  //     text: '',
  //     completed: false,
  //     date: '',
  //     person: '',
  //   };
  //   setTodos([newTodo, ...todos]);
  // };

  return (
    <div className="space-y-6">
      {/* Button Section */}
      <div className="flex items-center justify-between">
        <div>
          {/* Use Props for Titles */}
          <h2 className="text-xl font-semibold text-slate-700">{title}</h2>
          <p className="text-lg font-base text-slate-400 mb-4">{subtitle}</p>
        </div>
        <button
          type="button"
          onClick={() => { remove(0); append({ cijfer: -1, content: "", gevoel: -1, talentId: "" }); }}
          className="inline-flex items-center bg-blue-600 px-4 py-2 text-sm font-medium text-white rounded-lg shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          <PlusCircleIcon className="size-5 text-white mr-2" aria-hidden="true" />
          <span>Nieuwe update schrijven</span>
        </button>
      </div>

      {/* To-Do Table */}
      {fields.map((field, i) =>
        <div className="space-y-4">
          <div key={-1} className="bg-white p-4 border-t border-gray-200 space-y-4">
            {/* Checkbox en Dynamisch Textveld */}
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* Date and Person Selectors */}
              <div className="flex items-center space-x-4">
                    {/* Person Selector */}
                    <Controller
                      control={control}
                      name="evaluaties.0.talentId"
                      rules={{ required: true }}
                      render={({
                        field: { onChange }
                      }) => (
                        <Listbox
                        value={talentId}
                          onChange={(e) => {onChange(e); setTalentId(e)}}
                        >
                          {({ open }) => (
                            <div className="relative">
                              <Listbox.Button className="px-3 py-1.5 text-sm font-medium rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200">
                                {talentId ? talenten.find((talent) => talent.id == talentId)?.volledige_naam : "Selecteer het Talent"}
                              </Listbox.Button>
                              {open && (
                                <Listbox.Options className="absolute mt-1 w-48 bg-white shadow-lg ring-1 ring-black/5 rounded-md max-h-96 overflow-scroll text-gray-700">
                                  {talenten.map((talent) =>
                                    <Listbox.Option key={talent.id} value={talent.id} className="px-4 py-2 hover:bg-gray-100 text-sm">
                                      {talent.volledige_naam}
                                    </Listbox.Option>
                                  )}
                                </Listbox.Options>
                              )}
                            </div>
                          )}
                        </Listbox>)}
                    />
                  </div>

              <><div className="flex items-center py-2">
                <textarea
                  {...register("evaluaties.0.content", { required: true })}
                  placeholder="Wat heeft het Talent vandaag gedaan?"
                  rows={1}
                  onInput={(e) => {
                    const target = e.target as HTMLTextAreaElement;
                    target.style.height = 'auto';
                    target.style.height = `${target.scrollHeight}px`;
                  }}
                  className="w-full border-none focus:ring-0 text-gray-900 placeholder-gray-400 resize-none overflow-hidden"
                ></textarea>
              </div>

                <div className="flex mx-3 my-2 pb-2 items-center space-x-4">
                  {/* Slider for rating (0-10) */}
                  <div className="flex items-center w-full">
                    <label className="mr-4 text-sm text-gray-700">Cijfer:</label>
                    <input
                      type="range"
                      min="0"
                      max="10"
                      step="1"
                      {...register("evaluaties.0.cijfer", { required: true })}
                      defaultValue={5} // Set default value to 5
                      className="w-full h-4 bg-gray-200 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onChange={(e) => setEvaluatieValue(Number(e.target.value))} // Parse the value to a number
                    />
                    <span className="ml-4 text-gray-700 font-bold">{evaluatieValue || 0}</span> {/* Show value or default */}
                  </div>

                  {/* Slider for mood (emoji-based) */}
                  <div className="flex items-center w-full">
                    <label className="mr-4 text-sm text-gray-700">Mood:</label>
                    <input
                      type="range"
                      min="1"
                      max="5"
                      step="1"
                      {...register("evaluaties.0.gevoel", { required: true })}
                      className="w-full h-4 bg-gray-200 rounded-lg appearance-none cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-500"
                      onChange={(e) => {
                        const value = e.target.value;
                        setMood(value); // Assuming setMood is a state setter
                      }}
                    />
                    <span className="ml-4 text-gray-700">
                      {mood === "1" ? "😢" : mood === "2" ? "😟" : mood === "3" ? "😐" : mood === "4" ? "😊" : "😁"}
                    </span>
                  </div>
                </div>

                {/* Action Buttons */}
                <div className="flex items-center justify-between">
                  {/* Save Button */}
                  <button
                    type="submit"
                    className="px-3 py-1.5 text-xs font-medium rounded-lg bg-blue-600 text-white hover:bg-indigo-500"
                  >
                    Opslaan
                  </button>

                  
                </div></>
            </form>
          </div>
        </div>
      )
      }

      {/* To-Do Table */}
      {isLoading ? (
         <ul role="list" className="divide-y divide-gray-100">
         {Array.from({ length: 12 }).map((_, index) => (
           <li
             key={index}
             className="py-4 flex items-center justify-between animate-pulse"
           >
             <div className="flex items-center">
               <div className="ml-4">
                 <div className="h-6 w-64 bg-gray-300 rounded"></div> {/* Match title size */}
                 <div className="mt-4 h-4 w-96 bg-gray-300 rounded"></div> {/* Match subtitle size */}
                 <div className="mt-4 h-4 w-24 bg-gray-300 rounded"></div> {/* Match subtitle size */}
               </div>
             </div>
             <div className="h-4 w-20 bg-gray-300 rounded"></div> {/* Match action button size */}
           </li>
         ))}
       </ul>
     ) : (
      <div className="space-y-4">
        {evaluaties.map((evaluatie, i) => (
          <div key={i} className="bg-white p-4 border-t border-gray-200 space-y-4">
            {/* Titel met de naam van de persoon */}
            <div className="text-base font-medium text-gray-900">{evaluatie.talent?.naam}</div>

            {/* Checkbox en Dynamisch Textveld */}
            <div className="flex items-center">
              {/* <input
                type="checkbox"
                checked={evaluaties.completed}
                onChange={(e) =>
                  setTodos(
                    todos.map((t) =>
                      t.id === todo.id ? { ...t, completed: e.target.checked } : t
                    )
                  )
                }
                className="h-5 w-5 mr-4 rounded text-blue-700 focus:ring-indigo-500 border-gray-300"
              /> */}
              <p
                className="w-full border-none focus:ring-0 text-gray-900 placeholder-gray-400 resize-none overflow-hidden"
              >
                {evaluatie.content}
              </p>
            </div>

            {/* Action Buttons */}
            <div className="flex items-center justify-between">


              {/* Date and Person Selectors */}
              <div className="flex items-center space-x-4">
                {/* Date Selector */}
                <p className="px-3 py-1.5 text-xs font-medium rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200">
                  {evaluatie.aanmaak_datum.toLocaleDateString()}
                </p>

                <p className="px-3 py-1.5 text-xs font-medium rounded-lg bg-gray-100 text-gray-700 hover:bg-gray-200">
                  {evaluatie.talent?.naam}
                </p>

                
              </div>
            </div>
          </div>
        ))}
      </div>
      )}
    </div>
  );
}