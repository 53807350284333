import { doc, DocumentReference, writeBatch } from 'firebase/firestore';
import _getIntakes, { afmeldenIntakeDocument, createIntake, createIntakeWithBatch, getIntakeDocumentById } from '../datasource/intake-utils';
import { _getUserById, getUserById, UserModel } from './user-utils'; 
import { db, functions } from '../utils/firebase';
import { getTrajectByID, getTrajectDocumentById } from '../datasource/traject-utils';
import { uploadFileToFirebase } from '../utils/files-handler';
import { getDownloadURL } from 'firebase/storage';
import { getTrajectById } from './traject-utils';
import { getUserDocumentById } from '../datasource/user-utils';
import { httpsCallable } from "firebase/functions";
import { addTalentDocument, updateTalentDocument } from '../datasource/talent-utils';
import { getBedrijfById } from './bedrijf-utils';
import { addLopendTrajectDocument } from '../datasource/lopend-traject-utils';

export type IntakeModel = {
    id?: string,
    datumUitgevoerd?: Date,
    gemeente?: string,
    trajectId?: string,
    traject?: {
        naam?: string
    }
    startDatum?: Date,
    consulentId?: string,
    consulent?: {
        naam?: string
    },
    aangemaaktDoorId?: string,
    aangemaaktDoor?: {
        naam?: string
    },
    eersteIndrukTalent?: string,
    documenten?: string[],
    voornaam?: string,
    achternaam?: string,
    geboortedatum?: Date,
    gender?: string,
    email?: string,
    telefoon?: string,
    nationaliteit?: string,
    afkomst?: string,
    straat?: string,
    huisnummer?: string,
    postcode?: string,
    woonplaats?: string,
    situatieSchets?: string,
    werkervaring?: string,
    soortUitkering?: string,
    vervoer?: string,
    taalniveau?: string,
    gezondheid?: string,
    opleiding?: string,
    medicijnen?: string,
    ingeburgerd?: boolean,
    allergien?: string,
    fysiekeBelasting?: string,
    diplomas?: string,
    diplomasGewaardeerd?: boolean,
    kinderopvang?: boolean,
    rijbewijs?: boolean,
    ervaringTalent?: string,
    ambitieTalent?: string,
    kunnenTalent?: string,
    voorstel?: string
}

export type NewIntakeModel = {
    gemeente?: string,
    trajectId?: string,
    startDatum?: Date,
    consulentId?: string,
    werkvloerbegeleiderId?: string,
    casemanagerId?: string,
    werkbedrijfId?: string,
    eersteIndrukTalent?: string,
    documenten?: File[],
    voornaam?: string,
    achternaam?: string,
    geboortedatum?: Date,
    gender?: string,
    email?: string,
    telefoon?: string,
    nationaliteit?: string,
    afkomst?: string,
    straat?: string,
    huisnummer?: string,
    postcode?: string,
    woonplaats?: string,
    situatieSchets?: string,
    werkervaring?: string,
    soortUitkering?: string,
    vervoer?: string,
    taalniveau?: string,
    gezondheid?: string,
    opleiding?: string,
    medicijnen?: string,
    ingeburgerd?: boolean,
    allergien?: string,
    fysiekeBelasting?: string,
    diplomas?: string,
    diplomasGewaardeerd?: boolean,
    kinderopvang?: boolean,
    rijbewijs?: boolean,
    ervaringTalent?: string,
    ambitieTalent?: string,
    kunnenTalent?: string,
    voorstel?: string,
    aangemaakt_door_reference?: DocumentReference,
    data_eigenaar_ref?: DocumentReference
}

export const getIntakes = async (max: number, user: UserModel, options?: {consulent?: boolean, traject?: boolean, aangemaaktDoor?: boolean}) => {
    const list: IntakeModel[] = [];
    const intakes = await _getIntakes(user?.data_eigenaar_ref!, max!);

    for (const intake of intakes) {
        const intakeData: IntakeModel = {
            id: intake.reference.id,
            datumUitgevoerd: intake.datum_uitgevoerd,
            gemeente: intake.gemeente,
            startDatum: intake.start_datum,
            eersteIndrukTalent: intake.eerste_indruk_talent,
            documenten: intake.documenten,
            voornaam: intake.voornaam,
            achternaam: intake.achternaam,
            geboortedatum: intake.geboortedatum,
            gender: intake.geslacht,
            email: intake.email,
            telefoon: intake.telefoon,
            nationaliteit: intake.nationaliteit,
            afkomst: intake.afkomst,
            straat: intake.straat,
            huisnummer: intake.huisnummer,
            postcode: intake.postcode,
            woonplaats: intake.woonplaats,
            situatieSchets: intake.korte_situatieschets,
            werkervaring: intake.werkervaring,
            soortUitkering: intake.soort_uitkering,
            vervoer: intake.vervoer,
            taalniveau: intake.taalniveau,
            gezondheid: intake.gezondheid,
            opleiding: intake.opleiding,
            medicijnen: intake.medicijnen,
            ingeburgerd: intake.ingeburgerd,
            allergien: intake.allergien,
            fysiekeBelasting: intake.fysieke_belasting,
            diplomas: intake.diplomas,
            diplomasGewaardeerd: intake.diplomas_gewaardeerd,
            kinderopvang: intake.kinderopvang,
            rijbewijs: intake.rijbewijs,
            ervaringTalent: intake.talent_ervaring,
            ambitieTalent: intake.talent_wensen,
            kunnenTalent: intake.talent_kunnen,
            voorstel: intake.voorstel
        };

        if (options?.consulent) {
            let consulent = await getUserById(intake?.consulent_reference)
            intakeData.consulent = {
                naam: `${consulent?.voornaam} ${consulent?.achternaam}`
            } 
        } else {
            intakeData.consulentId = intake.consulent_reference?.id
        }

        if (options?.traject) {
            let traject = await getTrajectById(intake.traject_reference?.id).catch((e) => {})
            intakeData.traject = {
                naam: `${traject?.naam}`
            } 
        } else {
            intakeData.trajectId = intake.traject_reference?.id
        }

        if (options?.aangemaaktDoor) {
            let aangemaaktDoor = await getUserById(intake.aangemaakt_door_reference)
            intakeData.aangemaaktDoor = {
                naam: `${aangemaaktDoor?.voornaam} ${aangemaaktDoor?.achternaam}`
            } 
        } else {
            intakeData.aangemaaktDoorId = intake.aangemaakt_door_reference?.id
        }
        
        list.push(intakeData);
    }

    return list;
};

export const getIntakeById = async (id?: string, options?: {consulent?: boolean, traject?: boolean}) => {

    if (!id) return Promise.reject("Fout.")

    const intake = await getIntakeDocumentById(id)

    if (!intake) return Promise.reject("Geen intake gevonden.")

    const model: IntakeModel = {
        id: intake.reference.id,
    datumUitgevoerd: intake.datum_uitgevoerd,
    gemeente: intake.gemeente,
    startDatum: intake.start_datum,
    eersteIndrukTalent: intake.eerste_indruk_talent,
    documenten: intake.documenten,
    voornaam: intake.voornaam,
    achternaam: intake.achternaam,
    geboortedatum: intake.geboortedatum,
    gender: intake.geslacht,
    email: intake.email,
    telefoon: intake.telefoon,
    nationaliteit: intake.nationaliteit,
    afkomst: intake.afkomst,
    straat: intake.straat,
    huisnummer: intake.huisnummer,
    postcode: intake.postcode,
    woonplaats: intake.woonplaats,
    situatieSchets: intake.korte_situatieschets,
    werkervaring: intake.werkervaring,
    soortUitkering: intake.soort_uitkering,
    vervoer: intake.vervoer,
    taalniveau: intake.taalniveau,
    gezondheid: intake.gezondheid,
    opleiding: intake.opleiding,
    medicijnen: intake.medicijnen,
    ingeburgerd: intake.ingeburgerd,
    allergien: intake.allergien,
    fysiekeBelasting: intake.fysieke_belasting,
    diplomas: intake.diplomas,
    diplomasGewaardeerd: intake.diplomas_gewaardeerd,
    kinderopvang: intake.kinderopvang,
    rijbewijs: intake.rijbewijs,
    ervaringTalent: intake.talent_ervaring,
    ambitieTalent: intake.talent_wensen,
    kunnenTalent: intake.talent_kunnen,
    voorstel: intake.voorstel
    }

    if (options?.consulent) {
        let consulent = await getUserDocumentById(intake?.consulent_reference!.id)
        model.consulent = {
            naam: `${consulent?.voornaam} ${consulent?.achternaam}`
        } 
    } else {
        model.consulentId = intake.consulent_reference?.id
    }

    if (options?.traject) {
        let traject = await getTrajectDocumentById(intake?.traject_reference?.id)
        model.traject = {
            naam: traject?.naam
        } 
    } else {
        model.trajectId = intake.traject_reference?.id
    }

    return model

}


export const createNewIntake = async (user: UserModel, intakeModel: NewIntakeModel, aanmeldId?: string) => {

    const traject = await getTrajectDocumentById(intakeModel.trajectId)
    const werkvloerbegeleider = await _getUserById(intakeModel.werkvloerbegeleiderId)
    const casemanager = await _getUserById(intakeModel.casemanagerId)
    const werkbedrijf = await getBedrijfById(intakeModel.werkbedrijfId)

    if (!traject || !werkvloerbegeleider || !casemanager || !werkbedrijf) return Promise.reject("Kan geen intake maken.")

    const uploads: string[] = []

    for (let file of intakeModel.documenten!) {
        let ref = await uploadFileToFirebase(user.ref.id, file)
        let url = await getDownloadURL(ref.ref)
        uploads.push(url)
    }

    intakeModel.aangemaakt_door_reference = user.ref
    intakeModel.data_eigenaar_ref = user.data_eigenaar_ref

    const {data} = await httpsCallable<{email: string}, {status: string, userId: string}>(functions, 'create-user-after-intake')({email: intakeModel.email!})

    const batch = writeBatch(db)

    const intakeRef = createIntakeWithBatch(intakeModel, traject.reference, werkvloerbegeleider.ref, casemanager.ref, werkbedrijf.reference,uploads, batch)
    const talent = addTalentDocument({
        achternaam: intakeModel.achternaam!,
            actief: true,
            email: intakeModel.email!,
            gebruikers_rol: "talent",
            geslacht: intakeModel.gender!,
            telefoon_nummer: intakeModel.telefoon!,
            voornaam: intakeModel.voornaam!,
            talent: {
                intake_ref: intakeRef,
                geboorte_datum: intakeModel.geboortedatum!,
                gemeente: intakeModel.gemeente!,
                huisnummer: intakeModel.huisnummer!,
                nationaliteit: intakeModel.nationaliteit!,
                plaats: "",
                postcode: intakeModel.postcode!,
                straat: intakeModel.straat!,
                traject_ref: traject.reference,
                traject: {
                    duur: traject.duur,
                    evaluatie_momenten: traject.evaluatie_momenten,
                    gemeente: traject.gemeente,
                    jobcoach_interventies: traject.jobcoach_interventies,
                    loonwaardemeting: traject.loonwaardemeting,
                    naam: traject.naam,
                    omschrijving: traject.omschrijving,
                    praktijkverklaring: traject.praktijkverklaring,
                    reference: traject.reference.id,
                    talentportfolio: traject.talentportfolio,
                    aantal_rapportages: traject.aantal_rapportages
                },
                uitstroom: false
            },
            data_eigenaar_ref: user.data_eigenaar_ref!
    }, batch, data.userId)

    const lopendTraject = addLopendTrajectDocument({
        begindatum: intakeModel.startDatum!,
        consulent: {
            reference: casemanager.ref.id,
            voornaam: casemanager.voornaam,
            achternaam: casemanager.achternaam,
            profiel_foto: casemanager.profiel_foto ?? null,
            email: casemanager.email
        },
        consulent_ref: casemanager.ref,
        status: 'Niet gestart',
        talent_ref: talent,
        talent: {
            reference: data.userId,
            voornaam: intakeModel.voornaam!,
            achternaam: intakeModel.achternaam!,
            profiel_foto: null,
            email: intakeModel.email!
        },
        traject_ref: traject.reference,
        traject: {
            duur: traject.duur,
            evaluatie_momenten: traject.evaluatie_momenten,
            gemeente: traject.gemeente,
            jobcoach_interventies: traject.jobcoach_interventies,
            loonwaardemeting: traject.loonwaardemeting,
            naam: traject.naam,
            omschrijving: traject.omschrijving,
            praktijkverklaring: traject.praktijkverklaring,
            reference: traject.reference.id,
            talentportfolio: traject.talentportfolio,
            aantal_rapportages: traject.aantal_rapportages
        },
        werkbedrijf: {
            reference: werkbedrijf.reference.id,
            naam: werkbedrijf.naam!
        },
        werkdagen: '',
        werkvloerbegeleider: {
            reference: werkvloerbegeleider.ref.id,
            voornaam: werkvloerbegeleider.voornaam,
            achternaam: werkvloerbegeleider.achternaam,
            profiel_foto: werkvloerbegeleider.profiel_foto ?? null,
            email: werkvloerbegeleider.email
        },
        data_eigenaar_ref: user.data_eigenaar_ref!
    }, batch)

    batch.update(talent, {"talent.lopendtraject": {
        begindatum: intakeModel.startDatum!,
        reference: lopendTraject.id,
        status: 'Niet gestart'
    }})

    batch.update(talent, {"talent.lopendtraject_ref": lopendTraject})

    if (aanmeldId) {
        batch.update(doc(db, "aanmelding", aanmeldId), {afgemeld_op: new Date()})
    }

    return batch.commit();
};

export const afmeldenIntake = async (intakeId: string) => {
    return afmeldenIntakeDocument(intakeId)
}