import { DocumentReference, getDoc } from 'firebase/firestore';
import { getAllBedrijvenDocuments, getBedrijfDocumentByReference, getBedrijfReferenceByName, getBedrijfReferenceByReference } from '../datasource/bedrijf-utils';
import { UserModel } from './user-utils';

export type BedrijfModel = {
    id?: null,
    reference: DocumentReference,
    locatie?: string;
    huisnummer?: string;
    logo?: string;
    naam?: string;
    plaats?: string;
    postcode?: string;
    soort?: null; 
    straat?: string;
}

export type NewBedrijfModel = {
    locatie: string,
    nummer: string,
    logo: string,
    naam: string,
    plaats: string,
    postcode: string,
    straat: string,
    begeleider?: DocumentReference,
    data_eigenaar_ref?: DocumentReference,
}

export type LicentienemerModel = {
    id?: null,
    reference: DocumentReference,
    naam: string
    url_naam: string,
    logo: string,
    titel1: string,
    beschrijving1: string,
    titel2: string,
    beschrijving2: string,
    feature1: string,
    feature_description1: string,
    feature2: string,
    feature_description2: string,
    feature3: string,
    feature_description3: string,
    straat: string,
    nummer: string,
    postcode: string,
    telefoonnummer: string,
    email: string,
    plaats: string,
    contact_persoon?: {
        naam: string
    },
    contact_persoon_id?: string
}

export const getAllBedrijven = async (user: UserModel) => {
    const list = await getAllBedrijvenDocuments(user.data_eigenaar_ref!)
    const bedrijven: BedrijfModel[] = []

    for(let bedrijf of list) {
        bedrijven.push({
            reference: bedrijf.reference,
            locatie: bedrijf.plaats,
            huisnummer: bedrijf.huisnummer,
            logo: bedrijf.logo,
            naam: bedrijf.naam,
            plaats: bedrijf.plaats,
            postcode: bedrijf.postcode,
            soort: null,
            straat: bedrijf.straat
        })
    }

    return bedrijven
}

export const getBedrijfByReference = async (werkbedrijfRef: DocumentReference) => {
    const bedrijf = await getBedrijfDocumentByReference(werkbedrijfRef.id)

    if (!bedrijf) {

        return null;
    }
  
    return {
        reference: bedrijf.reference,
        huisnummer: bedrijf.huisnummer,
        logo: bedrijf.logo,
        naam: bedrijf.naam,
        plaats: bedrijf.plaats,
        postcode: bedrijf.postcode,
        soort: null,
        straat: bedrijf.straat,
        locatie: bedrijf.plaats + " " + bedrijf.straat + " " + bedrijf.postcode,
    } as BedrijfModel;
};

export const getBedrijfById = async (id?: string) => {

    if (!id) return null

    const bedrijf = await getBedrijfDocumentByReference(id)

    if (!bedrijf) {

        return null;
    }
  
    return {
        reference: bedrijf.reference,
        huisnummer: bedrijf.huisnummer,
        logo: bedrijf.logo,
        naam: bedrijf.naam,
        plaats: bedrijf.plaats,
        postcode: bedrijf.postcode,
        soort: null,
        straat: bedrijf.straat,
        locatie: bedrijf.plaats + " " + bedrijf.straat + " " + bedrijf.postcode,
    };
};

export const getBedrijfByName = async (name: string) => {
    const reference = await getBedrijfReferenceByName(name)

    if (!reference) {

        return Promise.reject("Geen bedrijf gevonden.");
    }
  
    return {
        reference: reference
    };
};

export const getLicentienemerByReference = async (reference: DocumentReference) => {
    const licentienemer = await getBedrijfReferenceByReference(reference)

    if (!licentienemer) {

        return Promise.reject("Geen bedrijf gevonden.");
    }
  
    return {
        reference: licentienemer.reference,
        naam: licentienemer.naam
    } as LicentienemerModel;
};

  
